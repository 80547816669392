import React from 'react';
import { User } from '@types';
import SearchHistory from './SearchHistory';

interface SearchHistoryPageProps {
  loading: boolean;
  loggedInUser: Nullable<User>;
}

const SearchHistoryPage = ({ loading, loggedInUser }: SearchHistoryPageProps) => (
  <SearchHistory loading={loading} userRole={loggedInUser?.role || 'user'} />
);

export default SearchHistoryPage;

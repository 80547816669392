import React, { useState } from 'react';
import { useFormik } from 'formik';
import map from 'lodash/map';
import { SelectBoxChangeEvent } from '@types';
import { SERVICE_PROVIDERS } from '@mantis/data';
import { AdvancedSearchParams } from '@mantis/types';
import ErrorMessage from '@components/ErrorMessage';
import {
  Grid,
  Column,
  Layer,
  Stack,
  FluidForm as Form,
  unstable__FluidTextInput as TextInput,
  unstable__FluidComboBox as ComboBox,
  Button,
  InlineLoading,
} from '@carbon/react';
import spacing from '@styles/spacing.module.scss';
import baseStyles from '@styles/base.module.scss';
import styles from './advancedSearchForm.module.scss';
import { InputField } from './types';
import initFormParams from './helpers/initFormParams';
import isMinRequirementsFulfilled from './helpers/isMinRequirementsFulfilled';
import buildSearchParams from './helpers/buildSearchParams';
import formFields from './data/fields';
import AdvancedSearchSchema from './data/formSchema';

interface AdvancedSearchFormProps {
  onSubmit: (data: AdvancedSearchParams) => void;
  initialValues?: AdvancedSearchParams;
  loading?: boolean;
  errorMessage?: string;
}

const AdvancedSearchForm = ({
  onSubmit,
  initialValues,
  loading = false,
  errorMessage = '',
}: AdvancedSearchFormProps) => {
  const [isMinRequirementsValid, setMinRequirementValid] = useState(true);

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: initFormParams(initialValues),
      validationSchema: AdvancedSearchSchema,
      onSubmit: (data) => {
        // validate min search requirements
        const isDataValid = isMinRequirementsFulfilled(data);
        setMinRequirementValid(isDataValid);

        if (!isDataValid) {
          window.scrollTo({ top: 0, behavior: 'smooth' });

          return;
        }

        const searchParams = buildSearchParams(data);
        onSubmit(searchParams);
      },
    });

  const handleResetForm = () => {
    resetForm({
      values: initFormParams(),
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={5}>
        {errorMessage && !loading && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {!isMinRequirementsValid && (
          <ErrorMessage>The minimal requirement for search was not fulfilled</ErrorMessage>
        )}
        <Grid className={styles.fields}>
          {map(formFields, (field: InputField, key: keyof AdvancedSearchParams) => (
            <Column sm={4} md={8} lg={8} className={styles.column} key={key}>
              <Layer>
                {key === 'userId' ? (
                  <div className={styles.comboBox}>
                    <TextInput
                      id={key}
                      type="text"
                      labelText={field.label}
                      placeholder={field.placeholder}
                      title={field.label}
                      value={values[key]}
                      onChange={handleChange}
                      invalid={errors[key] && touched[key]}
                      invalidText={errors[key]}
                    />
                    <ComboBox
                      id="userIdService"
                      label="Service provider"
                      titleText="Service provider"
                      onChange={({ selectedItem }: SelectBoxChangeEvent) => {
                        setFieldValue('userIdService', selectedItem || '');
                      }}
                      placeholder="Select provider"
                      items={SERVICE_PROVIDERS}
                      isCondensed
                      className={spacing.ml1}
                      selectedItem={values.userIdService}
                      invalid={errors.userIdService && touched.userIdService}
                      invalidText={errors.userIdService}
                    />
                  </div>
                ) : (
                  <TextInput
                    id={key}
                    type="text"
                    labelText={field.label}
                    placeholder={field.placeholder}
                    title={field.label}
                    value={values[key]}
                    onChange={handleChange}
                    invalid={errors[key] && touched[key]}
                    invalidText={errors[key]}
                  />
                )}
              </Layer>
            </Column>
          ))}
        </Grid>
        <p className={styles.description}>
          Use any combination of the parameters above. A search request has to contain{' '}
          <b>at least one</b> valid name (first name and last name), email address, phone, username,
          user ID or URL.
        </p>
        {!loading ? (
          <div className={baseStyles.dFlex}>
            <Button type="submit">Search</Button>
            <Button kind="secondary" type="reset" className={spacing.ml4} onClick={handleResetForm}>
              Reset
            </Button>
          </div>
        ) : (
          <InlineLoading description="Searching..." />
        )}
      </Stack>
    </Form>
  );
};

export default AdvancedSearchForm;

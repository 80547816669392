import orderBy from 'lodash/orderBy';
import client from '@services/client';
import { gql } from '@services/gql';
import { Contract } from '@types';
import { CONTRACT_STATUS_MAP } from '@mantis/data';

const FETCH_CONTRACTS = gql(/* GraphQL */ `
  query fetchOrganizationContracts($id: ID!) {
    organization(id: $id) {
      id
      contracts {
        id
        start
        end
        status
        count
        usage
      }
    }
  }
`);

const fetchContracts = async (id: string): Promise<Contract[]> => {
  const response = await client.query({ query: FETCH_CONTRACTS, variables: { id } });

  if (response.data.organization) {
    const contracts: Contract[] = response.data.organization.contracts.map((contract) => ({
      id: contract.id,
      start: contract.start,
      end: contract.end,
      status: CONTRACT_STATUS_MAP[contract.status],
      count: contract.count,
      usage: contract.usage,
    }));

    // sort contracts
    return orderBy(contracts, 'start', 'desc');
  }

  return [];
};

export default fetchContracts;

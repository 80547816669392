import React, { useState, useEffect } from 'react';
import { Person } from '@mantis/types';
import { FlexGrid, Row, Column } from '@carbon/react';
import { SearchLocate } from '@carbon/icons-react';
import useTypedParams from '@hooks/useTypedParams';
import Navigate from '@components/Navigate';
import CenteredContent from '@components/CenteredContent';
import Card from '@components/Card';
import ErrorContent from '@components/ErrorContent';
import MainProfile from './MainProfile';
import Details from './Details';
import fetchPerson from './helpers/fetchPerson';
import styles from './detailPage.module.scss';

interface DetailPageProps {
  loading: boolean;
}

const DetailPage = ({ loading }: DetailPageProps) => {
  const { id } = useTypedParams(['id']);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);
  const [person, setPerson] = useState<Nullable<Person>>(null);

  useEffect(() => {
    const fetchPersonFn = async () => {
      try {
        const fetchedPerson = await fetchPerson(id);
        setPerson(fetchedPerson);
      } catch (err) {
        setError(true);
      } finally {
        setFetching(false);
      }
    };

    if (!loading) {
      fetchPersonFn();
    }
  }, [id, loading]);

  if (!loading && !fetching) {
    if (error) {
      return (
        <ErrorContent title="Something went wrong">
          <p>
            There was an error when processing the data. Please try again later, contact our support
            or provide a new search.
          </p>
          <Navigate to="/search" icon={SearchLocate}>
            Search for a person
          </Navigate>
        </ErrorContent>
      );
    }

    if (!person) {
      return (
        <ErrorContent title="Person not found">
          <p>
            We couldn&apos;t find the person you were looking for. Person detail might expired or
            the URL for the person is incorrect. Please check the URL and try again or provide a new
            search.
          </p>
          <Navigate to="/search" icon={SearchLocate}>
            Search for a person
          </Navigate>
        </ErrorContent>
      );
    }
  }

  return (
    <CenteredContent>
      <FlexGrid condensed>
        <Row>
          <Column sm={4} md={8} lg={16} xlg={{ span: 12, offset: 2 }}>
            <Card>
              <MainProfile person={person} loading={loading || fetching} />
            </Card>
            <Card className={styles.details}>
              <Details loading={loading || fetching} person={person} />
            </Card>
          </Column>
        </Row>
      </FlexGrid>
    </CenteredContent>
  );
};

export default DetailPage;

import React from 'react';
import { Tag } from '@carbon/react';
import styles from './searchResultCard.module.scss';

interface MatchProbabilityProps {
  probability: number;
}

const MatchProbability = ({ probability }: MatchProbabilityProps) => {
  const getColorType = () => {
    if (probability > 0.8) {
      return 'green';
    }

    if (probability > 0.5) {
      return 'blue';
    }

    return 'gray';
  };

  return (
    <Tag size="sm" title="Match probability" type={getColorType()} className={styles.tag}>
      {(probability * 100).toFixed(0)}%
    </Tag>
  );
};

export default MatchProbability;

import isPlainObject from 'lodash/isPlainObject';

type RecursivelyReplaceNullWithUndefined<T> = T extends null
  ? undefined
  : T extends Date
  ? T
  : {
      [K in keyof T]: T[K] extends (infer U)[]
        ? RecursivelyReplaceNullWithUndefined<U>[]
        : RecursivelyReplaceNullWithUndefined<T[K]>;
    };

const replaceNullsWithUndefined = <T extends {}>(
  obj: T
): RecursivelyReplaceNullWithUndefined<T> => {
  const newObj: any = {};

  Object.keys(obj).forEach((key) => {
    const value: any = (obj as any)[key];
    if (value === null) {
      newObj[key as keyof T] = undefined;
    } else {
      newObj[key as keyof T] =
        value && isPlainObject(value) ? replaceNullsWithUndefined(value) : value;
    }
  });

  return newObj;
};

export default replaceNullsWithUndefined;

import React from 'react';
import styles from './centeredContent.module.scss';

export interface CenteredContentProps {
  children: React.ReactNode;
}

const CenteredContent = ({ children }: CenteredContentProps) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>{children}</div>
  </div>
);

export default CenteredContent;

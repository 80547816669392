import React from 'react';
import { Navigate as RouterNavigate, useLocation } from 'react-router-dom';
import { UserRole } from '@types';
import useAuth from '@hooks/useAuth';
import ErrorContent from '@components/ErrorContent';
import Navigate from '@components/Navigate';

interface ProtectedRouteProps {
  granted?: UserRole | UserRole[];
  children: JSX.Element;
  /**
   * if TRUE, the content is rendered during fetching of the logged in user so the child component
   * can implement their own loader during loading state
   */
  forwardLoading?: boolean;
}

const ProtectedRoute = ({ granted, children, forwardLoading = false }: ProtectedRouteProps) => {
  const auth = useAuth();
  const location = useLocation();

  if (auth.loading) {
    return forwardLoading ? children : null;
  }

  if (!auth.user) {
    return <RouterNavigate to="/login" replace state={{ from: location }} />;
  }

  if (granted && !([] as UserRole[]).concat(granted).includes(auth.user.role)) {
    return (
      <ErrorContent title="Unauthorized">
        <p>
          It appears that you don&apos;t have permissions to access this page. Please make sure you
          are authorized to view this content.
        </p>
        <Navigate to="/">Return to homepage</Navigate>
      </ErrorContent>
    );
  }

  return children;
};

export default ProtectedRoute;

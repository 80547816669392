import React from 'react';
import classNames from 'classnames';
import { Tile } from '@carbon/react';
import styles from './card.module.scss';

export interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Card = ({ children, className }: CardProps) => (
  <Tile className={classNames(styles.container, className)}>{children}</Tile>
);

export default Card;

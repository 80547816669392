import React, { useCallback } from 'react';
import useOnClickOutside from '@hooks/useOnClickOutside';

export interface ClickAwayListenerProps {
  children: React.ReactNode;
  onClickAway: (event?: MouseEvent | TouchEvent) => void;
  disabled?: boolean;
}

const ClickAwayListener = ({ children, onClickAway, disabled = false }: ClickAwayListenerProps) => {
  const handleClick = useCallback(
    (event?: MouseEvent | TouchEvent) => {
      if (!disabled) {
        onClickAway(event);
      }
    },
    [disabled, onClickAway]
  );

  const ref = useOnClickOutside<HTMLDivElement>(handleClick);

  return <div ref={ref}>{children}</div>;
};

export default ClickAwayListener;

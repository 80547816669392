import { useReducer, useMemo } from 'react';

interface LoaderState {
  loading: boolean;
  error: Nullable<string>;
}

interface ActionError {
  type: 'ERROR';
  payload: {
    error: string;
  };
}

type LoaderAction =
  | ActionError
  | {
      type: 'START' | 'SUCCESS' | 'RESET';
    };

const reducer = (state: LoaderState, action: LoaderAction): LoaderState => {
  switch (action.type) {
    case 'START':
      return {
        loading: true,
        error: null,
      };
    case 'SUCCESS':
      return {
        loading: false,
        error: null,
      };
    case 'ERROR':
      return {
        loading: false,
        error: action.payload.error,
      };
    case 'RESET':
      return {
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

const useLoader = () => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    error: null,
  });

  const actions = useMemo(
    () => ({
      start: () => dispatch({ type: 'START' }),
      success: () => dispatch({ type: 'SUCCESS' }),
      error: (err: string) => dispatch({ type: 'ERROR', payload: { error: err } }),
      reset: () => dispatch({ type: 'RESET' }),
    }),
    []
  );

  return { loading: state.loading, error: state.error, actions };
};

export default useLoader;

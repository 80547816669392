import client from '@services/client';
import { gql } from '@services/gql';
import logger from '@services/logger';
import { StatisticsData } from '../../types';

gql(/* GraphQL */ `
  fragment Statistics on Statistics {
    total
    match
    noMatch
    error
  }
`);

const FETCH_STATISTICS = gql(/* GraphQL */ `
  query fetchUserStatistics($user: ID!, $year: Int!) {
    userStatistics(user: $user, year: $year) {
      yearly {
        year
        statistics {
          ...Statistics
        }
      }
      monthly {
        month
        statistics {
          ...Statistics
        }
      }
    }
  }
`);

const fetchStatistics = async (filter: {
  user: string;
  year: number;
}): Promise<Nullable<StatisticsData>> => {
  try {
    const response = await client.query({
      query: FETCH_STATISTICS,
      variables: { user: filter.user, year: filter.year },
    });

    return response.data.userStatistics || null;
  } catch (error: unknown) {
    if (error instanceof Error) {
      logger.error(error.message, { filter });
    }

    throw error;
  }
};

export default fetchStatistics;

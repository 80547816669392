import { useReducer, useCallback, useEffect } from 'react';
import { useIsFirstRender } from 'usehooks-ts';
import debounce from 'lodash/debounce';
import { SortOrder, SortOption, CursorDataFetchResolver } from '@types';

interface CursorDataFetchOptions<T> {
  pageSize?: number;
  sort?: SortOption;
  preFetch?: boolean; // fetch on initial render
  filter?: T;
}

interface CursorDataFetchState<T, U> {
  count: number;
  data: T[];
  loading: boolean;
  error: boolean;
  fullText: string;
  sortBy: Nullable<string>;
  filterBy: Nullable<U>;
  order: SortOrder;
  pageSize: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: Nullable<string>;
  endCursor: Nullable<string>;
}

interface FetchOptions<T extends Record<string, any> = {}> {
  fullText?: string;
  filter?: T;
  sort?: Partial<SortOption>;
  pageSize?: number;
  after?: string;
  before?: string;
  preserveCount?: boolean;
}

type ConfigurableInitialParams<T, U> = Pick<
  CursorDataFetchState<T, U>,
  'filterBy' | 'sortBy' | 'order' | 'pageSize'
>;

interface ActionFetch<T extends Record<string, any> = {}> {
  type: 'FETCH';
  payload: FetchOptions<T>;
}

interface ActionReset<T, U> {
  type: 'RESET';
  payload: ConfigurableInitialParams<T, U>;
}

interface ActionSuccess<T> {
  type: 'SUCCESS';
  payload: {
    count: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: Nullable<string>;
    endCursor: Nullable<string>;
    data: T[];
  };
}

interface ActionError {
  type: 'ERROR';
}

interface ActionFullText {
  type: 'FULL_TEXT';
  payload: {
    text: string;
  };
}

type CursorDataFetchAction<T, U extends Record<string, any> = {}> =
  | ActionFetch<U>
  | ActionReset<T, U>
  | ActionSuccess<T>
  | ActionError
  | ActionFullText;

const reducer = <T, U extends Record<string, any> = {}>(
  state: CursorDataFetchState<T, U>,
  action: CursorDataFetchAction<T, U>
): CursorDataFetchState<T, U> => {
  switch (action.type) {
    case 'FETCH': {
      const newState: Partial<CursorDataFetchState<T, U>> = {
        loading: true,
        error: false,
      };

      if (action.payload.fullText) {
        newState.fullText = action.payload.fullText || state.fullText;
      }
      if (action.payload.filter) {
        newState.filterBy = action.payload.filter || state.filterBy;
      }
      if (action.payload.sort) {
        newState.sortBy = action.payload.sort.field || state.sortBy;
        newState.order = action.payload.sort.order || state.order;
      }
      if (action.payload.pageSize) {
        newState.pageSize = action.payload.pageSize;
      }

      return { ...state, ...newState };
    }
    case 'RESET':
      return {
        ...state,
        ...action.payload,
        fullText: '',
      };
    case 'SUCCESS':
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
      };
    case 'FULL_TEXT':
      return {
        ...state,
        fullText: action.payload.text,
      };
    default:
      return state;
  }
};

const useCursorDataFetch = <T, U extends Record<string, any> = {}>(
  resolver: CursorDataFetchResolver<T, U>,
  options: CursorDataFetchOptions<U> = {}
) => {
  const initialParams: ConfigurableInitialParams<T, U> = {
    filterBy: options.filter || null,
    sortBy: options.sort?.field || null,
    order: options.sort?.order || 'ASC',
    pageSize: options.pageSize || 10,
  };
  const isFirstRender = useIsFirstRender();
  const [state, dispatch] = useReducer<
    React.Reducer<CursorDataFetchState<T, U>, CursorDataFetchAction<T, U>>
  >(reducer, {
    count: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    startCursor: null,
    endCursor: null,
    data: [],
    loading: false,
    error: false,
    fullText: '',
    ...initialParams,
  });

  const fetchData = async (fetchOptions: FetchOptions<U> = {}) => {
    try {
      const { sort, filter, pageSize, after, before, preserveCount } = fetchOptions;

      dispatch({ type: 'FETCH', payload: fetchOptions });
      const response = await resolver({
        limit: pageSize || state.pageSize,
        fullText: fetchOptions.fullText || state.fullText,
        filterBy: filter || state.filterBy,
        sortBy: sort?.field || state.sortBy,
        order: sort?.order || state.order,
        before: before || null,
        after: after || null,
      });

      const { count, ...rest } = response;
      const payload = { ...rest, count: preserveCount ? state.count : count };

      dispatch({ type: 'SUCCESS', payload });
    } catch (error) {
      dispatch({ type: 'ERROR' });
    }
  };

  // 1st fetch
  useEffect(() => {
    if (isFirstRender && options.preFetch) {
      fetchData();
    }
  }, [options.preFetch]);

  const debounceFullTextSearch = useCallback(
    debounce((text) => {
      fetchData({ fullText: text });
    }, 300),
    [state.sortBy, state.filterBy, state.order, state.pageSize]
  );

  const searchByText = useCallback(
    (text: string) => {
      dispatch({ type: 'FULL_TEXT', payload: { text } });
      debounceFullTextSearch(text);
    },
    [state.sortBy, state.filterBy, state.order, state.pageSize]
  );

  const filterBy = useCallback(
    (filter: U) => {
      fetchData({ filter });
    },
    [state.fullText, state.pageSize, state.sortBy, state.order, state.filterBy]
  );

  const sortBy = useCallback(
    (sort: Partial<SortOption>) => {
      fetchData({ sort });
    },
    [state.fullText, state.pageSize, state.filterBy]
  );

  const nextPage = useCallback(() => {
    if (state.hasNextPage && state.endCursor) {
      fetchData({ after: state.endCursor, preserveCount: true });
    }
  }, [
    state.endCursor,
    state.hasNextPage,
    state.fullText,
    state.filterBy,
    state.sortBy,
    state.order,
    state.pageSize,
    state.count,
  ]);

  const previousPage = useCallback(() => {
    if (state.hasPreviousPage && state.startCursor) {
      fetchData({ before: state.startCursor, preserveCount: true });
    }
  }, [
    state.startCursor,
    state.hasPreviousPage,
    state.fullText,
    state.filterBy,
    state.sortBy,
    state.order,
    state.pageSize,
    state.count,
  ]);

  const setPageSize = useCallback(
    (size: number) => {
      if (state.pageSize !== size) {
        fetchData({ pageSize: size });
      }
    },
    [state.fullText, state.sortBy, state.filterBy, state.order, state.pageSize]
  );

  const fetch = useCallback(() => {
    fetchData();
  }, [state.fullText, state.sortBy, state.filterBy, state.order, state.pageSize]);

  const reset = useCallback(() => {
    dispatch({ type: 'RESET', payload: initialParams });

    (async () => {
      try {
        dispatch({ type: 'FETCH', payload: {} });
        const response = await resolver({
          limit: initialParams.pageSize,
          fullText: '',
          filterBy: initialParams.filterBy,
          sortBy: initialParams.sortBy,
          order: initialParams.order,
          before: null,
          after: null,
        });

        const { count, ...rest } = response;
        const payload = { ...rest, count };

        dispatch({ type: 'SUCCESS', payload });
      } catch (error) {
        dispatch({ type: 'ERROR' });
      }
    })();
  }, [initialParams]);

  return {
    count: state.count,
    data: state.data,
    loading: state.loading,
    error: state.error,
    sortedBy: state.sortBy,
    filteredBy: state.filterBy,
    order: state.order,
    fullText: state.fullText,
    pageSize: state.pageSize,
    hasNextPage: state.hasNextPage,
    hasPreviousPage: state.hasPreviousPage,
    searchByText,
    filterBy,
    sortBy,
    nextPage,
    previousPage,
    setPageSize,
    refetch: fetch,
    fetch,
    reset,
  };
};

export default useCursorDataFetch;

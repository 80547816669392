import React from 'react';
import times from 'lodash/times';
import random from 'lodash/random';
import classNames from 'classnames';
import { SkeletonText, SkeletonPlaceholder, Stack } from '@carbon/react';
import spacing from '@styles/spacing.module.scss';
import baseStyle from '@styles/base.module.scss';
import styles from './pieChart.module.scss';

interface PieChartSkeletonProps {
  items: number;
}

const PieChartSkeleton = ({ items }: PieChartSkeletonProps) => (
  <div className={baseStyle.dFlex}>
    <div className={classNames(spacing.py8, spacing.mr8)}>
      <Stack gap={4}>
        {times(items, (rowIndex) => (
          <SkeletonText key={rowIndex} width={`${random(80, 120)}px`} />
        ))}
      </Stack>
    </div>
    <SkeletonPlaceholder className={styles.chartSkeleton} />
  </div>
);

export default PieChartSkeleton;

import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { SkeletonPlaceholder } from '@carbon/react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { type Payload } from 'recharts/types/component/DefaultTooltipContent';
import COLORS_MAP from '@data/colorsMap';
import useBreakpoint from '@hooks/useBreakpoint';
import LoadingWrapper from '@components/LoadingWrapper';
import styles from './monthlyBarChart.module.scss';

export interface MonthlyBarChartData {
  [key: string]: number;
  month: number;
}

export interface MonthlyBarChartOption {
  key: string;
  color: 'blue' | 'green' | 'red' | 'gray' | 'lightGray';
}

interface MonthlyBarChartProps {
  loading: boolean;
  data: MonthlyBarChartData[];
  barOptions: MonthlyBarChartOption[];
  layout?: 'horizontal' | 'vertical';
  loadingAriaLabel?: string;
  labelFormatter: (month: number) => string;
  tooltipFormatter: (value: number, key: string, item: Record<string, number>) => [number, string];
}

const MonthlyBarChart = ({
  loading,
  data,
  barOptions,
  labelFormatter,
  tooltipFormatter,
  loadingAriaLabel = 'Loading monthly bar chart',
  layout,
}: MonthlyBarChartProps) => {
  const { breakpoint } = useBreakpoint();
  const tooltipCursor = useMemo(() => ({ fill: COLORS_MAP.lightGray, fillOpacity: 0.6 }), []);
  const orientation = useMemo(() => {
    if (layout) {
      return layout;
    }

    return breakpoint === 'sm' ? 'vertical' : 'horizontal';
  }, [layout, breakpoint]);

  const margin = useMemo(
    () => ({
      top: 20,
      bottom: 20,
      right: orientation === 'vertical' ? 40 : 10,
      left: orientation === 'vertical' ? -25 : -15,
    }),
    [orientation]
  );

  const formatMonth = useCallback(
    (month: number) => DateTime.fromObject({ month }).toFormat('MMMM'),
    []
  );

  const formatMonthAbbr = useCallback(
    (month: number) => DateTime.fromObject({ month }).toFormat('MMM'),
    []
  );

  const handleTooltipFormatter = useCallback(
    (value: number, name: string, properties: Payload<number, string>): [number, string] =>
      tooltipFormatter(value, name, properties.payload),
    [tooltipFormatter]
  );

  if (loading) {
    return (
      <LoadingWrapper ariaLabel={loadingAriaLabel}>
        <SkeletonPlaceholder
          className={classNames(styles.container, {
            [styles.vertical]: orientation === 'vertical',
            [styles.horizontal]: orientation === 'horizontal',
          })}
        />
      </LoadingWrapper>
    );
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.vertical]: orientation === 'vertical',
        [styles.horizontal]: orientation === 'horizontal',
      })}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart key={orientation} margin={margin} data={data} layout={orientation}>
          {/* CartesianGrid breaks the chart on mobile */}
          {breakpoint !== 'sm' && <CartesianGrid strokeDasharray="2 3" />}
          {orientation === 'vertical' ? (
            <React.Fragment>
              <XAxis type="number" />
              <YAxis type="category" dataKey="month" tickFormatter={formatMonthAbbr} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <XAxis type="category" dataKey="month" tickFormatter={formatMonthAbbr} />
              <YAxis type="number" />
            </React.Fragment>
          )}
          <Tooltip
            labelFormatter={formatMonth}
            formatter={handleTooltipFormatter}
            cursor={tooltipCursor}
          />
          {barOptions.map((option, index) => {
            if (index === barOptions.length - 1) {
              return (
                <Bar
                  key={option.key}
                  dataKey={option.key}
                  stackId="a"
                  fill={COLORS_MAP[option.color]}
                >
                  <LabelList
                    dataKey="month"
                    position={orientation === 'vertical' ? 'right' : 'top'}
                    formatter={labelFormatter}
                    offset={5}
                  />
                </Bar>
              );
            }

            return (
              <Bar
                key={option.key}
                dataKey={option.key}
                stackId="a"
                fill={COLORS_MAP[option.color]}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default React.memo(MonthlyBarChart);

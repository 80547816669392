import React from 'react';
import { SkeletonText, TabsSkeleton } from '@carbon/react';
import spacing from '@styles/spacing.module.scss';
import styles from './detailTabs.module.scss';

const DetailTabsSkeleton = () => (
  <React.Fragment>
    <TabsSkeleton className={styles.tabsSkeleton} />
    <div className={spacing.mt6}>
      <SkeletonText heading className={spacing.mb6} />
      <div className={spacing.mb6}>
        <SkeletonText lineCount={2} paragraph />
      </div>
      <div className={spacing.mb6}>
        <SkeletonText lineCount={3} paragraph />
      </div>
      <div className={spacing.mb6}>
        <SkeletonText lineCount={1} paragraph />
      </div>
      <div className={spacing.mb6}>
        <SkeletonText lineCount={2} paragraph />
      </div>
      <SkeletonText lineCount={1} paragraph />
    </div>
  </React.Fragment>
);

export default DetailTabsSkeleton;

import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BasicSearchParams } from '@mantis/types';
import { LocationFilled } from '@carbon/icons-react';
import { Layer, Form, Stack, TextInput, Button, InlineLoading } from '@carbon/react';
import ErrorMessage from '@components/ErrorMessage';
import ButtonLoader from '@components/ButtonLoader';
import spacing from '@styles/spacing.module.scss';
import baseStyles from '@styles/base.module.scss';
import styles from './basicSearchForm.module.scss';
import Description from './Description';

interface Fields {
  query: string;
  location: string;
}

export interface BasicSearchFormProps {
  onSubmit: (data: BasicSearchParams) => void;
  onUseMyLocation: () => Promise<string | null>;
  initialValues?: BasicSearchParams;
  loading?: boolean;
  errorMessage?: string;
}

const BasicSearchSchema = Yup.object().shape({
  query: Yup.string()
    .trim()
    .required('This field is required')
    .min(3, 'Must be at least 3 characters'),
  location: Yup.string().trim(),
});

const BasicSearchForm = ({
  onSubmit,
  onUseMyLocation,
  initialValues,
  loading = false,
  errorMessage = '',
}: BasicSearchFormProps) => {
  const [fetching, setFetching] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, resetForm } =
    useFormik({
      initialValues: { query: '', location: '', ...(initialValues || {}) },
      validationSchema: BasicSearchSchema,
      onSubmit: (data: Fields) => {
        onSubmit({
          query: data.query.trim(),
          location: data.location.trim(),
        });
      },
    });

  const handleResetForm = () => {
    resetForm({
      values: {
        query: '',
        location: '',
      },
    });
  };

  const handleUseMyLocation = async () => {
    try {
      setFetching(true);
      const myLocation = await onUseMyLocation();
      setFieldValue('location', myLocation || '');

      return true;
    } catch (error) {
      return false;
    } finally {
      setFetching(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={6}>
        {errorMessage && !loading && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Layer>
          <TextInput
            id="query"
            labelText="Search query"
            placeholder="Name (first and last name), username, user ID, email or phone"
            title="Search query"
            value={values.query}
            onChange={handleChange}
            invalid={errors.query && touched.query}
            invalidText={errors.query}
            hideLabel
          />
        </Layer>
        <Layer>
          <TextInput
            id="location"
            labelText="Location"
            placeholder="Location (optional)"
            title="Location"
            value={values.location}
            onChange={handleChange}
            invalid={errors.location && touched.location}
            invalidText={errors.location}
            hideLabel
          />
        </Layer>
        <ButtonLoader
          onClick={handleUseMyLocation}
          loadingText="Fetching location..."
          successText="Location fetched"
          errorText="Failed to fetch location"
          asLinkButton
          classes={{
            loader: styles.inlineLoader,
          }}
        >
          <LocationFilled className={spacing.mr3} />
          Use my location
        </ButtonLoader>
        <Description />
        {!loading ? (
          <div className={baseStyles.dFlex}>
            <Button type="submit" disabled={fetching}>
              Search
            </Button>
            <Button
              kind="secondary"
              type="reset"
              disabled={fetching}
              className={spacing.ml4}
              onClick={handleResetForm}
            >
              Reset
            </Button>
          </div>
        ) : (
          <InlineLoading description="Searching..." />
        )}
      </Stack>
    </Form>
  );
};

export default React.memo(BasicSearchForm);

import * as colors from '@carbon/colors';

const COLORS_MAP = {
  blue: colors.blue60,
  green: colors.green60,
  red: colors.red60,
  gray: colors.gray50,
  lightGray: colors.gray20,
} as const;

export default COLORS_MAP;

import React from 'react';
import { UserRole } from '@types';
import { InlineNotification } from '@carbon/react';
import LinkButton from '@components/LinkButton';
import styles from './warning.module.scss';

interface WarningProps {
  userRole: UserRole;
  onReset: () => void;
}

const Warning = ({ userRole, onReset }: WarningProps) => (
  <InlineNotification
    className={styles.container}
    aria-label="closes notification"
    statusIconDescription="notification"
    role="alert"
    kind="warning"
    title="Warning: "
    hideCloseButton
    lowContrast
  >
    {userRole === 'organizationAdmin' && (
      <span className={styles.text}>User set for filtering from URL no longer exists.</span>
    )}
    {userRole === 'admin' && (
      <span className={styles.text}>Organization set for filtering from URL no longer exists.</span>
    )}
    <LinkButton onClick={onReset} className={styles.button}>
      Reset filter
    </LinkButton>
  </InlineNotification>
);

export default Warning;

import React, { useEffect, useState } from 'react';
import { ArrowLeft } from '@carbon/icons-react';
import useAuth from '@hooks/useAuth';
import useToast from '@hooks/useToast';
import UsersList from '@components/UsersList';
import Navigate from '@components/Navigate';
import baseStyles from '@styles/base.module.scss';
import fetchOrganizationName from './helpers/fetchOrganizationName';

interface OrganizationUsersProps {
  loading: boolean;
  organizationId: string;
}

const OrganizationUsers = ({ loading, organizationId }: OrganizationUsersProps) => {
  const toast = useToast();
  const { user } = useAuth();
  const [fetching, setFetching] = useState(false);
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    const fetchOrganizationNameFn = async () => {
      try {
        setFetching(true);
        const fetchedOrganizationName = await fetchOrganizationName(organizationId);
        setOrganizationName(fetchedOrganizationName);
      } catch (err) {
        toast.warning({
          title: 'Fetch organization',
          subtitle: "Organization name couldn't be fetched",
        });
      } finally {
        setFetching(false);
      }
    };

    if (!loading && !fetching && !organizationName) {
      fetchOrganizationNameFn();
    }
  }, [loading, fetching, organizationName]);

  const renderTitle = () => (
    <div className={baseStyles.spaceBetween}>
      <span>Users</span>
      <Navigate icon={ArrowLeft} iconPosition="before" to="/organizations">
        back
      </Navigate>
    </div>
  );

  return (
    <UsersList
      title={renderTitle()}
      description={`for ${organizationName} organization`}
      loading={loading || fetching}
      organization={organizationId}
      loggedInUser={user}
    />
  );
};

export default OrganizationUsers;

import React, { useContext, useEffect } from 'react';
import { TableRow as CarbonTableRow, TableSelectRow, SkeletonText } from '@carbon/react';
import TableContext from './TableContext';
import styles from './dataTable.module.scss';

interface TableRowProps {
  id: string;
  children: React.ReactNode;
}

const TableRow = ({ id, children }: TableRowProps) => {
  const { fetching, isSelectable, isSelected, select, subscribe, unsubscribe } =
    useContext(TableContext);

  useEffect(() => {
    subscribe(id);

    return () => {
      unsubscribe(id);
    };
  }, []);

  if (!isSelectable) {
    return <CarbonTableRow>{children}</CarbonTableRow>;
  }

  return (
    <CarbonTableRow>
      {fetching && <SkeletonText className={styles.checkboxSkeleton} />}
      <TableSelectRow
        id={id}
        name={`select-${id}`}
        ariaLabel="Select row"
        checked={isSelected(id)}
        onSelect={() => select(id)}
      />
      {children}
    </CarbonTableRow>
  );
};

export default TableRow;

import React from 'react';
import { SkeletonText } from '@carbon/react';
import LoadingWrapper from '@components/LoadingWrapper';

interface HeadingSkeletonProps {
  width?: number;
  ariaLabel?: string;
  className?: string;
}

const HeadingSkeleton = ({
  width = 100,
  ariaLabel = 'Loading heading',
  className,
}: HeadingSkeletonProps) => (
  <LoadingWrapper ariaLabel={ariaLabel}>
    <SkeletonText heading width={`${width.toString()}px`} className={className} />
  </LoadingWrapper>
);

export default HeadingSkeleton;

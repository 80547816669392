import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useIsMounted } from 'usehooks-ts';
import { Button, InlineLoading } from '@carbon/react';
import LinkButton from '@components/LinkButton';
import styles from './buttonLoader.module.scss';

export interface ButtonLoaderProps {
  children: React.ReactNode;
  onClick: () => Promise<boolean>;
  loadingText: string;
  successText: string;
  errorText?: string;
  asLinkButton?: boolean;
  size?: 'sm' | 'md' | 'lg';
  classes?: {
    root?: string;
    button?: string;
    loader?: string;
  };
}

const ButtonLoader = ({
  children,
  onClick,
  loadingText,
  successText,
  errorText = 'Failed',
  asLinkButton = false,
  size = 'md',
  classes = {},
}: ButtonLoaderProps) => {
  const timerId = useRef<NodeJS.Timeout>();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [statusVisible, setStatusVisible] = useState(false);

  useEffect(() => {
    if (timerId.current) {
      clearInterval(timerId.current);
    }
  }, []);

  const handleStatus = (hasSuccessResponse: boolean) => {
    if (!isMounted()) {
      return;
    }

    setLoading(false);
    setSuccess(hasSuccessResponse);

    timerId.current = setTimeout(() => {
      if (isMounted()) {
        setStatusVisible(false);
      }
    }, 2000);
  };

  const handleClick = async () => {
    try {
      setLoading(true);
      setStatusVisible(true);
      const response = await onClick();

      if (!response) {
        handleStatus(false);
      } else {
        handleStatus(true);
      }
    } catch (error) {
      handleStatus(false);
    }
  };

  const getLoaderStatus = (): string => {
    if (loading) {
      return 'active';
    }

    return success ? 'finished' : 'error';
  };

  const getLoaderText = (): string => {
    if (loading) {
      return loadingText;
    }

    return success ? successText : errorText;
  };

  const renderButton = () => {
    if (asLinkButton) {
      return (
        <LinkButton onClick={handleClick} className={classes.button}>
          {children}
        </LinkButton>
      );
    }

    return (
      <Button onClick={handleClick} className={classes.button} size={size}>
        {children}
      </Button>
    );
  };

  return (
    <div className={classes.root}>
      {statusVisible ? (
        <div className={classNames({ [styles[size]]: !asLinkButton })}>
          <InlineLoading
            className={classes.loader}
            description={getLoaderText()}
            status={getLoaderStatus()}
          />
        </div>
      ) : (
        renderButton()
      )}
    </div>
  );
};

export default ButtonLoader;

import React, { useCallback, useMemo } from 'react';
import ToastContext from '@context/ToastContext';
import { ToastType } from './types';
import useToasts from './useToasts';
import Toasts from './Toasts';

interface ToastData {
  title: string;
  subtitle?: string;
}

interface ToastProviderProps {
  children: React.ReactNode;
}

const ToastProvider = ({ children }: ToastProviderProps) => {
  const { add, close, toasts } = useToasts();

  const createAction = useCallback(
    (type: ToastType) => (data: ToastData) => add({ ...data, type }),
    []
  );

  const actions = useMemo(
    () => ({
      success: createAction('success'),
      warning: createAction('warning'),
      error: createAction('error'),
    }),
    []
  );

  return (
    <ToastContext.Provider value={actions}>
      <Toasts toasts={toasts} close={close} />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;

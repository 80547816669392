import React from 'react';
import { ButtonSkeleton, SkeletonText, SkeletonIcon, Stack } from '@carbon/react';
import baseStyles from '@styles/base.module.scss';
import spacing from '@styles/spacing.module.scss';
import styles from './searchPageSkeleton.module.scss';

const SearchSkeleton = () => (
  <React.Fragment>
    <div className={baseStyles.dFlex}>
      <SkeletonText heading width="100px" className={spacing.mr5} />
      <SkeletonText heading width="120px" />
    </div>
    <Stack gap={6} className={spacing.mt4}>
      <SkeletonText className={styles.input} />
      <SkeletonText className={styles.input} />
      <div className={baseStyles.dFlex}>
        <SkeletonIcon className={spacing.mr3} />
        <SkeletonText width="150px" />
      </div>
      <SkeletonText lineCount={2} paragraph />
      <ButtonSkeleton />
    </Stack>
  </React.Fragment>
);

export default SearchSkeleton;

import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form, Stack } from '@carbon/react';
import usePrevious from '@hooks/usePrevious';
import Modal from '@components/Modal';
import DatePicker from '@components/DatePicker';
import NumberInput from '@components/NumberInput';
import { ContractData } from '../types';

interface Fields {
  start: string;
  end: string;
  count: number;
}

interface CreateContractProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ContractData) => void;
}

const CreateContractSchema = Yup.object().shape({
  start: Yup.date().required('Start date is required'),
  end: Yup.date().required('End date is required'),
  count: Yup.number().min(1, 'Number of searches is required').integer(),
});

const CreateContract = ({ isOpen, onClose, onSubmit }: CreateContractProps) => {
  const previouslyOpen = usePrevious(isOpen);
  const initialValues: Fields = {
    start: '',
    end: '',
    count: 0,
  };

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema: CreateContractSchema,
    onSubmit: (data: ContractData) => {
      onSubmit(data);
    },
  });

  // reset form once it's closed
  useEffect(() => {
    if (!isOpen && previouslyOpen) {
      resetForm({
        values: initialValues,
      });
    }
  }, [isOpen, previouslyOpen]);

  const handleDateChange = (field: 'start' | 'end') => (date: Nullable<string>) => {
    setFieldValue(field, date || '');
  };

  const handleCountChange = (value: Nullable<number>) => {
    setFieldValue('count', value || 0);
  };

  return (
    <Modal
      isOpen={isOpen}
      heading="Add contract"
      primaryButtonText="Submit"
      secondaryButtonText="Cancel"
      onClose={onClose}
      onSubmit={handleSubmit}
      size="sm"
    >
      <Form onSubmit={handleSubmit}>
        <Stack gap={6}>
          <DatePicker
            id="create.startDate"
            title="Start date"
            onChange={handleDateChange('start')}
            invalid={!!errors.start && touched.start}
            invalidText={errors.start}
            maxDate={values.end ? new Date(values.end) : null}
            value={values.start ? new Date(values.start) : null}
          />
          <DatePicker
            id="create.endDate"
            title="End date"
            onChange={handleDateChange('end')}
            invalid={!!errors.end && touched.end}
            invalidText={errors.end}
            minDate={values.start ? new Date(values.start) : null}
            value={values.end ? new Date(values.end) : null}
          />
          <NumberInput
            id="create.numberOfSearches"
            label="Number of searches"
            allowEmpty
            min={0}
            value={values.count || null}
            onChange={handleCountChange}
            invalid={!!errors.count && touched.count}
            invalidText={errors.count}
          />
        </Stack>
      </Form>
    </Modal>
  );
};

export default CreateContract;

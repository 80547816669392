import React from 'react';
import { useQuery } from '@apollo/client';
import { Routes, Route, Navigate as RouterNavigate } from 'react-router-dom';
import { gql } from '@services/gql';
import useAuth from '@hooks/useAuth';
import Layout from '@components/Layout';
import ProtectedRoute from '@components/ProtectedRoute';
import ErrorContent from '@components/ErrorContent';
import Navigate from '@components/Navigate';
import LoginPage from '@pages/Login';
import SignupPage, { VerifyEmailPage } from '@pages/Signup';
import ForgotPasswordPage from '@pages/ForgotPassword';
import ResetPasswordPage from '@pages/ResetPassword';
import SearchPage, { SearchResult } from '@pages/Search';
import DetailPage from '@pages/Detail';
import OrganizationsPage, { OrganizationUsersPage, ContractsPage } from '@pages/Organization';
import UsersPage from '@pages/Users';
import ProfilePage from '@pages/Profile';
import SearchHistoryPage from '@pages/SearchHistory';
import StatisticsPage from '@pages/Statistics';

const FETCH_APP_DATA = gql(/* GraphQL */ `
  query fetchApplicationData {
    application {
      warningMessage
    }
  }
`);

const App = () => {
  const { user, loading } = useAuth();
  const { data } = useQuery(FETCH_APP_DATA, { pollInterval: 1000 * 60 });

  return (
    <Layout
      warningMessage={data?.application.warningMessage || ''}
      authenticated={!!user}
      loading={loading}
      user={user}
    >
      <Routes>
        <Route path="/" element={<RouterNavigate to="/search" replace />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/signup/verify" element={<VerifyEmailPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route
          path="/search"
          element={
            <ProtectedRoute forwardLoading>
              <SearchPage loading={loading} />
            </ProtectedRoute>
          }
        >
          <Route path="result" element={<SearchResult />} />
        </Route>
        <Route
          path="/detail/:id"
          element={
            <ProtectedRoute forwardLoading>
              <DetailPage loading={loading} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/organizations"
          element={
            <ProtectedRoute granted="admin" forwardLoading>
              <OrganizationsPage loading={loading} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/organization/:id/users"
          element={
            <ProtectedRoute granted="admin" forwardLoading>
              <OrganizationUsersPage loading={loading} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/organization/:id/contracts"
          element={
            <ProtectedRoute granted="admin" forwardLoading>
              <ContractsPage loading={loading} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute granted="organizationAdmin" forwardLoading>
              <UsersPage loading={loading} loggedInUser={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/history"
          element={
            <ProtectedRoute granted={['admin', 'organizationAdmin']} forwardLoading>
              <SearchHistoryPage loading={loading} loggedInUser={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/statistics"
          element={
            <ProtectedRoute forwardLoading>
              <StatisticsPage loading={loading} loggedInUser={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute forwardLoading>
              <ProfilePage loading={loading} loggedInUser={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <ErrorContent title="Page not found">
              <p>
                This page is not available. The link you followed may be broken or may have been
                removed.
              </p>
              <Navigate to="/">Return to homepage</Navigate>
            </ErrorContent>
          }
        />
      </Routes>
    </Layout>
  );
};

export default App;

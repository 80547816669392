import React from 'react';
// components
import { CheckmarkFilled, ErrorFilled } from '@carbon/icons-react';
// styles
import styles from './result.module.scss';

interface ResultProps {
  success: boolean;
  title: string;
  children: React.ReactNode;
}

const Result = ({ success, title, children }: ResultProps) => (
  <div className={styles.container}>
    <div className={styles.headingWrapper}>
      {success ? (
        <CheckmarkFilled size={20} className={styles.success} />
      ) : (
        <ErrorFilled size={20} className={styles.error} />
      )}
      <h1 className={styles.heading}>{title}</h1>
    </div>
    {children}
  </div>
);

export default Result;

import React from 'react';
import { CheckmarkFilled } from '@carbon/icons-react';
import { Divider } from '@components/Form';
import Navigate from '@components/Navigate';
import ButtonLoader from '@components/ButtonLoader';
import spacing from '@styles/spacing.module.scss';
import styles from './emailVerification.module.scss';

export interface EmailVerificationProps {
  onResendMail: () => Promise<boolean>;
}

const EmailVerification = ({ onResendMail }: EmailVerificationProps) => (
  <div className={styles.container}>
    <div className={styles.headingWrapper}>
      <CheckmarkFilled size={20} className={styles.icon} />
      <h1 className={styles.heading}>Successfully signed up</h1>
    </div>
    <p>
      Thanks for signing up! Please check your email inbox and confirm your email address to get
      started.
    </p>
    <p>If you can&apos;t find the email check your spam folder or click below to re-send email.</p>
    <ButtonLoader
      classes={{ root: spacing.mt5 }}
      onClick={onResendMail}
      loadingText="Sending..."
      successText="Sent!"
    >
      Resend email
    </ButtonLoader>
    <Divider />
    Already active account ? <Navigate to="/login">Login</Navigate>
  </div>
);

export default EmailVerification;

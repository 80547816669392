import React, { useContext } from 'react';
import classNames from 'classnames';
import { TableCell as CarbonTableCell, SkeletonText } from '@carbon/react';
import TableContext from './TableContext';
import styles from './dataTable.module.scss';

interface TableCellProps {
  children: React.ReactNode;
  className?: string;
}

const TableCell = ({ children, className }: TableCellProps) => {
  const { fetching } = useContext(TableContext);

  return (
    <CarbonTableCell className={classNames(styles.cell, className)}>
      {fetching ? <SkeletonText className={styles.cellSkeleton} /> : children}
    </CarbonTableCell>
  );
};

export default TableCell;

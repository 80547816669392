import React, { useState } from 'react';
import { forgotPassword, resendPasswordResetLink } from '@services/auth';
import { FlexGrid, Row, Column } from '@carbon/react';
import CenteredContent from '@components/CenteredContent';
import Card from '@components/Card';
import ForgotPasswordForm from './ForgotPasswordForm';
import EmailVerification from './EmailVerification';

const ForgotPasswordPage = () => {
  const [resetEmail, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (email: string) => {
    try {
      setLoading(true);
      const success = await forgotPassword(email);

      if (success) {
        setEmail(email);
      } else {
        setError('Something went wrong');
      }

      return success;
    } catch (err: unknown) {
      setError('Something went wrong');

      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <CenteredContent>
      <FlexGrid condensed>
        <Row>
          <Column sm={4} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 5 }}>
            <Card>
              {!resetEmail ? (
                <ForgotPasswordForm
                  loading={loading}
                  errorMessage={error}
                  onSubmit={handleSubmit}
                />
              ) : (
                <EmailVerification
                  email={resetEmail}
                  onResendMail={() => resendPasswordResetLink(resetEmail)}
                  onNewRequest={() => setEmail('')}
                />
              )}
            </Card>
          </Column>
        </Row>
      </FlexGrid>
    </CenteredContent>
  );
};

export default ForgotPasswordPage;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { SelectOption } from '@types';
import usePrevious from '@hooks/usePrevious';
import useToast from '@hooks/useToast';
import ComboBox from '@components/ComboBox';
import Select from '@components/Select';
import fetchUsers from './helpers/fetchUsers';
import { UserStatisticsFilter, UserData, UserSelectOption } from '../types';
import styles from './userStatistics.module.scss';

interface FiltersProps {
  isAdmin: boolean;
  loading: boolean;
  userData: UserData;
  onChange: (filter: UserStatisticsFilter) => void;
}

const Filters = ({ isAdmin, loading, userData, onChange }: FiltersProps) => {
  const currentYear = new Date().getFullYear();
  const previousUserId = usePrevious(userData.id);
  const toast = useToast();
  const [fetching, setFetching] = useState(true);
  const [users, setUsers] = useState<UserSelectOption[]>([]);
  const [filter, setFilter] = useState<UserStatisticsFilter>({
    user: userData.id,
    organization: userData.organization.id,
    year: currentYear,
  });

  const yearOptions = useMemo(() => {
    const options: SelectOption[] = [];

    // logged user is NOT admin - from logged in user creation year
    if (!isAdmin) {
      for (let year = userData.creationYear; year <= currentYear; year += 1) {
        options.push({ label: year.toString(), value: year.toString() });
      }

      return options;
    }

    // logged user is admin - from selected org creation year
    const selectedOrganization = users.find((user) => user.value === filter.user);

    if (!selectedOrganization) {
      return [
        {
          label: currentYear.toString(),
          value: currentYear.toString(),
        },
      ];
    }

    for (let year = selectedOrganization.createdYear; year <= currentYear; year += 1) {
      options.push({ label: year.toString(), value: year.toString() });
    }

    return options;
  }, [filter.user, filter.year, users, currentYear]);

  // update org filter if "user data" prop is changed
  useEffect(() => {
    if (userData.id && userData.id !== previousUserId) {
      setFilter({ ...filter, user: userData.id });
    }
  }, [userData.id, previousUserId, filter.year, filter.organization]);

  // fetch filter options
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const fetchedUsers = await fetchUsers();
        setUsers(fetchedUsers);
      } catch (error) {
        toast.warning({ title: 'Filter options', subtitle: 'Failed to fetch users' });
      } finally {
        setFetching(false);
      }
    };

    if (isAdmin && !loading) {
      fetchItems();
    } else {
      setFetching(false);
    }
  }, [isAdmin, loading]);

  const handleUserChange = useCallback(
    (selectedUserId: string) => {
      const selectedUser = users.find((user) => user.value === selectedUserId);

      if (!selectedUser) {
        return;
      }

      const newFilter: UserStatisticsFilter = {
        user: selectedUserId,
        organization: selectedUser.organization,
        year: filter.year,
      };

      // if selected year is before user creation year -> use current year instead of previously selected year
      if (selectedUser && selectedUser.createdYear > filter.year) {
        newFilter.year = currentYear;
      }

      setFilter(newFilter);
      onChange(newFilter);
    },
    [onChange, users, filter.year]
  );

  const handleYearChange = useCallback(
    (value: string) => {
      const newFilter = { ...filter, year: parseInt(value, 10) };

      setFilter(newFilter);
      onChange(newFilter);
    },
    [onChange, filter.user, filter.organization]
  );

  return (
    <div className={styles.filters}>
      {isAdmin && (
        <ComboBox
          id="user"
          title="User"
          hideLabel
          placeholder="Filter by user"
          selected={filter.user}
          items={users}
          loading={loading || fetching}
          onChange={handleUserChange}
        />
      )}
      <Select
        id="year"
        title="Year"
        hideLabel
        selected={filter.year.toString()}
        items={yearOptions}
        loading={loading || fetching}
        onChange={handleYearChange}
      />
    </div>
  );
};

export default Filters;

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import useToast from '@hooks/useToast';
import useModal from '@hooks/useModal';
import { Contract } from '@types';
import useContracts from './useContracts';
import CreateContract from '../CreateContract';
import EditContract from '../EditContract';
import ContractsTable from '../ContractsTable';
import { ContractData } from '../types';

interface ContractsListProps {
  loading: boolean;
  organizationId: string;
}

const initialSort = { field: 'start', order: 'DESC' } as const;

const ContractsList = ({ loading, organizationId }: ContractsListProps) => {
  const { openModal, closeModal, isModalOpen } = useModal<'add' | 'edit'>();
  const [contractToEdit, setContractToEdit] = useState<Nullable<Contract>>(null);
  const toast = useToast();
  const fetchedOrgId = useRef('');
  const {
    loading: fetching,
    error,
    organization,
    contracts,
    fetch,
    sortBy,
    create,
    remove,
    update,
  } = useContracts(organizationId, { initialSort });

  const contractEditData = useMemo((): ContractData => {
    if (contractToEdit) {
      return contractToEdit;
    }

    return {
      start: '',
      end: '',
      count: 0,
    };
  }, [contractToEdit]);

  useEffect(() => {
    if (!loading && fetchedOrgId.current !== organizationId) {
      fetch();
      fetchedOrgId.current = organizationId;
    }
  }, [loading, fetchedOrgId.current, organizationId]);

  const handleRefetch = useCallback(() => {
    if (!fetching) {
      fetch();
    }
  }, [fetching, fetch]);

  const handleCreate = useCallback(
    async (contract: ContractData) => {
      try {
        closeModal();
        await create(contract);
      } catch (err) {
        toast.error({ title: 'Add contract', subtitle: 'Failed to add' });
      }
    },
    [create]
  );

  const handleRemove = useCallback(
    async (id: string) => {
      try {
        await remove(id);
      } catch (err) {
        toast.error({ title: 'Remove contract', subtitle: 'Failed to remove' });
      }
    },
    [remove]
  );

  const handleEditClick = useCallback(
    (contract: Contract) => {
      setContractToEdit(contract);
      openModal('edit');
    },
    [openModal, setContractToEdit]
  );

  const handleUpdate = useCallback(
    async (data: ContractData) => {
      closeModal();

      if (!contractToEdit) {
        return;
      }

      try {
        await update(contractToEdit.id, data);
      } catch (err) {
        toast.error({ title: 'Update contract', subtitle: 'Failed to update' });
      }
    },
    [closeModal, update, contractToEdit]
  );

  return (
    <React.Fragment>
      <ContractsTable
        loading={loading}
        fetching={fetching}
        error={error}
        initialSort={initialSort}
        data={contracts}
        organization={organization}
        onAdd={() => openModal('add')}
        onEdit={handleEditClick}
        onRemove={handleRemove}
        onRefetch={handleRefetch}
        onSortBy={sortBy}
      />
      {!loading && (
        <React.Fragment>
          <CreateContract
            isOpen={isModalOpen('add')}
            onClose={closeModal}
            onSubmit={handleCreate}
          />
          <EditContract
            isOpen={isModalOpen('edit')}
            onClose={closeModal}
            onSubmit={handleUpdate}
            data={contractEditData}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ContractsList;

import React, { useMemo, useCallback } from 'react';
import { MonthlyStatistics } from '@types';
import percentage from '@utils/percentage';
import MonthlyBarChart, {
  MonthlyBarChartData,
  MonthlyBarChartOption,
} from '@components/MonthlyBarChart';

interface MonthlyUtilizationProps {
  loading: boolean;
  userStatistics: MonthlyStatistics[];
  organizationStatistics: MonthlyStatistics[];
}

const MonthlyUtilization = ({
  loading,
  userStatistics,
  organizationStatistics,
}: MonthlyUtilizationProps) => {
  const data: MonthlyBarChartData[] = useMemo(
    () =>
      userStatistics.map((monthlyUserStats, index) => {
        const userSearches = monthlyUserStats.statistics.total;
        const totalSearches = organizationStatistics[index]?.statistics.total || 0;

        return {
          month: monthlyUserStats.month,
          user: userSearches,
          total: totalSearches,
          diff: totalSearches - userSearches,
        };
      }),
    [userStatistics, organizationStatistics]
  );

  const barOptions: MonthlyBarChartOption[] = useMemo(
    () => [
      { key: 'user', color: 'blue' },
      { key: 'diff', color: 'lightGray' },
    ],
    []
  );

  const formatLabel = useCallback(
    (month: number) => {
      const monthlyUserStats = userStatistics.find((item) => item.month === month);
      const monthlyOrgStats = organizationStatistics.find((item) => item.month === month);

      if (monthlyUserStats && monthlyOrgStats) {
        if (!monthlyUserStats.statistics.total) {
          return '';
        }

        const userSearches = monthlyUserStats.statistics.total || 0;
        const totalSearches = monthlyOrgStats.statistics.total || 0;

        return `${percentage(userSearches, totalSearches, false)}%`;
      }

      return '';
    },
    [userStatistics, organizationStatistics]
  );

  const formatTooltip = useCallback(
    (value: number, key: string, item: Record<string, number>): [number, string] => {
      switch (key) {
        case 'user':
          return [value, 'User searches'];
        case 'diff':
          return [item.total, 'Total searches'];
        default:
          return [value, ''];
      }
    },
    []
  );

  return (
    <MonthlyBarChart
      loading={loading}
      loadingAriaLabel="Loading monthly utilization"
      data={data}
      barOptions={barOptions}
      labelFormatter={formatLabel}
      tooltipFormatter={formatTooltip}
    />
  );
};

export default React.memo(MonthlyUtilization);

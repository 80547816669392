import React from 'react';
import useImageOnLoad from '@hooks/useImageOnLoad';
import { SkeletonPlaceholder } from '@carbon/react';
import { Gender } from '@types';
import getPersonPlaceholder from '@utils/getPersonPlaceholder';
import Navigate from '@components/Navigate';
import baseStyles from '@styles/base.module.scss';
import styles from './imageLoader.module.scss';

interface ImageLoaderProps {
  src: string;
  alt: string;
  gender?: Gender;
  loading: boolean;
  matchPointer: string;
}

const ImageLoader = ({ src, alt, gender, loading, matchPointer }: ImageLoaderProps) => {
  const { loaded, error } = useImageOnLoad(src, {
    delayLoading: false,
    fallback: getPersonPlaceholder(gender),
  });

  if (!loading && loaded) {
    return (
      <Navigate to={`/detail/${matchPointer}`} disableFocus noStyle>
        <figure className={styles.figure}>
          <img
            className={baseStyles.imgResponsive}
            src={error ? getPersonPlaceholder(gender) : src}
            alt={alt}
          />
        </figure>
      </Navigate>
    );
  }

  return (
    <figure className={styles.figure}>
      <SkeletonPlaceholder className={styles.skeleton} title="Image loader" />
    </figure>
  );
};

export default ImageLoader;

import React from 'react';
import Modal from '@components/Modal';
import ErrorMessage from '@components/ErrorMessage';
import spacing from '@styles/spacing.module.scss';

interface DeleteUserProps {
  user: string;
  isOpen: boolean;
  loading: boolean;
  errorMessage?: string;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteUser = ({
  isOpen,
  loading,
  user,
  errorMessage,
  onClose,
  onSubmit,
}: DeleteUserProps) => (
  <Modal
    danger
    isOpen={isOpen}
    heading="Delete user"
    loading={loading}
    loadingText="Deleting..."
    primaryButtonText="Delete"
    secondaryButtonText="Cancel"
    onClose={onClose}
    onSubmit={onSubmit}
  >
    Do you really want to delete <b>{user}</b>? This process cannot be undone.
    {errorMessage && !loading && (
      <div className={spacing.mt4}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    )}
  </Modal>
);

export default React.memo(DeleteUser);

/* eslint-disable */
// Generated by 'graphql-codegen'. DO NOT EDIT.
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  EmailAddress: string;
  Latitude: number;
  Longitude: number;
  URL: string;
};

export type AddressType = 'HOME' | 'OLD' | 'WORK';

export type Application = {
  __typename?: 'Application';
  warningMessage: Maybe<Scalars['String']>;
};

export type Contract = {
  __typename?: 'Contract';
  count: Scalars['Int'];
  end: Scalars['Date'];
  id: Scalars['ID'];
  start: Scalars['Date'];
  status: ContractStatus;
  usage: Scalars['Int'];
};

export type ContractInput = {
  count: Scalars['Int'];
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type ContractStatus = 'ACTIVE' | 'EXPIRED' | 'PENDING';

export type Coordinates = {
  __typename?: 'Coordinates';
  lat: Scalars['Latitude'];
  lng: Scalars['Longitude'];
};

export type EmailMetadata = Metadata & {
  __typename?: 'EmailMetadata';
  current: Maybe<Scalars['Boolean']>;
  disposable: Maybe<Scalars['Boolean']>;
  inferred: Maybe<Scalars['Boolean']>;
  lastSeen: Maybe<Scalars['Date']>;
  validSince: Maybe<Scalars['Date']>;
};

export type EmailType = 'PERSONAL' | 'WORK';

export type FieldMetadata = Metadata & {
  __typename?: 'FieldMetadata';
  current: Maybe<Scalars['Boolean']>;
  inferred: Maybe<Scalars['Boolean']>;
  lastSeen: Maybe<Scalars['Date']>;
  validSince: Maybe<Scalars['Date']>;
};

export type Gender = 'FEMALE' | 'MALE';

export type IOrganization = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  city: Scalars['String'];
  country: Scalars['String'];
};

export type LoggedInUser = {
  __typename?: 'LoggedInUser';
  createdAt: Scalars['DateTime'];
  email: Scalars['EmailAddress'];
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  organization: OrganizationConnection;
  role: UserRole;
};

export type Metadata = {
  current: Maybe<Scalars['Boolean']>;
  inferred: Maybe<Scalars['Boolean']>;
  lastSeen: Maybe<Scalars['Date']>;
  validSince: Maybe<Scalars['Date']>;
};

export type MonthlyStatistics = {
  __typename?: 'MonthlyStatistics';
  month: Scalars['Int'];
  statistics: Statistics;
};

export type Mutation = {
  __typename?: 'Mutation';
  organizationCreate: Organization;
  organizationDelete: SuccessResponse;
  organizationUpdate: Organization;
  userCreate: User;
  userDelete: SuccessResponse;
  userResendNotification: SuccessResponse;
  userUpdate: User;
};

export type MutationorganizationCreateArgs = {
  data: OrganizationCreateInput;
};

export type MutationorganizationDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationorganizationUpdateArgs = {
  data: OrganizationUpdateInput;
  id: Scalars['ID'];
};

export type MutationuserCreateArgs = {
  data: UserCreateInput;
};

export type MutationuserDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationuserResendNotificationArgs = {
  id: Scalars['ID'];
};

export type MutationuserUpdateArgs = {
  data: UserUpdateInput;
  id: Scalars['ID'];
};

export type NameType = 'ALIAS' | 'FORMER' | 'MAIDEN' | 'PRESENT';

export type Organization = IOrganization & {
  __typename?: 'Organization';
  contracts: Array<Contract>;
  createdAt: Scalars['DateTime'];
  currentContract: Maybe<Contract>;
  id: Scalars['ID'];
  name: Scalars['String'];
  recentVolume: Scalars['Int'];
  users: UsersResponse;
};

export type OrganizationAdmin = {
  email: Scalars['EmailAddress'];
  name?: InputMaybe<Scalars['String']>;
};

export type OrganizationConnection = IOrganization & {
  __typename?: 'OrganizationConnection';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OrganizationCreateInput = {
  admin: OrganizationAdmin;
  contract?: InputMaybe<ContractInput>;
  name: Scalars['String'];
  sendNotification?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationFilter = {
  fullText?: InputMaybe<Scalars['String']>;
};

export type OrganizationSort = {
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  recentVolume?: InputMaybe<SortOrder>;
  usersCount?: InputMaybe<SortOrder>;
};

export type OrganizationUpdateInput = {
  contracts?: InputMaybe<Array<ContractInput>>;
  name?: InputMaybe<Scalars['String']>;
};

export type OrganizationsResponse = {
  __typename?: 'OrganizationsResponse';
  count: Scalars['Int'];
  items: Array<Organization>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Maybe<Scalars['ID']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Maybe<Scalars['ID']>;
};

export type Person = {
  __typename?: 'Person';
  addresses: Array<PersonAddress>;
  age: Maybe<Scalars['String']>;
  dateOfBirth: Maybe<Scalars['Date']>;
  dateOfBirthRange: Maybe<Array<Scalars['Date']>>;
  educations: Array<PersonEducation>;
  emails: Array<PersonEmail>;
  gender: Maybe<Gender>;
  images: Array<Scalars['String']>;
  jobs: Array<PersonJob>;
  languages: Array<Scalars['String']>;
  name: Scalars['String'];
  names: Array<PersonName>;
  originCountries: Array<Scalars['String']>;
  phones: Array<PersonPhone>;
  relationships: Array<PersonRelationship>;
  tags: Array<PersonTag>;
  urls: Array<PersonUrl>;
  userIds: Array<PersonUserId>;
  vehicles: Array<PersonVehicle>;
};

export type PersonAddress = {
  __typename?: 'PersonAddress';
  metadata: FieldMetadata;
  type: Maybe<AddressType>;
  value: Scalars['String'];
};

export type PersonEducation = {
  __typename?: 'PersonEducation';
  degree: Maybe<Scalars['String']>;
  end: Maybe<Scalars['Date']>;
  metadata: FieldMetadata;
  school: Scalars['String'];
  start: Maybe<Scalars['Date']>;
  value: Scalars['String'];
};

export type PersonEmail = {
  __typename?: 'PersonEmail';
  metadata: EmailMetadata;
  type: Maybe<EmailType>;
  value: Scalars['EmailAddress'];
};

export type PersonJob = {
  __typename?: 'PersonJob';
  end: Maybe<Scalars['Date']>;
  industry: Maybe<Scalars['String']>;
  metadata: FieldMetadata;
  organization: Maybe<Scalars['String']>;
  start: Maybe<Scalars['Date']>;
  title: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type PersonName = {
  __typename?: 'PersonName';
  first: Scalars['String'];
  last: Scalars['String'];
  metadata: FieldMetadata;
  middle: Maybe<Scalars['String']>;
  type: Maybe<NameType>;
  value: Scalars['String'];
};

export type PersonPhone = {
  __typename?: 'PersonPhone';
  metadata: PhoneMetadata;
  type: Maybe<PhoneType>;
  value: Scalars['String'];
};

export type PersonRelationship = {
  __typename?: 'PersonRelationship';
  names: Array<PersonName>;
  subtype: Maybe<Scalars['String']>;
  type: RelationshipType;
};

export type PersonTag = {
  __typename?: 'PersonTag';
  classification: Scalars['String'];
  metadata: FieldMetadata;
  source: Scalars['String'];
  value: Scalars['String'];
};

export type PersonUrl = {
  __typename?: 'PersonUrl';
  category: UrlCategory;
  domain: Scalars['String'];
  metadata: UrlMetadata;
  name: Maybe<Scalars['String']>;
  phrase: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type PersonUserId = {
  __typename?: 'PersonUserId';
  metadata: FieldMetadata;
  value: Scalars['String'];
};

export type PersonVehicle = {
  __typename?: 'PersonVehicle';
  manufacturer: Scalars['String'];
  metadata: FieldMetadata;
  model: Scalars['String'];
  type: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  year: Scalars['String'];
};

export type PhoneMetadata = Metadata & {
  __typename?: 'PhoneMetadata';
  current: Maybe<Scalars['Boolean']>;
  doNotCall: Maybe<Scalars['Boolean']>;
  inferred: Maybe<Scalars['Boolean']>;
  lastSeen: Maybe<Scalars['Date']>;
  validSince: Maybe<Scalars['Date']>;
  voip: Maybe<Scalars['Boolean']>;
};

export type PhoneType = 'HOME_FAX' | 'HOME_PHONE' | 'MOBILE' | 'PAGER' | 'WORK_FAX' | 'WORK_PHONE';

export type Query = {
  __typename?: 'Query';
  advancedSearch: SearchResult;
  application: Application;
  coordinates: Maybe<Coordinates>;
  location: Maybe<Location>;
  me: Maybe<LoggedInUser>;
  organization: Maybe<Organization>;
  organizationStatistics: StatisticsResponse;
  organizationsList: OrganizationsResponse;
  person: Maybe<Person>;
  search: SearchResult;
  searchActivities: SearchActivitiesResponse;
  user: Maybe<User>;
  userStatistics: StatisticsResponse;
  usersList: UsersResponse;
};

export type QueryadvancedSearchArgs = {
  age?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type QuerycoordinatesArgs = {
  address: Scalars['String'];
};

export type QuerylocationArgs = {
  lat: Scalars['Latitude'];
  lng: Scalars['Longitude'];
};

export type QueryorganizationArgs = {
  id: Scalars['ID'];
};

export type QueryorganizationStatisticsArgs = {
  organization?: InputMaybe<Scalars['ID']>;
  year: Scalars['Int'];
};

export type QueryorganizationsListArgs = {
  filter?: InputMaybe<OrganizationFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<OrganizationSort>;
};

export type QuerypersonArgs = {
  id: Scalars['ID'];
};

export type QuerysearchArgs = {
  location?: InputMaybe<Scalars['String']>;
  query: Scalars['String'];
};

export type QuerysearchActivitiesArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<SearchActivityFilter>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryuserArgs = {
  id: Scalars['ID'];
};

export type QueryuserStatisticsArgs = {
  user?: InputMaybe<Scalars['ID']>;
  year: Scalars['Int'];
};

export type QueryusersListArgs = {
  filter?: InputMaybe<UserFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<UserSort>;
};

export type RelationshipType = 'FAMILY' | 'FRIEND' | 'OTHER' | 'WORK';

export type Result = 'ERROR' | 'MATCH' | 'NO_MATCH';

export type SearchActivitiesResponse = {
  __typename?: 'SearchActivitiesResponse';
  count: Scalars['Int'];
  items: Array<SearchActivity>;
  pageInfo: PageInfo;
};

export type SearchActivity = {
  __typename?: 'SearchActivity';
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  organization: Scalars['String'];
  result: Result;
  type: Type;
  user: Scalars['String'];
};

export type SearchActivityFilter = {
  from?: InputMaybe<Scalars['Date']>;
  organization?: InputMaybe<Scalars['ID']>;
  to?: InputMaybe<Scalars['Date']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type SearchItem = {
  __typename?: 'SearchItem';
  address: Maybe<Scalars['String']>;
  age: Maybe<Scalars['String']>;
  dateOfBirth: Maybe<Scalars['Date']>;
  dateOfBirthRange: Maybe<Array<Scalars['Date']>>;
  gender: Maybe<Gender>;
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  name: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  pointer: Scalars['String'];
  probability: Scalars['Float'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  count: Scalars['Int'];
  items: Array<SearchItem>;
  warnings: Array<Scalars['String']>;
};

export type SortOrder = 'ASC' | 'DESC';

export type Statistics = {
  __typename?: 'Statistics';
  error: Scalars['Int'];
  match: Scalars['Int'];
  noMatch: Scalars['Int'];
  total: Scalars['Int'];
};

export type StatisticsResponse = {
  __typename?: 'StatisticsResponse';
  monthly: Array<MonthlyStatistics>;
  yearly: YearlyStatistics;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean'];
};

export type Type = 'PROFILE' | 'SEARCH';

export type UrlCategory =
  | 'BACKGROUND_REPORTS'
  | 'CONTACT_DETAILS'
  | 'EMAIL_ADDRESS'
  | 'MEDIA'
  | 'PERSONAL_PROFILES'
  | 'PROFESSIONAL_AND_BUSINESS'
  | 'PUBLICATIONS'
  | 'PUBLIC_RECORDS'
  | 'SCHOOL_AND_CLASSMATES'
  | 'WEBPAGES';

export type UrlMetadata = Metadata & {
  __typename?: 'UrlMetadata';
  current: Maybe<Scalars['Boolean']>;
  inferred: Maybe<Scalars['Boolean']>;
  lastSeen: Maybe<Scalars['Date']>;
  sponsored: Maybe<Scalars['Boolean']>;
  validSince: Maybe<Scalars['Date']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['EmailAddress'];
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  organization: OrganizationConnection;
  recentActivity: Maybe<Scalars['DateTime']>;
  recentVolume: Scalars['Int'];
  role: UserRole;
  status: UserStatus;
};

export type UserCreateInput = {
  email: Scalars['EmailAddress'];
  name?: InputMaybe<Scalars['String']>;
  organization: Scalars['ID'];
  role: UserRole;
  sendNotification?: InputMaybe<Scalars['Boolean']>;
};

export type UserFilter = {
  fullText?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['ID']>;
};

export type UserRole = 'ADMIN' | 'ORGANIZATION_ADMIN' | 'USER';

export type UserSort = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  recentActivity?: InputMaybe<SortOrder>;
  recentVolume?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type UserStatus = 'ACTIVE' | 'PENDING';

export type UserUpdateInput = {
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRole>;
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  count: Scalars['Int'];
  items: Array<User>;
};

export type YearlyStatistics = {
  __typename?: 'YearlyStatistics';
  statistics: Statistics;
  year: Scalars['Int'];
};

export type fetchApplicationDataQueryVariables = Exact<{ [key: string]: never }>;

export type fetchApplicationDataQuery = {
  __typename?: 'Query';
  application: { __typename?: 'Application'; warningMessage: string | null };
};

export type fetchMeQueryVariables = Exact<{ [key: string]: never }>;

export type fetchMeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'LoggedInUser';
    id: string;
    name: string | null;
    email: string;
    createdAt: string;
    role: UserRole;
    organization: {
      __typename?: 'OrganizationConnection';
      id: string;
      name: string;
      createdAt: string;
    };
  } | null;
};

export type createUserMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  email: Scalars['EmailAddress'];
  role: UserRole;
  organization: Scalars['ID'];
  sendNotification: Scalars['Boolean'];
}>;

export type createUserMutation = {
  __typename?: 'Mutation';
  userCreate: { __typename?: 'User'; id: string };
};

export type deleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type deleteUserMutation = {
  __typename?: 'Mutation';
  userDelete: { __typename?: 'SuccessResponse'; success: boolean };
};

export type fetchUsersQueryVariables = Exact<{
  fullText?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['ID']>;
  sort?: InputMaybe<UserSort>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type fetchUsersQuery = {
  __typename?: 'Query';
  usersList: {
    __typename?: 'UsersResponse';
    count: number;
    items: Array<{
      __typename?: 'User';
      id: string;
      createdAt: string;
      name: string | null;
      email: string;
      status: UserStatus;
      role: UserRole;
      recentActivity: string | null;
      recentVolume: number;
    }>;
  };
};

export type resendNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type resendNotificationMutation = {
  __typename?: 'Mutation';
  userResendNotification: { __typename?: 'SuccessResponse'; success: boolean };
};

export type updateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UserUpdateInput;
}>;

export type updateUserMutation = {
  __typename?: 'Mutation';
  userUpdate: { __typename?: 'User'; id: string };
};

export type fetchMarkerQueryVariables = Exact<{
  address: Scalars['String'];
}>;

export type fetchMarkerQuery = {
  __typename?: 'Query';
  coordinates: { __typename?: 'Coordinates'; lat: number; lng: number } | null;
};

type Metadata_EmailMetadata_Fragment = {
  __typename?: 'EmailMetadata';
  current: boolean | null;
  inferred: boolean | null;
  validSince: string | null;
  lastSeen: string | null;
};

type Metadata_FieldMetadata_Fragment = {
  __typename?: 'FieldMetadata';
  current: boolean | null;
  inferred: boolean | null;
  validSince: string | null;
  lastSeen: string | null;
};

type Metadata_PhoneMetadata_Fragment = {
  __typename?: 'PhoneMetadata';
  current: boolean | null;
  inferred: boolean | null;
  validSince: string | null;
  lastSeen: string | null;
};

type Metadata_UrlMetadata_Fragment = {
  __typename?: 'UrlMetadata';
  current: boolean | null;
  inferred: boolean | null;
  validSince: string | null;
  lastSeen: string | null;
};

export type MetadataFragment =
  | Metadata_EmailMetadata_Fragment
  | Metadata_FieldMetadata_Fragment
  | Metadata_PhoneMetadata_Fragment
  | Metadata_UrlMetadata_Fragment;

export type NameFragment = {
  __typename?: 'PersonName';
  value: string;
  first: string;
  last: string;
  middle: string | null;
  type: NameType | null;
  metadata: {
    __typename?: 'FieldMetadata';
    current: boolean | null;
    inferred: boolean | null;
    validSince: string | null;
    lastSeen: string | null;
  };
};

export type fetchPersonQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type fetchPersonQuery = {
  __typename?: 'Query';
  person: {
    __typename?: 'Person';
    name: string;
    age: string | null;
    dateOfBirth: string | null;
    dateOfBirthRange: Array<string> | null;
    gender: Gender | null;
    languages: Array<string>;
    originCountries: Array<string>;
    images: Array<string>;
    names: Array<{
      __typename?: 'PersonName';
      value: string;
      first: string;
      last: string;
      middle: string | null;
      type: NameType | null;
      metadata: {
        __typename?: 'FieldMetadata';
        current: boolean | null;
        inferred: boolean | null;
        validSince: string | null;
        lastSeen: string | null;
      };
    }>;
    emails: Array<{
      __typename?: 'PersonEmail';
      value: string;
      type: EmailType | null;
      metadata: {
        __typename?: 'EmailMetadata';
        disposable: boolean | null;
        current: boolean | null;
        inferred: boolean | null;
        validSince: string | null;
        lastSeen: string | null;
      };
    }>;
    phones: Array<{
      __typename?: 'PersonPhone';
      value: string;
      type: PhoneType | null;
      metadata: {
        __typename?: 'PhoneMetadata';
        doNotCall: boolean | null;
        voip: boolean | null;
        current: boolean | null;
        inferred: boolean | null;
        validSince: string | null;
        lastSeen: string | null;
      };
    }>;
    addresses: Array<{
      __typename?: 'PersonAddress';
      value: string;
      type: AddressType | null;
      metadata: {
        __typename?: 'FieldMetadata';
        current: boolean | null;
        inferred: boolean | null;
        validSince: string | null;
        lastSeen: string | null;
      };
    }>;
    educations: Array<{
      __typename?: 'PersonEducation';
      value: string;
      degree: string | null;
      school: string;
      start: string | null;
      end: string | null;
      metadata: {
        __typename?: 'FieldMetadata';
        current: boolean | null;
        inferred: boolean | null;
        validSince: string | null;
        lastSeen: string | null;
      };
    }>;
    jobs: Array<{
      __typename?: 'PersonJob';
      value: string;
      title: string | null;
      industry: string | null;
      organization: string | null;
      start: string | null;
      end: string | null;
      metadata: {
        __typename?: 'FieldMetadata';
        current: boolean | null;
        inferred: boolean | null;
        validSince: string | null;
        lastSeen: string | null;
      };
    }>;
    urls: Array<{
      __typename?: 'PersonUrl';
      url: string;
      domain: string;
      name: string | null;
      title: string | null;
      phrase: string | null;
      category: UrlCategory;
      metadata: {
        __typename?: 'UrlMetadata';
        sponsored: boolean | null;
        current: boolean | null;
        inferred: boolean | null;
        validSince: string | null;
        lastSeen: string | null;
      };
    }>;
    userIds: Array<{
      __typename?: 'PersonUserId';
      value: string;
      metadata: {
        __typename?: 'FieldMetadata';
        current: boolean | null;
        inferred: boolean | null;
        validSince: string | null;
        lastSeen: string | null;
      };
    }>;
    relationships: Array<{
      __typename?: 'PersonRelationship';
      type: RelationshipType;
      subtype: string | null;
      names: Array<{
        __typename?: 'PersonName';
        value: string;
        first: string;
        last: string;
        middle: string | null;
        type: NameType | null;
        metadata: {
          __typename?: 'FieldMetadata';
          current: boolean | null;
          inferred: boolean | null;
          validSince: string | null;
          lastSeen: string | null;
        };
      }>;
    }>;
    tags: Array<{
      __typename?: 'PersonTag';
      source: string;
      classification: string;
      value: string;
      metadata: {
        __typename?: 'FieldMetadata';
        current: boolean | null;
        inferred: boolean | null;
        validSince: string | null;
        lastSeen: string | null;
      };
    }>;
    vehicles: Array<{
      __typename?: 'PersonVehicle';
      vin: string;
      year: string;
      manufacturer: string;
      model: string;
      type: string | null;
      metadata: {
        __typename?: 'FieldMetadata';
        current: boolean | null;
        inferred: boolean | null;
        validSince: string | null;
        lastSeen: string | null;
      };
    }>;
  } | null;
};

export type fetchContractsQueryVariables = Exact<{
  organization: Scalars['ID'];
}>;

export type fetchContractsQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    name: string;
    contracts: Array<{
      __typename?: 'Contract';
      id: string;
      start: string;
      end: string;
      count: number;
      status: ContractStatus;
      usage: number;
    }>;
  } | null;
};

export type saveContractsMutationVariables = Exact<{
  organization: Scalars['ID'];
  contracts: Array<ContractInput> | ContractInput;
}>;

export type saveContractsMutation = {
  __typename?: 'Mutation';
  organizationUpdate: {
    __typename?: 'Organization';
    contracts: Array<{
      __typename?: 'Contract';
      id: string;
      start: string;
      end: string;
      count: number;
      status: ContractStatus;
      usage: number;
    }>;
  };
};

export type fetchOrganizationNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type fetchOrganizationNameQuery = {
  __typename?: 'Query';
  organization: { __typename?: 'Organization'; id: string; name: string } | null;
};

export type createOrganizationMutationVariables = Exact<{
  name: Scalars['String'];
  orgAdminName?: InputMaybe<Scalars['String']>;
  orgAdminEmail: Scalars['EmailAddress'];
  sendNotification: Scalars['Boolean'];
  contract?: InputMaybe<ContractInput>;
}>;

export type createOrganizationMutation = {
  __typename?: 'Mutation';
  organizationCreate: { __typename?: 'Organization'; id: string };
};

export type deleteOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type deleteOrganizationMutation = {
  __typename?: 'Mutation';
  organizationDelete: { __typename?: 'SuccessResponse'; success: boolean };
};

export type fetchOrganizationsQueryVariables = Exact<{
  fullText?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<OrganizationSort>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type fetchOrganizationsQuery = {
  __typename?: 'Query';
  organizationsList: {
    __typename?: 'OrganizationsResponse';
    count: number;
    items: Array<{
      __typename?: 'Organization';
      id: string;
      createdAt: string;
      name: string;
      recentVolume: number;
      currentContract: {
        __typename?: 'Contract';
        id: string;
        start: string;
        end: string;
        count: number;
        usage: number;
      } | null;
      users: { __typename?: 'UsersResponse'; count: number };
    }>;
  };
};

export type updateOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;

export type updateOrganizationMutation = {
  __typename?: 'Mutation';
  organizationUpdate: { __typename?: 'Organization'; id: string };
};

export type fetchRecentActivitiesQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type fetchRecentActivitiesQuery = {
  __typename?: 'Query';
  searchActivities: {
    __typename?: 'SearchActivitiesResponse';
    items: Array<{
      __typename?: 'SearchActivity';
      id: string;
      date: string;
      result: Result;
      type: Type;
    }>;
  };
};

export type StatisticsFragment = {
  __typename?: 'Statistics';
  total: number;
  match: number;
  noMatch: number;
  error: number;
};

export type fetchMyStatisticsQueryVariables = Exact<{
  year: Scalars['Int'];
}>;

export type fetchMyStatisticsQuery = {
  __typename?: 'Query';
  userStatistics: {
    __typename?: 'StatisticsResponse';
    yearly: {
      __typename?: 'YearlyStatistics';
      year: number;
      statistics: {
        __typename?: 'Statistics';
        total: number;
        match: number;
        noMatch: number;
        error: number;
      };
    };
    monthly: Array<{
      __typename?: 'MonthlyStatistics';
      month: number;
      statistics: {
        __typename?: 'Statistics';
        total: number;
        match: number;
        noMatch: number;
        error: number;
      };
    }>;
  };
};

export type updateNameMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;

export type updateNameMutation = {
  __typename?: 'Mutation';
  userUpdate: { __typename?: 'User'; id: string; name: string | null };
};

export type fetchLocationQueryVariables = Exact<{
  lat: Scalars['Latitude'];
  lng: Scalars['Longitude'];
}>;

export type fetchLocationQuery = {
  __typename?: 'Query';
  location: { __typename?: 'Location'; city: string; country: string } | null;
};

export type SearchResultFragment = {
  __typename?: 'SearchResult';
  count: number;
  warnings: Array<string>;
  items: Array<{
    __typename?: 'SearchItem';
    id: string;
    pointer: string;
    probability: number;
    name: string;
    gender: Gender | null;
    images: Array<string>;
    age: string | null;
    dateOfBirth: string | null;
    dateOfBirthRange: Array<string> | null;
    address: string | null;
    phone: string | null;
  }>;
};

export type basicSearchQueryVariables = Exact<{
  query: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
}>;

export type basicSearchQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchResult';
    count: number;
    warnings: Array<string>;
    items: Array<{
      __typename?: 'SearchItem';
      id: string;
      pointer: string;
      probability: number;
      name: string;
      gender: Gender | null;
      images: Array<string>;
      age: string | null;
      dateOfBirth: string | null;
      dateOfBirthRange: Array<string> | null;
      address: string | null;
      phone: string | null;
    }>;
  };
};

export type advancedSearchQueryVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  age?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
}>;

export type advancedSearchQuery = {
  __typename?: 'Query';
  advancedSearch: {
    __typename?: 'SearchResult';
    count: number;
    warnings: Array<string>;
    items: Array<{
      __typename?: 'SearchItem';
      id: string;
      pointer: string;
      probability: number;
      name: string;
      gender: Gender | null;
      images: Array<string>;
      age: string | null;
      dateOfBirth: string | null;
      dateOfBirthRange: Array<string> | null;
      address: string | null;
      phone: string | null;
    }>;
  };
};

export type fetchOrganizationsOptionsForHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type fetchOrganizationsOptionsForHistoryQuery = {
  __typename?: 'Query';
  organizationsList: {
    __typename?: 'OrganizationsResponse';
    items: Array<{ __typename?: 'Organization'; id: string; name: string }>;
  };
};

export type fetchSearchHistoryQueryVariables = Exact<{
  user?: InputMaybe<Scalars['ID']>;
  organization?: InputMaybe<Scalars['ID']>;
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
  limit: Scalars['Int'];
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
}>;

export type fetchSearchHistoryQuery = {
  __typename?: 'Query';
  searchActivities: {
    __typename?: 'SearchActivitiesResponse';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string | null;
      endCursor: string | null;
    };
    items: Array<{
      __typename?: 'SearchActivity';
      id: string;
      user: string;
      organization: string;
      date: string;
      result: Result;
      type: Type;
    }>;
  };
};

export type fetchUsersOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type fetchUsersOptionsQuery = {
  __typename?: 'Query';
  usersList: {
    __typename?: 'UsersResponse';
    items: Array<{ __typename?: 'User'; id: string; email: string }>;
  };
};

export type checkOrgExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type checkOrgExistsQuery = {
  __typename?: 'Query';
  organization: { __typename?: 'Organization'; id: string } | null;
};

export type checkUserExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type checkUserExistsQuery = {
  __typename?: 'Query';
  user: { __typename?: 'User'; id: string } | null;
};

export type fetchOrganizationContractsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type fetchOrganizationContractsQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    contracts: Array<{
      __typename?: 'Contract';
      id: string;
      start: string;
      end: string;
      status: ContractStatus;
      count: number;
      usage: number;
    }>;
  } | null;
};

export type fetchUserStatisticsQueryVariables = Exact<{
  user: Scalars['ID'];
  year: Scalars['Int'];
}>;

export type fetchUserStatisticsQuery = {
  __typename?: 'Query';
  userStatistics: {
    __typename?: 'StatisticsResponse';
    yearly: {
      __typename?: 'YearlyStatistics';
      year: number;
      statistics: {
        __typename?: 'Statistics';
        total: number;
        match: number;
        noMatch: number;
        error: number;
      };
    };
    monthly: Array<{
      __typename?: 'MonthlyStatistics';
      month: number;
      statistics: {
        __typename?: 'Statistics';
        total: number;
        match: number;
        noMatch: number;
        error: number;
      };
    }>;
  };
};

export type fetchUsersForStatsQueryVariables = Exact<{ [key: string]: never }>;

export type fetchUsersForStatsQuery = {
  __typename?: 'Query';
  usersList: {
    __typename?: 'UsersResponse';
    items: Array<{
      __typename?: 'User';
      id: string;
      name: string | null;
      email: string;
      createdAt: string;
      organization: { __typename?: 'OrganizationConnection'; id: string; name: string };
    }>;
  };
};

export type fetchOrganizationStatisticsQueryVariables = Exact<{
  organization?: InputMaybe<Scalars['ID']>;
  year: Scalars['Int'];
}>;

export type fetchOrganizationStatisticsQuery = {
  __typename?: 'Query';
  organizationStatistics: {
    __typename?: 'StatisticsResponse';
    yearly: {
      __typename?: 'YearlyStatistics';
      year: number;
      statistics: {
        __typename?: 'Statistics';
        total: number;
        match: number;
        noMatch: number;
        error: number;
      };
    };
    monthly: Array<{
      __typename?: 'MonthlyStatistics';
      month: number;
      statistics: {
        __typename?: 'Statistics';
        total: number;
        match: number;
        noMatch: number;
        error: number;
      };
    }>;
  };
};

export type fetchOrganizationsOptionsForStatsQueryVariables = Exact<{ [key: string]: never }>;

export type fetchOrganizationsOptionsForStatsQuery = {
  __typename?: 'Query';
  organizationsList: {
    __typename?: 'OrganizationsResponse';
    items: Array<{ __typename?: 'Organization'; id: string; name: string; createdAt: string }>;
  };
};

export const MetadataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Metadata' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Metadata' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'current' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validSince' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastSeen' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MetadataFragment, unknown>;
export const NameFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Name' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PersonName' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Metadata' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Metadata' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Metadata' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'current' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validSince' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastSeen' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NameFragment, unknown>;
export const StatisticsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Statistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Statistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'match' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noMatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'error' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatisticsFragment, unknown>;
export const SearchResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pointer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'probability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirthRange' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchResultFragment, unknown>;
export const fetchApplicationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchApplicationData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'application' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'warningMessage' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchApplicationDataQuery, fetchApplicationDataQueryVariables>;
export const fetchMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchMe' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchMeQuery, fetchMeQueryVariables>;
export const createUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmailAddress' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserRole' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendNotification' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sendNotification' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sendNotification' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<createUserMutation, createUserMutationVariables>;
export const deleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<deleteUserMutation, deleteUserMutationVariables>;
export const fetchUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fullText' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserSort' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fullText' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fullText' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recentActivity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recentVolume' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchUsersQuery, fetchUsersQueryVariables>;
export const resendNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resendNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userResendNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<resendNotificationMutation, resendNotificationMutationVariables>;
export const updateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<updateUserMutation, updateUserMutationVariables>;
export const fetchMarkerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchMarker' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lng' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchMarkerQuery, fetchMarkerQueryVariables>;
export const fetchPersonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchPerson' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirthRange' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'languages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originCountries' } },
                { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'names' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Metadata' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disposable' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phones' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Metadata' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'doNotCall' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'voip' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Metadata' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'educations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'degree' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'school' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Metadata' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Metadata' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'urls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phrase' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Metadata' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sponsored' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userIds' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Metadata' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'relationships' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'names' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Metadata' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'manufacturer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Metadata' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Metadata' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Metadata' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'current' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validSince' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastSeen' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Name' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PersonName' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Metadata' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchPersonQuery, fetchPersonQueryVariables>;
export const fetchContractsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchContracts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contracts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'usage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchContractsQuery, fetchContractsQueryVariables>;
export const saveContractsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveContracts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contracts' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContractInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contracts' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contracts' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contracts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'usage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<saveContractsMutation, saveContractsMutationVariables>;
export const fetchOrganizationNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchOrganizationName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchOrganizationNameQuery, fetchOrganizationNameQueryVariables>;
export const createOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgAdminName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgAdminEmail' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmailAddress' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendNotification' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contract' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContractInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'admin' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'name' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgAdminName' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'email' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgAdminEmail' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contract' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contract' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sendNotification' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sendNotification' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<createOrganizationMutation, createOrganizationMutationVariables>;
export const deleteOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<deleteOrganizationMutation, deleteOrganizationMutationVariables>;
export const fetchOrganizationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchOrganizations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fullText' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationSort' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fullText' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fullText' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recentVolume' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentContract' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usage' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchOrganizationsQuery, fetchOrganizationsQueryVariables>;
export const updateOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<updateOrganizationMutation, updateOrganizationMutationVariables>;
export const fetchRecentActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchRecentActivities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '10' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchRecentActivitiesQuery, fetchRecentActivitiesQueryVariables>;
export const fetchMyStatisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchMyStatistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userStatistics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'yearly' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Statistics' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthly' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Statistics' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Statistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Statistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'match' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noMatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'error' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchMyStatisticsQuery, fetchMyStatisticsQueryVariables>;
export const updateNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<updateNameMutation, updateNameMutationVariables>;
export const fetchLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lat' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Latitude' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lng' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Longitude' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lat' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lat' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lng' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lng' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchLocationQuery, fetchLocationQueryVariables>;
export const basicSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'basicSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'location' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchResult' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pointer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'probability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirthRange' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<basicSearchQuery, basicSearchQueryVariables>;
export const advancedSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'advancedSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'middleName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'age' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'street' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zipCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'industry' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'advancedSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'middleName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'middleName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'age' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'age' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'username' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'url' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'street' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'street' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zipCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'zipCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organization' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industry' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'industry' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchResult' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pointer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'probability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirthRange' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<advancedSearchQuery, advancedSearchQueryVariables>;
export const fetchOrganizationsOptionsForHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchOrganizationsOptionsForHistory' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationsList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  fetchOrganizationsOptionsForHistoryQuery,
  fetchOrganizationsOptionsForHistoryQueryVariables
>;
export const fetchSearchHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSearchHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'from' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'to' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'user' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchSearchHistoryQuery, fetchSearchHistoryQueryVariables>;
export const fetchUsersOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchUsersOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchUsersOptionsQuery, fetchUsersOptionsQueryVariables>;
export const checkOrgExistsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkOrgExists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<checkOrgExistsQuery, checkOrgExistsQueryVariables>;
export const checkUserExistsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkUserExists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<checkUserExistsQuery, checkUserExistsQueryVariables>;
export const fetchOrganizationContractsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchOrganizationContracts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contracts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'usage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  fetchOrganizationContractsQuery,
  fetchOrganizationContractsQueryVariables
>;
export const fetchUserStatisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchUserStatistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userStatistics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'yearly' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Statistics' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthly' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Statistics' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Statistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Statistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'match' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noMatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'error' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchUserStatisticsQuery, fetchUserStatisticsQueryVariables>;
export const fetchUsersForStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchUsersForStats' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<fetchUsersForStatsQuery, fetchUsersForStatsQueryVariables>;
export const fetchOrganizationStatisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchOrganizationStatistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationStatistics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organization' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'yearly' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Statistics' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthly' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Statistics' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Statistics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Statistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'match' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noMatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'error' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  fetchOrganizationStatisticsQuery,
  fetchOrganizationStatisticsQueryVariables
>;
export const fetchOrganizationsOptionsForStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchOrganizationsOptionsForStats' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationsList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  fetchOrganizationsOptionsForStatsQuery,
  fetchOrganizationsOptionsForStatsQueryVariables
>;

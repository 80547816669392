import React, { useState, useEffect, useCallback, useMemo } from 'react';
import useOffsetDataFetch from '@hooks/useOffsetDataFetch';
import useToast from '@hooks/useToast';
import { User as LoggedInUser, SortOption } from '@types';
import UsersTable from './UsersTable';
import EditUser, { UserData } from './EditUser';
import CreateUser from './CreateUser';
import DeleteUser from './DeleteUser';
import { SortField, User, CreateUserFormData, FetchUserFilter, EditUserData } from './types';
import fetchUsers from './helpers/fetchUsers';
import resendNotification from './helpers/resendNotification';
import useUserActions from './useUserActions';

interface UsersListProps {
  loading: boolean;
  organization: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  loggedInUser: Nullable<LoggedInUser>;
}

const initialSort: SortOption<SortField> = {
  field: 'name',
  order: 'ASC',
};

const UsersList = ({ loading, organization, title, description, loggedInUser }: UsersListProps) => {
  const toast = useToast();
  const [userToEdit, setUserToEdit] = useState<Nullable<User>>(null);
  const [userToDelete, setUserToDelete] = useState<Nullable<User>>(null);

  // actions
  const {
    isModalOpen,
    openModal,
    closeModal,
    createUser,
    updateUser,
    deleteUser,
    isLoading,
    getError,
  } = useUserActions();

  // table data
  const {
    loading: fetching,
    data,
    error,
    count,
    page,
    searchByText,
    sortBy,
    paginate,
    refetch,
    filterBy,
  } = useOffsetDataFetch<User, FetchUserFilter>(fetchUsers, { sort: initialSort });

  const userEditData = useMemo((): UserData => {
    if (userToEdit) {
      return {
        name: userToEdit.name || '',
        email: userToEdit.email,
        role: userToEdit.role,
      };
    }

    return {
      name: '',
      email: '',
      role: 'user',
    };
  }, [userToEdit]);

  // initial data fetch after loading
  useEffect(() => {
    if (!loading && organization) {
      filterBy({ organization });
    }
  }, [loading, organization]);

  const handleEditUser = useCallback((user: User) => {
    setUserToEdit(user);
    openModal('editUser');
  }, []);

  const handleDeleteUser = useCallback(
    (user: User) => {
      // prevent deleting yourself
      if (loggedInUser?.id === user.id) {
        toast.warning({ title: 'Delete user', subtitle: "Can't delete yourself" });

        return;
      }

      setUserToDelete(user);
      openModal('deleteUser');
    },
    [loggedInUser?.id]
  );

  const handleUpdateUser = useCallback(
    async (updateData: EditUserData) => {
      if (userToEdit) {
        const success = await updateUser(userToEdit.id, updateData);
        if (success) {
          setUserToEdit(null);
          refetch();
        }
      }
    },
    [userToEdit, refetch]
  );

  const handleConfirmDeleteUser = useCallback(async () => {
    if (userToDelete) {
      const success = await deleteUser(userToDelete.id);

      if (success) {
        setUserToDelete(null);
        refetch();
      }
    }
  }, [userToDelete, refetch]);

  const handleCreateUser = useCallback(
    async (userData: CreateUserFormData) => {
      const success = await createUser({ ...userData, organization });

      if (success) {
        refetch();
      }
    },
    [refetch]
  );

  const handleResendNotification = useCallback((id: string) => {
    resendNotification(id);
    toast.success({ title: 'User notification', subtitle: 'Notification was sent' });
  }, []);

  return (
    <React.Fragment>
      <UsersTable
        title={title || 'Users'}
        loading={loading}
        description={description}
        fetching={fetching}
        initialSort={initialSort}
        page={page}
        count={count}
        data={data}
        error={error}
        onSearchByText={searchByText}
        onSortBy={sortBy}
        onPaginate={paginate}
        onRefetch={refetch}
        onResendNotification={handleResendNotification}
        onAdd={() => openModal('createUser')}
        onEdit={handleEditUser}
        onRemove={handleDeleteUser}
        loggedInUserRole={loggedInUser?.role || 'user'}
      />
      {!loading && loggedInUser && (
        <React.Fragment>
          <CreateUser
            isOpen={isModalOpen('createUser')}
            loading={isLoading('createUser')}
            errorMessage={getError('createUser') || ''}
            loggedInUserRole={loggedInUser.role}
            onClose={closeModal}
            onSubmit={handleCreateUser}
          />
          <EditUser
            isOpen={isModalOpen('editUser')}
            loading={isLoading('editUser')}
            errorMessage={getError('editUser') || ''}
            loggedInUserRole={loggedInUser.role}
            user={userEditData}
            onClose={closeModal}
            onSubmit={handleUpdateUser}
          />
          <DeleteUser
            user={userToDelete?.name || userToDelete?.email || ''}
            isOpen={isModalOpen('deleteUser')}
            loading={isLoading('deleteUser')}
            errorMessage={getError('deleteUser') || ''}
            onClose={closeModal}
            onSubmit={handleConfirmDeleteUser}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default UsersList;

import React, { useState, useCallback, useEffect } from 'react';
import useOffsetDataFetch from '@hooks/useOffsetDataFetch';
import { SortOption } from '@types';
import useToast from '@hooks/useToast';
import { CreateOrganizationData, Organization } from '../types';
import CreateOrganization from '../CreateOrganization';
import EditOrganization from '../EditOrganization';
import DeleteOrganization from '../DeleteOrganization';
import OrganizationsTable from '../OrganizationsTable';
import fetchOrganizations from './helpers/fetchOrganizations';
import useOrganizationActions from './useOrganizationActions';

interface OrganizationsListProps {
  loading: boolean;
}

const initialSort: SortOption = {
  field: 'name',
  order: 'ASC',
};

const OrganizationsList = ({ loading }: OrganizationsListProps) => {
  const toast = useToast();
  const [organizationToEdit, setOrganizationToEdit] = useState<Nullable<Organization>>(null);
  const [organizationToDelete, setOrganizationToDelete] = useState<Nullable<Organization>>(null);

  // table data
  const {
    loading: fetching,
    data,
    error,
    count,
    searchByText,
    sortBy,
    paginate,
    refetch,
    fetch,
  } = useOffsetDataFetch<Organization>(fetchOrganizations, { sort: initialSort });

  // actions
  const {
    isLoading,
    isModalOpen,
    getError,
    openModal,
    closeModal,
    createOrganization,
    updateOrganization,
    deleteOrganization,
  } = useOrganizationActions();

  // initial data fetch after loading
  useEffect(() => {
    if (!loading) {
      fetch();
    }
  }, [loading]);

  const handleCreateOrganization = useCallback(
    async (createData: CreateOrganizationData) => {
      const success = await createOrganization(createData);

      if (success) {
        refetch();
      }
    },
    [refetch]
  );

  const handleEditOrganizationClick = useCallback((organization: Organization) => {
    setOrganizationToEdit(organization);
    openModal('editOrganization');
  }, []);

  const handleUpdateOrganization = useCallback(
    async (name: string) => {
      if (organizationToEdit) {
        const success = await updateOrganization(organizationToEdit.id, name);
        if (success) {
          setOrganizationToEdit(null);
          refetch();
        }
      }
    },
    [organizationToEdit, refetch]
  );

  const handleRemoveOrganizationClick = useCallback((organization: Organization) => {
    if (organization.numberOfUsers > 0) {
      toast.warning({
        title: 'Delete organization',
        subtitle: "Organization with users can't be deleted",
      });

      return;
    }

    setOrganizationToDelete(organization);
    openModal('deleteOrganization');
  }, []);

  const handleRemoveOrganization = useCallback(async () => {
    if (organizationToDelete) {
      const success = await deleteOrganization(organizationToDelete.id);
      if (success) {
        setOrganizationToDelete(null);
        refetch();
      }
    }
  }, [organizationToDelete, refetch]);

  return (
    <React.Fragment>
      <OrganizationsTable
        loading={loading}
        fetching={fetching}
        initialSort={initialSort}
        count={count}
        data={data}
        error={error}
        onAdd={() => openModal('createOrganization')}
        onEdit={handleEditOrganizationClick}
        onRemove={handleRemoveOrganizationClick}
        onSearchByText={searchByText}
        onSortBy={sortBy}
        onPaginate={paginate}
        onRefetch={refetch}
      />
      {!loading && (
        <React.Fragment>
          <CreateOrganization
            isOpen={isModalOpen('createOrganization')}
            loading={isLoading('createOrganization')}
            onClose={closeModal}
            onSubmit={handleCreateOrganization}
            errorMessage={getError('createOrganization') || ''}
          />
          <EditOrganization
            isOpen={isModalOpen('editOrganization')}
            loading={isLoading('editOrganization')}
            onClose={closeModal}
            onSubmit={handleUpdateOrganization}
            errorMessage={getError('editOrganization') || ''}
            name={organizationToEdit?.name || ''}
          />
          <DeleteOrganization
            isOpen={isModalOpen('deleteOrganization')}
            loading={isLoading('deleteOrganization')}
            onClose={closeModal}
            onSubmit={handleRemoveOrganization}
            errorMessage={getError('deleteOrganization') || ''}
            organization={organizationToDelete?.name || ''}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OrganizationsList;

import React from 'react';
import { PersonFieldMetadata } from '@mantis/types';
import useBreakpoint from '@hooks/useBreakpoint';
import baseStyles from '@styles/base.module.scss';
import spacing from '@styles/spacing.module.scss';
import DateRange from '@components/DateRange';
import MetadataInfo from '../../components/MetadataInfo';
import RecordTags from '../../components/RecordTags';
import styles from './job.module.scss';

interface JobProps {
  title?: string;
  industry?: string;
  organization?: string;
  start?: string;
  end?: string;
  metadata: PersonFieldMetadata;
}

const Job = ({ title, industry, organization, start, end, metadata }: JobProps) => {
  const { breakpointDown } = useBreakpoint();

  const renderTitle = () => {
    if (!title) {
      if (organization && industry) {
        return `${organization} (${industry})`;
      }

      return organization || industry;
    }

    let text = title;

    if (organization) {
      text += ` at ${organization}`;
    }
    if (industry) {
      text += ` (${industry})`;
    }

    return text;
  };

  if (breakpointDown('sm')) {
    return (
      <div className={baseStyles.dFlexColumn}>
        <div className={baseStyles.spaceBetween}>
          {renderTitle()}
          {(metadata.validSince || metadata.lastSeen) && (
            <div className={spacing.ml3}>
              <MetadataInfo metadata={metadata} />
            </div>
          )}
        </div>
        {start && (
          <span className={spacing.mt2}>
            <DateRange start={start} end={end} />
          </span>
        )}
        <RecordTags metadata={metadata} />
      </div>
    );
  }

  return (
    <div className={baseStyles.dFlex}>
      {renderTitle()}
      {start && (
        <span className={styles.dateWrapper}>
          <DateRange start={start} end={end} />
        </span>
      )}
      <RecordTags metadata={metadata} className={spacing.ml3} />
      {(metadata.validSince || metadata.lastSeen) && (
        <div className={spacing.ml3}>
          <MetadataInfo metadata={metadata} />
        </div>
      )}
    </div>
  );
};

export default Job;

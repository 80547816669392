import errorType from '@mantis/errors';
import client from '@services/client';
import { gql } from '@services/gql';
import parseError from '@utils/parseError';

const REMOVE_ORGANIZATION = gql(/* GraphQL */ `
  mutation deleteOrganization($id: ID!) {
    organizationDelete(id: $id) {
      success
    }
  }
`);

const deleteOrganization = async (id: string): Promise<boolean> => {
  try {
    const response = await client.mutate({
      mutation: REMOVE_ORGANIZATION,
      variables: {
        id,
      },
    });

    if (!response.data) {
      throw new Error('Something went wrong');
    }

    return response.data.organizationDelete.success;
  } catch (error: unknown) {
    const parsedError = parseError(error);

    if (parsedError.type && parsedError.type in errorType) {
      throw new Error(parsedError.message);
    }

    throw new Error('Something went wrong');
  }
};

export default deleteOrganization;

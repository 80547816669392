import React from 'react';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import pluralize from 'pluralize';
import {
  GroupResource,
  LogoFacebook,
  LogoGithub,
  LogoInstagram,
  LogoLinkedin,
  LogoPinterest,
  LogoTwitter,
  Taxi,
} from '@carbon/icons-react';
import { Person, PersonFieldMetadata, PersonTag, PersonVehicle } from '@mantis/types';
import spacing from '@styles/spacing.module.scss';
import Section from '../components/Section';
import Records from '../components/Records';
import MetadataInfo from '../components/MetadataInfo';
import Group from '../components/Group';
import styles from './others.module.scss';

interface GroupItem {
  title: string;
  tags: PersonTag[];
}

interface TagGroup {
  source: string;
  items: GroupItem[];
}

interface SkillGroup {
  metadata: PersonFieldMetadata;
  tags: string[];
}

interface OthersTabProps {
  tags: Person['tags'];
  vehicles: Person['vehicles'];
}

const Others = ({ tags, vehicles }: OthersTabProps) => {
  const groupTags = () => {
    const groups: TagGroup[] = [];

    tags.forEach((tag) => {
      const group = groups.find((item) => item.source === tag.source);

      if (group) {
        const foundItem = group.items.find((groupItem) => groupItem.title === tag.classification);

        if (foundItem) {
          foundItem.tags.push(tag);
        } else {
          group.items.push({ title: tag.classification, tags: [tag] });
        }
      } else {
        groups.push({ source: tag.source, items: [{ title: tag.classification, tags: [tag] }] });
      }
    });

    return groups;
  };

  // group skills by metadata
  const groupSkills = (skills: PersonTag[]): PersonTag[] => {
    const groups: SkillGroup[] = [];

    skills.forEach((skill) => {
      const foundGroup = groups.find(
        (group) =>
          group.metadata.lastSeen === skill.metadata.lastSeen &&
          group.metadata.validSince === skill.metadata.validSince
      );

      if (foundGroup) {
        foundGroup.tags.push(skill.value);
      } else {
        groups.push({ metadata: skill.metadata, tags: [skill.value] });
      }
    });

    return groups.map((group) => ({
      source: '',
      classification: 'Skill',
      value: group.tags.join(', '),
      metadata: group.metadata,
    }));
  };

  const formatTagGroupTitle = (item: GroupItem) => {
    if (pluralize.isPlural(item.title)) {
      return item.title;
    }

    return pluralize(item.title, item.tags.length);
  };

  const formatVehicleLabel = (vehicle: PersonVehicle): string => {
    const label = `${capitalize(vehicle.manufacturer)} ${capitalize(vehicle.model)} ${
      vehicle.year
    }`;

    if (vehicle.type) {
      return `${label.trim()} (${capitalize(vehicle.type)})`;
    }

    return label.trim();
  };

  const getIcon = (source: string) => {
    switch (source) {
      case 'Facebook':
        return <LogoFacebook size={20} />;
      case 'Instagram':
        return <LogoInstagram size={20} />;
      case 'LinkedIn':
        return <LogoLinkedin size={20} />;
      case 'Twitter':
        return <LogoTwitter size={20} />;
      case 'Pinterest':
        return <LogoPinterest size={20} />;
      case 'Github':
        return <LogoGithub size={20} />;
      default:
        return <GroupResource size={20} />;
    }
  };

  if (tags.length === 0 && vehicles.length === 0) {
    return <span>No data were found</span>;
  }

  return (
    <Records condensed>
      {vehicles.length > 0 && (
        <Section key="vehicle" title="Vehicles" icon={<Taxi size={20} />}>
          <Records>
            {vehicles.map((vehicle) => (
              <div key={vehicle.vin} className={styles.record}>
                <div>
                  {formatVehicleLabel(vehicle)}
                  {vehicle.vin && <div className={styles.vin}>VIN: {vehicle.vin}</div>}
                </div>
                {(vehicle.metadata.validSince || vehicle.metadata.lastSeen) && (
                  <div className={spacing.ml3}>
                    <MetadataInfo metadata={vehicle.metadata} />
                  </div>
                )}
              </div>
            ))}
          </Records>
        </Section>
      )}
      {groupTags().map((group) => (
        <Section key={group.source} icon={getIcon(group.source)} title={group.source}>
          {group.items.map((item, index) => (
            <div key={item.title} className={classNames({ [spacing.mt5]: index })}>
              <Group title={formatTagGroupTitle(item)} condensed>
                <Records condensed>
                  {(item.title === 'Skill' ? groupSkills(item.tags) : item.tags).map((tag) => (
                    <div key={tag.value} className={styles.record}>
                      {tag.value}
                      <div className={spacing.ml3}>
                        <MetadataInfo metadata={tag.metadata} />
                      </div>
                    </div>
                  ))}
                </Records>
              </Group>
            </div>
          ))}
        </Section>
      ))}
    </Records>
  );
};

export default Others;

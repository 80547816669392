import { useReducer, useEffect, useCallback } from 'react';
import { useIsFirstRender } from 'usehooks-ts';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import logger from '@services/logger';
import { SortOrder, SortOption, OffsetPaginationData, OffsetDataFetchResolver } from '@types';

interface OffsetDataFetchOptions<T> {
  pageSize?: number;
  sort?: SortOption;
  preFetch?: boolean; // fetch on initial render
  filter?: T;
}

interface OffsetDataFetchState<T, U> {
  count: number;
  data: T[];
  loading: boolean;
  error: boolean;
  fullText: string;
  sortBy: Nullable<string>;
  filterBy: Nullable<U>;
  order: SortOrder;
  pageSize: number;
  page: number;
}

interface FetchOptions<T extends Record<string, any> = {}> {
  fullText?: string;
  filter?: T;
  sort?: Partial<SortOption>;
  pageSize?: number;
  page?: number;
}

type ConfigurableInitialParams<T, U> = Pick<
  OffsetDataFetchState<T, U>,
  'filterBy' | 'sortBy' | 'order' | 'pageSize'
>;

interface ActionFetch<T extends Record<string, any> = {}> {
  type: 'FETCH';
  payload: FetchOptions<T>;
}

interface ActionReset<T, U> {
  type: 'RESET';
  payload: ConfigurableInitialParams<T, U>;
}

interface ActionSuccess<T> {
  type: 'SUCCESS';
  payload: {
    count: number;
    data: T[];
  };
}

interface ActionError {
  type: 'ERROR';
}

interface ActionFullText {
  type: 'FULL_TEXT';
  payload: {
    text: string;
  };
}

type OffsetDataFetchAction<T, U extends Record<string, any> = {}> =
  | ActionFetch<U>
  | ActionReset<T, U>
  | ActionSuccess<T>
  | ActionError
  | ActionFullText;

const reducer = <T, U extends Record<string, any> = {}>(
  state: OffsetDataFetchState<T, U>,
  action: OffsetDataFetchAction<T, U>
): OffsetDataFetchState<T, U> => {
  switch (action.type) {
    case 'FETCH': {
      const newState: Partial<OffsetDataFetchState<T, U>> = {
        loading: true,
        error: false,
        fullText: action.payload.fullText ?? state.fullText,
      };

      if (action.payload.filter) {
        newState.filterBy = action.payload.filter || state.filterBy;
      }
      if (action.payload.sort) {
        newState.sortBy = action.payload.sort.field || state.sortBy;
        newState.order = action.payload.sort.order || state.order;
      }
      if (action.payload.page) {
        newState.page = action.payload.page;
      }
      if (action.payload.pageSize) {
        newState.pageSize = action.payload.pageSize;
      }

      return { ...state, ...newState };
    }
    case 'RESET':
      return {
        ...state,
        ...action.payload,
        page: 1,
        fullText: '',
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        count: action.payload.count,
        data: action.payload.data,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
      };
    case 'FULL_TEXT':
      return {
        ...state,
        fullText: action.payload.text,
      };
    default:
      return state;
  }
};

const useOffsetDataFetch = <T, U extends Record<string, any> = {}>(
  resolver: OffsetDataFetchResolver<T, U>,
  options: OffsetDataFetchOptions<U> = {}
) => {
  const initialParams: ConfigurableInitialParams<T, U> = {
    filterBy: options.filter || null,
    sortBy: options.sort?.field || null,
    order: options.sort?.order || 'ASC',
    pageSize: options.pageSize || 10,
  };

  const isFirstRender = useIsFirstRender();
  const [state, dispatch] = useReducer<
    React.Reducer<OffsetDataFetchState<T, U>, OffsetDataFetchAction<T, U>>
  >(reducer, {
    count: 0,
    data: [],
    loading: false,
    error: false,
    page: 1,
    fullText: '',
    ...initialParams,
  });

  const fetchData = async (fetchOptions: FetchOptions<U> = {}) => {
    try {
      const { sort, filter, page, pageSize } = fetchOptions;
      const fullText = fetchOptions.fullText ?? state.fullText;
      const sortField = sort?.field || state.sortBy;
      const order = sort?.order || state.order;
      const limit = pageSize || state.pageSize;
      const skip = ((page || state.page) - 1) * limit;

      dispatch({ type: 'FETCH', payload: fetchOptions });
      const response = await resolver({
        skip,
        limit,
        fullText,
        filterBy: filter || state.filterBy,
        sortBy: sortField,
        order,
      });
      dispatch({ type: 'SUCCESS', payload: { count: response.count, data: response.data } });
    } catch (error) {
      dispatch({ type: 'ERROR' });
      if (error instanceof Error) {
        logger.error(error.message, { fetchOptions, state: omit(state, 'data') });
      }
    }
  };

  // 1st fetch
  useEffect(() => {
    if (isFirstRender && options.preFetch) {
      fetchData();
    }
  }, [options.preFetch]);

  const debounceFullTextSearch = useCallback(
    debounce((text) => {
      fetchData({ fullText: text, page: 1 });
    }, 300),
    [state.sortBy, state.filterBy, state.order, state.pageSize]
  );

  const searchByText = useCallback(
    (text: string) => {
      dispatch({ type: 'FULL_TEXT', payload: { text } });
      debounceFullTextSearch(text);
    },
    [state.sortBy, state.filterBy, state.order, state.pageSize]
  );

  const filterBy = useCallback(
    (filter: U, overwrite: boolean = false) => {
      if (overwrite) {
        fetchData({ filter, page: 1 });
      } else {
        fetchData({ filter: { ...state.filterBy, ...filter }, page: 1 });
      }
    },
    [state.fullText, state.pageSize, state.filterBy, state.sortBy, state.order]
  );

  const sortBy = useCallback(
    (sort: Partial<SortOption>) => {
      fetchData({ sort, page: 1 });
    },
    [state.fullText, state.pageSize, state.filterBy]
  );

  const paginate = useCallback(
    (data: OffsetPaginationData) => {
      fetchData({ page: data.page, pageSize: data.pageSize });
    },
    [state.fullText, state.sortBy, state.filterBy, state.order]
  );

  const refetch = useCallback(() => {
    fetchData({ page: 1 });
  }, [state.fullText, state.sortBy, state.filterBy, state.order, state.pageSize]);

  const fetch = useCallback(() => {
    fetchData();
  }, [state.fullText, state.sortBy, state.filterBy, state.order, state.pageSize, state.page]);

  const reset = useCallback(() => {
    dispatch({ type: 'RESET', payload: initialParams });

    (async () => {
      try {
        dispatch({ type: 'FETCH', payload: {} });
        const response = await resolver({
          skip: 0,
          limit: initialParams.pageSize,
          fullText: '',
          filterBy: initialParams.filterBy,
          sortBy: initialParams.sortBy,
          order: initialParams.order,
        });

        const { count, ...rest } = response;
        const payload = { ...rest, count };

        dispatch({ type: 'SUCCESS', payload });
      } catch (error) {
        dispatch({ type: 'ERROR' });
      }
    })();
  }, [initialParams]);

  return {
    count: state.count,
    data: state.data,
    loading: state.loading,
    error: state.error,
    sortedBy: state.sortBy,
    filteredBy: state.filterBy,
    order: state.order,
    fullText: state.fullText,
    pageSize: state.pageSize,
    page: state.page,
    searchByText,
    filterBy,
    sortBy,
    paginate,
    refetch,
    fetch,
    reset,
  };
};

export default useOffsetDataFetch;

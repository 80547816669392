import includes from 'lodash/includes';
import { ApolloError } from '@apollo/client';
import errorType from '@mantis/errors';
import { ErrorResponse } from '@types';

const parseError = (error: unknown): ErrorResponse => {
  if (error instanceof ApolloError) {
    if (error.networkError) {
      return {
        message: error.networkError.message,
        type: errorType.NETWORK_ERROR,
      };
    }

    if (error.graphQLErrors) {
      const errorWithKnownType = error.graphQLErrors.find((err) =>
        includes(Object.values(errorType), err.extensions.code)
      );

      if (errorWithKnownType) {
        return {
          message: errorWithKnownType.message,
          type: errorWithKnownType.extensions.code as string,
        };
      }

      return {
        message: error.message,
        type: errorType.UNKNOWN_ERROR,
      };
    }
  }

  if (error instanceof Error) {
    return {
      message: error.message,
      type: errorType.INTERNAL_SERVER_ERROR,
    };
  }

  return {
    message: 'Unknown error',
    type: errorType.UNKNOWN_ERROR,
  };
};

export default parseError;

import React from 'react';
import styles from './pieChart.module.scss';

interface LegendLabelProps {
  children: React.ReactNode;
  color: string;
}

const LegendLabel = ({ children, color }: LegendLabelProps) => (
  <div className={styles.label} style={{ '--color': color }}>
    {children}
  </div>
);

export default React.memo(LegendLabel);

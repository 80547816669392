import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { resetPassword } from '@services/auth';
import { FlexGrid, Row, Column } from '@carbon/react';
import CenteredContent from '@components/CenteredContent';
import Card from '@components/Card';
import ResetPasswordForm from './ResetPasswordForm';
import PasswordChanged from './PasswordChanged';

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (password: string) => {
    try {
      setLoading(true);
      const context = searchParams.get('context') || '';
      const response = await resetPassword(context, password);

      if (!response.success) {
        setError(response.errorMessage || 'Something went wrong');
      } else {
        setSubmitted(true);
      }

      return response.success;
    } catch (err: unknown) {
      setError('Something went wrong');

      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <CenteredContent>
      <FlexGrid condensed>
        <Row>
          <Column sm={4} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 5 }}>
            <Card>
              {!submitted ? (
                <ResetPasswordForm loading={loading} errorMessage={error} onSubmit={handleSubmit} />
              ) : (
                <PasswordChanged />
              )}
            </Card>
          </Column>
        </Row>
      </FlexGrid>
    </CenteredContent>
  );
};

export default ResetPasswordPage;

import React from 'react';
import { SkeletonText, SkeletonPlaceholder } from '@carbon/react';
import spacing from '@styles/spacing.module.scss';
import styles from './mainProfile.module.scss';

const ProfileSkeleton = () => (
  <React.Fragment>
    <SkeletonPlaceholder className={styles.skeletonImage} />
    <div className={styles.skeletonProfile}>
      <SkeletonText heading width="70%" />
      <SkeletonText className={spacing.mt6} width="60%" />
      <SkeletonText className={spacing.mt6} width="70%" />
      <SkeletonText className={spacing.mt6} width="100%" />
      <SkeletonText className={spacing.mt6} width="80%" />
    </div>
  </React.Fragment>
);

export default ProfileSkeleton;

import React, { useState, useEffect, useCallback } from 'react';
import { DateTime } from 'luxon';
import { Layer, DatePicker as CarbonDatePicker, DatePickerInput } from '@carbon/react';
import styles from './datePicker.module.scss';

interface DatePickerProps {
  id: string;
  title: string;
  minDate?: Nullable<Date>;
  maxDate?: Nullable<Date>;
  value?: Nullable<Date>;
  defaultValue?: Nullable<Date>;
  invalid?: boolean;
  invalidText?: string;
  onChange: (date: Nullable<string>) => void;
}

const DatePicker = ({
  id,
  title,
  minDate,
  maxDate,
  value,
  defaultValue,
  invalid,
  invalidText,
  onChange,
}: DatePickerProps) => {
  const [internalValue, setValue] = useState('');

  const formatDate = (date: Date) => DateTime.fromJSDate(date).toFormat('yyyy/MM/dd');

  useEffect(() => {
    if (defaultValue && !value) {
      const newDateValue = formatDate(defaultValue);
      setValue(newDateValue);
    } else if (!defaultValue) {
      if (value === null && internalValue !== '') {
        setValue('');
      } else if (value) {
        const newDateValue = formatDate(value);

        if (newDateValue !== internalValue) {
          setValue(newDateValue);
        }
      }
    }
  }, [internalValue, value, defaultValue]);

  const handleChange = useCallback(
    (date: Date[]) => {
      if (date.length === 0) {
        onChange(null);
      } else {
        onChange(DateTime.fromJSDate(date[0]).toISODate());
      }
    },
    [onChange]
  );

  return (
    <Layer>
      <CarbonDatePicker
        dateFormat="Y/m/d"
        datePickerType="single"
        minDate={minDate ? formatDate(minDate) : ''}
        maxDate={maxDate ? formatDate(maxDate) : ''}
        className={styles.datePicker}
        onChange={handleChange}
        value={internalValue}
      >
        <DatePickerInput
          id={id}
          placeholder="yyyy/mm/dd"
          labelText={title}
          size="md"
          autoComplete="off"
          invalid={invalid}
          invalidText={invalidText}
        />
      </CarbonDatePicker>
    </Layer>
  );
};

export default DatePicker;

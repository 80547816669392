import React, { useState } from 'react';
import classNames from 'classnames';
import ImageGallery from 'react-image-gallery';
import { Gender } from '@types';
import useImageOnLoad from '@hooks/useImageOnLoad';
import getPersonPlaceholder from '@utils/getPersonPlaceholder';
import baseStyles from '@styles/base.module.scss';
import GallerySkeleton from './GallerySkeleton';
import styles from './gallery.module.scss';

interface GalleryProps {
  name: string;
  gender?: Gender;
  images: string[];
}

const Gallery = ({ name, gender, images }: GalleryProps) => {
  const imageToLoadSrc = images.length > 0 ? images[0] : getPersonPlaceholder(gender);
  const [isFullscreen, setFullscreen] = useState(false);
  const { loaded, loading, error, dimension } = useImageOnLoad(imageToLoadSrc, {
    delayLoading: false,
    fallback: getPersonPlaceholder(gender),
  });

  const getImageWidth = () => {
    const MAX_WIDTH = 300;
    const MAX_HEIGH = 200;

    if (!dimension.width) {
      return MAX_WIDTH;
    }

    const aspectRatio = dimension.width / dimension.height;

    return MAX_HEIGH * aspectRatio;
  };

  const getImages = () =>
    images.map((image) => ({
      original: image,
      originalAlt: `Person ${name}`,
      originalTitle: name,
    }));

  if (loading) {
    return <GallerySkeleton />;
  }

  return (
    <div className={styles.container} style={{ '--imageWidth': getImageWidth() }}>
      {loaded && !error && images.length > 0 && (
        <ImageGallery
          items={getImages()}
          infinite={false}
          showPlayButton={false}
          showBullets={images.length > 1}
          showThumbnails={false}
          useBrowserFullscreen={false}
          additionalClass={isFullscreen ? styles.fullscreen : styles.gallery}
          onScreenChange={setFullscreen}
        />
      )}
      {(images.length === 0 || error) && (
        <img
          src={getPersonPlaceholder(gender)}
          alt="Profile placeholder"
          className={classNames(baseStyles.imgResponsive, styles.placeholderImage)}
          crossOrigin="anonymous"
        />
      )}
    </div>
  );
};

export default React.memo(Gallery);

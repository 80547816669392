import { gql } from '@services/gql';
import client from '@services/client';
import logger from '@services/logger';

const getCoordinates = async (): Promise<GeolocationCoordinates> =>
  new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      throw new Error('Geolocation is not supported in browser');
    }

    const handleSuccess = ({ coords }: GeolocationPosition) => resolve(coords);

    const handleError = (error: GeolocationPositionError) => {
      reject(error);
    };

    navigator.geolocation.getCurrentPosition(handleSuccess, handleError, { timeout: 5000 });
  });

const FETCH_LOCATION = gql(/* GraphQL */ `
  query fetchLocation($lat: Latitude!, $lng: Longitude!) {
    location(lat: $lat, lng: $lng) {
      city
      country
    }
  }
`);

const fetchMyLocation = async (): Promise<string | null> => {
  try {
    const coordinates = await getCoordinates();
    const response = await client.query({
      query: FETCH_LOCATION,
      variables: {
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      },
    });
    const { location } = response.data;

    if (!location) {
      return '';
    }
    if (!location.city) {
      return location.country;
    }
    if (!location.country) {
      return location.city;
    }

    return `${location.city}, ${location.country}`;
  } catch (error: unknown) {
    if (
      error instanceof GeolocationPositionError &&
      error.code !== GeolocationPositionError.PERMISSION_DENIED
    ) {
      logger.error(error.message, { error });
    }

    if (error instanceof Error) {
      logger.error(error.message, { error });
    }

    throw error;
  }
};

export default fetchMyLocation;

import React from 'react';
import useTypedParams from '@hooks/useTypedParams';
import OrganizationUsers from './OrganizationUsers';

interface OrganizationUsersPageProps {
  loading: boolean;
}

const OrganizationUsersPage = ({ loading }: OrganizationUsersPageProps) => {
  const { id } = useTypedParams(['id']);

  return <OrganizationUsers loading={loading} organizationId={id} />;
};

export default OrganizationUsersPage;

import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Heading from '@components/Heading';
import Navigate from '@components/Navigate';
import ErrorMessage from '@components/ErrorMessage';
import { Divider } from '@components/Form';
import { Layer, Form, Stack, TextInput, Button, InlineLoading } from '@carbon/react';
import spacing from '@styles/spacing.module.scss';

interface Fields {
  email: string;
}

export interface ForgotPasswordsProps {
  onSubmit: (email: string) => void;
  loading?: boolean;
  errorMessage?: string;
}

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().trim().required('Email is required').email('Invalid email'),
});

const ForgotPasswordForm = ({
  onSubmit,
  loading = false,
  errorMessage = '',
}: ForgotPasswordsProps) => {
  const initialValues: Fields = { email: '' };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (data: Fields) => {
      if (!loading) {
        onSubmit(data.email.trim());
      }
    },
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={6}>
        <div>
          <Heading>Forgot password</Heading>
          <div className={spacing.mt3}>
            Enter your email used during signup and we will send you a link to reset your password
          </div>
        </div>
        {errorMessage && !loading && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Layer>
          <TextInput
            id="email"
            labelText="Email"
            placeholder="email@test.com"
            title="User email"
            value={values.email}
            onChange={handleChange}
            invalid={errors.email && touched.email}
            invalidText={errors.email}
          />
        </Layer>
        {!loading ? (
          <Button type="submit">Get new password</Button>
        ) : (
          <InlineLoading description="Sending.." />
        )}
      </Stack>
      <Divider />
      <div>
        Don&apos;t have an account ? <Navigate to="/signup">Sing up</Navigate>
      </div>
    </Form>
  );
};

export default ForgotPasswordForm;

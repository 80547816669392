import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft } from '@carbon/icons-react';
import { Person } from '@mantis/types';
import LinkButton from '@components/LinkButton';
import ProfileInfo, { Title } from '@components/ProfileInfo';
import baseStyles from '@styles/base.module.scss';
import spacing from '@styles/spacing.module.scss';
import ProfileSkeleton from './ProfileSkeleton';
import Gallery from './Gallery';
import styles from './mainProfile.module.scss';
import parseProfile from './helpers/parseProfile';

interface MainProfileProps {
  person: Nullable<Person>;
  loading: boolean;
}

const MainProfile = ({ person, loading }: MainProfileProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackNavigationClick = () => {
    if (location.state?.from?.pathname === '/search/result') {
      navigate(-1);
    } else {
      navigate('/search');
    }
  };

  if (loading) {
    return (
      <div className={styles.container}>
        <ProfileSkeleton />
      </div>
    );
  }

  if (!person) {
    return null;
  }

  return (
    <div className={styles.container}>
      {person.images && (
        <Gallery images={person.images} gender={person.gender} name={person.name} />
      )}
      <div className={styles.profile}>
        <ProfileInfo
          profile={parseProfile(person)}
          header={
            <div className={styles.profileHeader}>
              <div className={spacing.mr4}>
                <Title>{person.name}</Title>
              </div>
              <LinkButton onClick={handleBackNavigationClick} className={baseStyles.center}>
                <ArrowLeft size={14} />
                <span className={spacing.ml2}>back</span>
              </LinkButton>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default React.memo(MainProfile);

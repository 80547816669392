import React, { useState, useEffect, useCallback } from 'react';
import useToast from '@hooks/useToast';
import ComboBox from '@components/ComboBox';
import fetchOrganizations from '../helpers/fetchOrganizations';
import { OrganizationSelectOption } from '../types';

interface FiltersProps {
  loading: boolean;
  organizationId: string;
  onChange: (organizationId: string) => void;
}

const Filters = ({ loading, organizationId, onChange }: FiltersProps) => {
  const toast = useToast();
  const [fetching, setFetching] = useState(true);
  const [organizations, setOrganizations] = useState<OrganizationSelectOption[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState(organizationId);

  // update org filter if "organization" prop is changed
  useEffect(() => {
    if (!selectedOrganization && organizationId) {
      setSelectedOrganization(organizationId);
    }
  }, [organizationId, selectedOrganization]);

  // fetch filter options
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const fetchedOrganizations = await fetchOrganizations();
        setOrganizations(fetchedOrganizations);
      } catch (error) {
        toast.warning({ title: 'Filter options', subtitle: 'Failed to fetch organizations' });
      } finally {
        setFetching(false);
      }
    };

    if (!loading) {
      fetchItems();
    } else {
      setFetching(false);
    }
  }, [loading]);

  const handleChange = useCallback(
    (id: string) => {
      setSelectedOrganization(id);
      onChange(id);
    },
    [onChange]
  );

  return (
    <ComboBox
      id="organization"
      title="Organization"
      hideLabel
      placeholder="Filter by organization"
      selected={selectedOrganization}
      items={organizations}
      loading={loading || fetching}
      onChange={handleChange}
    />
  );
};

export default Filters;

import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Stack, TextInput, Dropdown, Checkbox } from '@carbon/react';
import { UserRole } from '@types';
import usePrevious from '@hooks/usePrevious';
import Modal from '@components/Modal';
import ErrorMessage from '@components/ErrorMessage';
import { CreateUserFormData } from './types';

interface CreateUserProps {
  isOpen: boolean;
  loading: boolean;
  loggedInUserRole: UserRole;
  errorMessage?: string;
  onClose: () => void;
  onSubmit: (data: CreateUserFormData) => void;
}

interface Fields {
  name: string;
  email: string;
  role: UserRole;
  sendNotification: boolean;
}

interface RoleOption {
  value: UserRole;
  label: string;
}

interface SelectedItem {
  selectedItem: RoleOption;
}

const CreateUserSchema = Yup.object().shape({
  name: Yup.string().trim(),
  email: Yup.string().trim().required('User email is required').email('Invalid email'),
  role: Yup.string()
    .trim()
    .oneOf(['admin', 'user', 'organizationAdmin'])
    .required('User role is required'),
  sendNotification: Yup.bool().required(),
});

const CreateUser = ({
  isOpen,
  errorMessage,
  loading,
  loggedInUserRole,
  onClose,
  onSubmit,
}: CreateUserProps) => {
  const previouslyOpen = usePrevious(isOpen);
  const initialValues: Fields = { name: '', email: '', role: 'user', sendNotification: true };

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, resetForm } =
    useFormik({
      initialValues,
      validationSchema: CreateUserSchema,
      onSubmit: async (data: Fields) => {
        if (!loading) {
          onSubmit({
            name: data.name.trim(),
            email: data.email.trim(),
            role: data.role,
            sendNotification: data.sendNotification,
          });
        }
      },
    });

  // reset form once it's closed
  useEffect(() => {
    if (!isOpen && previouslyOpen) {
      resetForm();
    }
  }, [isOpen, previouslyOpen]);

  const handleRoleSelection = ({ selectedItem }: SelectedItem) => {
    setFieldValue('role', selectedItem.value);
  };

  const getRoleOptions = () => {
    const options: RoleOption[] = [{ value: 'user', label: 'User' }];

    if (loggedInUserRole === 'admin') {
      options.push({
        value: 'admin',
        label: 'Admin',
      });
    }

    options.push({
      value: 'organizationAdmin',
      label: loggedInUserRole === 'admin' ? 'Organization admin' : 'Admin',
    });

    return options;
  };

  return (
    <Modal
      isOpen={isOpen}
      loading={loading}
      loadingText="Submitting..."
      heading="Add user"
      primaryButtonText="Submit"
      secondaryButtonText="Cancel"
      selectorPrimaryFocus="input[id='name']"
      onClose={onClose}
      onSubmit={handleSubmit}
      size="md"
      visibleOverflow
    >
      <Form onSubmit={handleSubmit}>
        <Stack gap={6}>
          {errorMessage && !loading && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <TextInput
            id="name"
            labelText="Name (optional)"
            title="Name"
            value={values.name}
            onChange={handleChange}
            invalid={errors.name && touched.name}
            invalidText={errors.name}
          />
          <TextInput
            id="email"
            labelText="Email"
            title="Email"
            value={values.email}
            onChange={handleChange}
            invalid={errors.email && touched.email}
            invalidText={errors.email}
          />
          <Dropdown
            id="role"
            titleText="Role"
            label="Role"
            items={getRoleOptions()}
            initialSelectedItem={getRoleOptions()[0]}
            onChange={handleRoleSelection}
          />
          <Checkbox
            id="sendNotification"
            labelText="Send invitation email"
            checked={values.sendNotification}
            onChange={handleChange}
          />
        </Stack>
      </Form>
    </Modal>
  );
};

export default React.memo(CreateUser);

import React, { useCallback } from 'react';
import { NumberInput as CarbonNumberInput } from '@carbon/react';
import { NumberInputChangeEvent } from '@types';

interface NumberInputProps {
  id: string;
  label: string;
  allowEmpty?: boolean;
  min?: number;
  max?: number;
  value: Nullable<number>;
  onChange: (value: Nullable<number>) => void;
  invalid?: boolean;
  invalidText?: string;
}

const NumberInput = ({
  id,
  label,
  allowEmpty,
  min,
  max,
  value,
  onChange,
  invalid,
  invalidText,
}: NumberInputProps) => {
  const handleChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, data: NumberInputChangeEvent) => {
      if (data.value === 0) {
        onChange(0);
      } else if (Number.isNaN(data.value) && data.direction === 'up') {
        onChange(1);
      } else if (!data.value) {
        onChange(null);
      } else if (typeof data.value === 'string') {
        onChange(parseInt(data.value, 10));
      } else {
        onChange(data.value);
      }
    },
    []
  );

  return (
    <CarbonNumberInput
      id={id}
      label={label}
      allowEmpty={allowEmpty}
      min={min}
      max={max}
      value={value || value === 0 ? value : ''}
      onChange={handleChange}
      invalid={invalid}
      invalidText={invalidText}
    />
  );
};

export default NumberInput;

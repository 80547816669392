import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { animated, useTransition, config } from '@react-spring/web';
import { Stack } from '@carbon/react';
import useBreakpoint from '@hooks/useBreakpoint';
import { Toast } from './types';
import ToastComponent from './Toast';
import styles from './toasts.module.scss';

interface ToastsProps {
  toasts: Toast[];
  close: (id: string) => void;
}

// Ensure a element is appended to document body where toasts will be rendered by react portal
const portalRoot = (() => {
  const existingElement = document.getElementById('toasts-portal');
  if (existingElement) {
    return existingElement;
  }

  const newElement = document.createElement('div');
  newElement.setAttribute('id', 'toasts-portal');
  newElement.setAttribute('class', styles.container);
  document.body.appendChild(newElement);

  return newElement;
})();

const Toasts = ({ toasts, close }: ToastsProps) => {
  const { breakpoint } = useBreakpoint();
  const transitions = useTransition(toasts, {
    keys: (item) => item.id,
    from: {
      opacity: 1,
      maxHeight: breakpoint === 'sm' ? 0 : 150,
      transform: breakpoint === 'sm' ? 'translateY(110%)' : 'translateX(110%)',
    },
    enter:
      breakpoint === 'sm'
        ? { transform: 'translateY(0%)', maxHeight: 150 }
        : { transform: 'translateX(0%)' },
    leave: () => async (next) => {
      next({ opacity: breakpoint === 'sm' ? 0 : 0.5 });
      if (breakpoint === 'sm') {
        await next({ maxHeight: 0 });
      } else {
        await next({ maxHeight: 0, transform: 'translateX(110%)', delay: 50 });
      }
    },
    config: { ...config.stiff, clamp: true },
  });

  const handleClose = useCallback(
    (id: string) => () => {
      close(id);
    },
    []
  );

  return ReactDOM.createPortal(
    <Stack gap={3}>
      {transitions((style, toast) => (
        <animated.div style={style}>
          <ToastComponent
            title={toast.title}
            subtitle={toast.subtitle}
            type={toast.type}
            onClose={handleClose(toast.id)}
          />
        </animated.div>
      ))}
    </Stack>,
    portalRoot
  );
};

export default Toasts;

import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InlineLoading } from '@carbon/react';
import ButtonLoader from '@components/ButtonLoader';
import Navigate from '@components/Navigate';
import baseStyles from '@styles/base.module.scss';
import spacing from '@styles/spacing.module.scss';
import { verifyEmail, resendActivationMail } from '@services/auth';
import errorType from '@mantis/errors';
import styles from './verifyEmailPage.module.scss';
import Container from './Container';
import Result from './Result';

const VerifyEmailPage = () => {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<keyof typeof errorType>();
  const [loading, setLoading] = useState(true);
  const context = searchParams.get('context') || '';

  useEffect(() => {
    const verifyEmailFn = async () => {
      try {
        const response = await verifyEmail(context);

        if (!response.success && response.errorType && response.email) {
          setError(response.errorType);
          setEmail(response.email);
        }
      } catch (err) {
        setError(errorType.UNKNOWN_ERROR);
      } finally {
        setLoading(false);
      }
    };

    if (context) {
      verifyEmailFn();
    } else {
      setLoading(false);
    }
  }, []);

  // missing context
  if (!context || error === errorType.SIGNUP_INVALID_VERIFICATION_LINK) {
    return (
      <Container>
        <Result success={false} title="Email verification failed">
          <p>
            The verification link is invalid. Please verify your account by clicking on the link
            contained in the verification email we&apos;ve already sent to you.
          </p>
        </Result>
      </Container>
    );
  }

  // initial loader for verifying
  if (loading && !error) {
    return (
      <Container>
        <div className={baseStyles.center}>
          <InlineLoading
            className={styles.loader}
            description="Verifying email..."
            status="active"
          />
        </div>
      </Container>
    );
  }

  // email was successfully verified
  if (!error) {
    return (
      <Container>
        <Result success title="Successfully verified">
          <p>
            Your email was successfully verified. Now you can <Navigate to="/login">login</Navigate>{' '}
            with your credentials.
          </p>
        </Result>
      </Container>
    );
  }

  // email verification failed e.g. verification link expired
  return (
    <Container>
      <Result success={false} title="Email verification failed">
        {error === errorType.SIGNUP_EXPIRED_VERIFICATION_LINK ? (
          <React.Fragment>
            <p>
              Your verification link has expired. Request a new verification link to your email
              address.
            </p>
            <ButtonLoader
              classes={{ root: spacing.mt5 }}
              onClick={() => resendActivationMail(email)}
              loadingText="Sending..."
              successText="Sent!"
            >
              Resend email
            </ButtonLoader>
          </React.Fragment>
        ) : (
          <p>Something wend wrong</p>
        )}
      </Result>
    </Container>
  );
};

export default VerifyEmailPage;

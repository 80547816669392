import React, { useState, useEffect } from 'react';
import Heading from '@components/Heading';
import RESULT_MAP from '@data/searchResultMap';
import useToast from '@hooks/useToast';
import { formatDateTime } from '@utils/formatDate';
import { RecentSearchActivity } from './types';
import RecentActivitiesSkeleton from './RecentActivitiesSkeleton';
import fetchRecentActivities from './helpers/fetchRecentActivities';
import styles from './recentActivities.module.scss';

interface RecentActivitiesProps {
  userId: string;
  loading: boolean;
}

const RecentActivities = ({ userId, loading }: RecentActivitiesProps) => {
  const toast = useToast();
  const [fetching, setFetching] = useState(true);
  const [activities, setActivities] = useState<RecentSearchActivity[]>([]);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        setFetching(true);
        const fetchedActivities = await fetchRecentActivities(userId);
        setActivities(fetchedActivities);
      } catch (error) {
        toast.warning({ title: 'Fetch recent search activities', subtitle: 'Failed to fetch' });
      } finally {
        setFetching(false);
      }
    };

    if (userId && !loading) {
      fetchActivities();
    }
  }, [userId, loading]);

  if (loading) {
    return <RecentActivitiesSkeleton includeHeading />;
  }

  return (
    <React.Fragment>
      <Heading className={styles.heading}>Recent search activities</Heading>
      {fetching ? (
        <RecentActivitiesSkeleton includeHeading={false} />
      ) : (
        <React.Fragment>
          {activities.length === 0 && <i>No search activities recorded</i>}
          {activities.map((activity) => (
            <div key={activity.id} className={styles.item}>
              <span>{formatDateTime(activity.date)}</span>
              <span>{RESULT_MAP[activity.result]}</span>
            </div>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default RecentActivities;

import React from 'react';
import { InlineNotification } from '@carbon/react';
import styles from './errorMessage.module.scss';

export interface ErrorMessageProps {
  children: string;
}

const ErrorMessage = ({ children }: ErrorMessageProps) => (
  <InlineNotification
    className={styles.container}
    aria-label="closes notification"
    statusIconDescription="notification"
    subtitle={children}
    title="Error:"
    lowContrast
  />
);

export default ErrorMessage;

import React from 'react';

interface ToastData {
  title: string;
  subtitle?: string;
}

type ToastAction = (data: ToastData) => void;

interface ToastContext {
  success: ToastAction;
  warning: ToastAction;
  error: ToastAction;
}

export default React.createContext<ToastContext>({
  success: () => {},
  warning: () => {},
  error: () => {},
});

import React, { useMemo } from 'react';
import {
  OverflowMenu as CarbonOverflowMenu,
  unstable_FeatureFlags as FeatureFlags,
} from '@carbon/react';

interface OverflowMenuProps {
  ariaLabel?: string;
  renderIcon?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  children: React.ReactNode;
}

const OverflowMenu = ({
  renderIcon,
  size = 'md',
  ariaLabel = 'Menu',
  children,
}: OverflowMenuProps) => {
  const flags = useMemo(
    () => ({
      'enable-v12-overflowmenu': true,
    }),
    []
  );

  return (
    <FeatureFlags flags={flags}>
      <CarbonOverflowMenu
        renderIcon={renderIcon}
        size={size}
        aria-label={ariaLabel}
        label={ariaLabel}
      >
        {children}
      </CarbonOverflowMenu>
    </FeatureFlags>
  );
};

export default OverflowMenu;

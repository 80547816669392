import client from '@services/client';
import { gql } from '@services/gql';

const RESEND_NOTIFICATION = gql(/* GraphQL */ `
  mutation resendNotification($id: ID!) {
    userResendNotification(id: $id) {
      success
    }
  }
`);

const resendNotification = async (id: string) => {
  const response = await client.mutate({ mutation: RESEND_NOTIFICATION, variables: { id } });

  if (!response.data?.userResendNotification.success) {
    throw new Error('Something went wrong');
  }
};

export default resendNotification;

import React, { useState, useEffect } from 'react';
import ErrorBoundary from '@components/ErrorBoundary';
import LoadingWrapper from '@components/LoadingWrapper';
import LocationSkeleton from './LocationsSkeleton';
import { LocationsProps } from './types';

// lazy component
const Locations = React.lazy(() => import('./Locations'));

type LocationsLoaderProps = LocationsProps & {
  isOpen: boolean;
};

const LocationsLoader = ({ isOpen, addresses }: LocationsLoaderProps) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (isOpen && !initialized) {
      setInitialized(true);
    }
  }, [isOpen]);

  if (!initialized) {
    return null;
  }

  return (
    <ErrorBoundary errorMessage="Failed to load map">
      <React.Suspense
        fallback={
          <LoadingWrapper ariaLabel="Loading map">
            <LocationSkeleton />
          </LoadingWrapper>
        }
      >
        <Locations addresses={addresses} />
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default LocationsLoader;

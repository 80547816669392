import client from '@services/client';
import logger from '@services/logger';
import { gql } from '@services/gql';

const CHECK_ORG_EXISTS = gql(/* GraphQL */ `
  query checkOrgExists($id: ID!) {
    organization(id: $id) {
      id
    }
  }
`);

const organizationExists = async (id: string): Promise<boolean> => {
  try {
    const response = await client.query({
      query: CHECK_ORG_EXISTS,
      variables: { id },
      fetchPolicy: 'network-only',
    });

    return !!response.data.organization;
  } catch (error: unknown) {
    if (error instanceof Error) {
      logger.error(error.message, { error });
    } else {
      logger.error('Something went wrong', { error });
    }

    throw error;
  }
};

export default organizationExists;

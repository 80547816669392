import React, { useRef, useEffect } from 'react';
import { Match, SearchParams } from '@mantis/types';
import spacing from '@styles/spacing.module.scss';
import SearchResultStatus from './SearchResultStatus';
import SearchResultCard from './SearchResultCard';

interface SearchResultProps {
  searchParams: SearchParams;
  count: number;
  items: Match[];
  warnings: string[];
  scrollIntoView?: boolean;
}

const SearchResult = ({
  searchParams,
  count,
  items,
  warnings,
  scrollIntoView,
}: SearchResultProps) => {
  const resultElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollIntoView && resultElement.current) {
      resultElement.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollIntoView]);

  const getQueryText = (): string => {
    if ('query' in searchParams) {
      return searchParams.query;
    }

    if (searchParams.firstName) {
      return `${searchParams.firstName} ${searchParams.lastName}`;
    }

    return searchParams.email || searchParams.username || searchParams.userId || '';
  };

  return (
    <div ref={resultElement} style={{ scrollMarginTop: '70px' }}>
      <div className={spacing.mt5}>
        <SearchResultStatus query={getQueryText()} count={count} warnings={warnings} />
      </div>
      {items.map((item) => (
        <div key={item.id} className={spacing.mt5}>
          <SearchResultCard match={item} />
        </div>
      ))}
    </div>
  );
};

export default SearchResult;

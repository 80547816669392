import React, { useCallback, useMemo } from 'react';
import { Stack } from '@carbon/react';
import {
  PieChart as PieChartLib,
  Pie,
  Cell,
  Label,
  LabelProps,
  ResponsiveContainer,
} from 'recharts';
import COLORS_MAP from '@data/colorsMap';
import LegendLabel from './LegendLabel';
import styles from './pieChart.module.scss';

export interface PieChartData {
  name: string;
  value: number;
  color: 'blue' | 'green' | 'red' | 'gray' | 'lightGray';
}

interface PieChartProps {
  data: PieChartData[];
  label?: string;
  text?: string;
  renderLegend?: (name: string, value: number) => React.ReactNode;
  empty?: boolean;
}

const PieChart = ({ data, label, text, empty = false, renderLegend }: PieChartProps) => {
  const parsedData: PieChartData[] = useMemo(() => {
    if (!empty) {
      return data;
    }

    return [
      {
        name: 'Total',
        value: 100,
        color: 'lightGray',
      },
    ];
  }, [data, empty]);

  const renderLabel = useCallback(
    ({ viewBox }: LabelProps) => {
      if (viewBox && 'cx' in viewBox) {
        return (
          <g>
            {label && (
              <text x={viewBox?.cx} y={viewBox?.cy} dy={-5} textAnchor="middle">
                {label}
              </text>
            )}
            {text && (
              <text
                x={viewBox?.cx}
                y={viewBox?.cy}
                dy={15}
                textAnchor="middle"
                className={styles.text}
              >
                {text}
              </text>
            )}
          </g>
        );
      }

      return null;
    },
    [text, label]
  );

  return (
    <div className={styles.chart}>
      <div className={styles.legend}>
        <Stack gap={3}>
          {data.map((item) => (
            <LegendLabel key={item.name} color={COLORS_MAP[item.color]}>
              {renderLegend ? (
                renderLegend(item.name, item.value)
              ) : (
                <React.Fragment>
                  {item.name}: {item.value}
                </React.Fragment>
              )}
            </LegendLabel>
          ))}
        </Stack>
      </div>
      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChartLib>
            <Pie
              data={parsedData.map((item) => ({ name: item.name, value: item.value }))}
              cx="50%"
              cy="50%"
              innerRadius={55}
              outerRadius={70}
              paddingAngle={4}
              labelLine={false}
              dataKey="value"
            >
              {(text || label) && <Label position="center" content={renderLabel} />}
              {parsedData.map((item) => (
                <Cell key={item.name} fill={COLORS_MAP[item.color]} />
              ))}
            </Pie>
          </PieChartLib>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default React.memo(PieChart);

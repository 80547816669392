import React from 'react';
import { SkeletonText, SkeletonPlaceholder } from '@carbon/react';
import spacing from '@styles/spacing.module.scss';
import styles from './locations.module.scss';

const LocationSkeleton = () => (
  <div className={styles.container}>
    <SkeletonPlaceholder className={styles.map} />
    <div>
      <SkeletonText paragraph lineCount={2} />
      <div className={spacing.mt6}>
        <SkeletonText paragraph lineCount={2} />
      </div>
    </div>
  </div>
);

export default LocationSkeleton;

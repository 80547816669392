import React from 'react';

interface LoadingWrapperProps {
  ariaLabel: string;
  children: React.ReactNode;
}

const LoadingWrapper = ({ ariaLabel, children }: LoadingWrapperProps) => (
  <div aria-label={ariaLabel} aria-live="assertive" role="status">
    {children}
  </div>
);

export default LoadingWrapper;

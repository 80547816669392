import React from 'react';
import { PersonFieldMetadata } from '@mantis/types';
import useBreakpoint from '@hooks/useBreakpoint';
import Tooltip from '@components/Tooltip';

interface MetadataInfoProps {
  metadata: PersonFieldMetadata;
}

const MetadataInfo = ({ metadata }: MetadataInfoProps) => {
  const { breakpointDown } = useBreakpoint();

  if (!metadata.lastSeen && !metadata.validSince) {
    return null;
  }

  return (
    <Tooltip align={breakpointDown('sm') ? 'left' : 'bottom'}>
      <React.Fragment>
        {metadata.validSince && (
          <div>
            <b>Valid since:</b> {metadata.validSince}
          </div>
        )}
        {metadata.lastSeen && (
          <div>
            <b>Last seen:</b> {metadata.lastSeen}
          </div>
        )}
      </React.Fragment>
    </Tooltip>
  );
};

export default MetadataInfo;

import React, { useCallback } from 'react';
import LoadingWrapper from '@components/LoadingWrapper';
import PieChart, { PieChartSkeleton } from '@components/PieChart';
import Tooltip from '@components/Tooltip';
import percentage from '@utils/percentage';
import baseStyles from '@styles/base.module.scss';
import spacing from '@styles/spacing.module.scss';

interface YearlyUtilizationProps {
  loading: boolean;
  user: number;
  organization: number;
}

const YearlyUtilization = ({ loading, user, organization }: YearlyUtilizationProps) => {
  if (loading) {
    return (
      <LoadingWrapper ariaLabel="Loading yearly utilization">
        <PieChartSkeleton items={2} />
      </LoadingWrapper>
    );
  }

  const renderLegend = useCallback((name: string, value: number) => {
    if (name === 'Total searches') {
      return (
        <React.Fragment>
          <div className={baseStyles.dFlex}>
            {name}
            <span className={spacing.mx1}>
              <Tooltip>Number of all user searches within organization</Tooltip>
            </span>
          </div>
          <b>{organization}</b>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {name}
        <div className={spacing.mt2}>
          <b>{value}</b>
        </div>
      </React.Fragment>
    );
  }, []);

  return (
    <PieChart
      label="Utilization rate"
      text={`${percentage(user, organization, false)}%`}
      renderLegend={renderLegend}
      data={[
        { name: 'User searches', value: user, color: 'blue' },
        { name: 'Total searches', value: organization - user, color: 'lightGray' },
      ]}
    />
  );
};

export default YearlyUtilization;

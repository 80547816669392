import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Theme,
  Content,
  Header,
  HeaderName,
  HeaderGlobalBar,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderGlobalAction,
  HeaderPanel,
  HeaderMenuButton,
  HeaderSideNavItems,
  SkeletonIcon,
  SideNav,
  SideNavItems,
} from '@carbon/react';
import { User as UserIcon } from '@carbon/icons-react';
import ClickAwayListener from '@components/ClickAwayListener';
import useBreakpoint from '@hooks/useBreakpoint';
import useComponentSize from '@hooks/useElementSize';
import Account from '@components/Account';
import { User } from '@types';
import logoPath from '@assets/logo.svg';
import styles from './layout.module.scss';
import WarningMessage from './WarningMessage';

export interface LayoutProps {
  children: React.ReactNode;
  authenticated: boolean;
  warningMessage?: string;
  loading?: boolean;
  user?: User | null;
}

const Layout = ({
  children,
  authenticated,
  warningMessage,
  user = null,
  loading = false,
}: LayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { breakpointDown, breakpointUp } = useBreakpoint();
  const [ref, size] = useComponentSize({ debounce: true });
  const [isPanelOpen, openPanel] = useState(false);
  const [isMenuOpen, openMenu] = useState(false);

  useEffect(() => {
    const root = document.documentElement;

    if (root) {
      root.style.setProperty('--warningElementHeight', size.height.toString());
    }
  }, [size.height]);

  const handleNavigate = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const href = event.currentTarget.getAttribute('href');
    if (href) {
      navigate(href);
    }
  };

  const isCurrentPage = (route: string | string[], exact: boolean = false) =>
    ([] as string[]).concat(route).some((item) => {
      if (exact) {
        return item === location.pathname;
      }

      return location.pathname.includes(item);
    });

  const renderNavigationItems = (includeAriaLabel: boolean = false) => (
    <React.Fragment>
      {user?.role === 'admin' && (
        <HeaderMenuItem
          href="/organizations"
          aria-label={includeAriaLabel ? 'Organizations mobile' : ''}
          onClick={handleNavigate}
          className={styles.link}
          isActive={isCurrentPage(['/organizations', '/organization'])}
        >
          Organizations
        </HeaderMenuItem>
      )}
      {user?.role === 'organizationAdmin' && (
        <HeaderMenuItem
          href="/users"
          aria-label={includeAriaLabel ? 'Users mobile' : ''}
          onClick={handleNavigate}
          className={styles.link}
          isActive={isCurrentPage('/users', true)}
        >
          Users
        </HeaderMenuItem>
      )}
      {user && ['admin', 'organizationAdmin'].includes(user.role) && (
        <React.Fragment>
          <HeaderMenuItem
            href="/statistics"
            aria-label={includeAriaLabel ? 'Statistics mobile' : ''}
            onClick={handleNavigate}
            className={styles.link}
            isActive={isCurrentPage('/statistics', true)}
          >
            Statistics
          </HeaderMenuItem>
          <HeaderMenuItem
            href="/history"
            aria-label={includeAriaLabel ? 'Search history mobile' : ''}
            onClick={handleNavigate}
            className={styles.link}
            isActive={isCurrentPage('/history', true)}
          >
            Search history
          </HeaderMenuItem>
        </React.Fragment>
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {warningMessage && (
        <div ref={ref} className={styles.warningMessageWrapper}>
          <WarningMessage message={warningMessage} />
        </div>
      )}
      <Theme theme="g100">
        <Header aria-label="Mantis" className={styles.header}>
          <div className={styles.container}>
            {/* Header navigation on mobile */}
            {!loading && authenticated && user && user.role !== 'user' && breakpointDown('md') && (
              <React.Fragment>
                <HeaderMenuButton
                  aria-label="Open menu"
                  onClick={() => openMenu(!isMenuOpen)}
                  isActive={isMenuOpen}
                />
                <SideNav
                  aria-label="Side navigation"
                  expanded={isMenuOpen}
                  isPersistent={false}
                  className={styles.sideNav}
                >
                  <SideNavItems>
                    <HeaderSideNavItems>{renderNavigationItems(true)}</HeaderSideNavItems>
                  </SideNavItems>
                </SideNav>
              </React.Fragment>
            )}
            {/* App name */}
            <HeaderName className={styles.appName} href="/" prefix="" onClick={handleNavigate}>
              <img src={logoPath} alt="logo" className={styles.logo} />
              Mantis
            </HeaderName>
            {/* Header links for admin */}
            {!loading && authenticated && user && user.role !== 'user' && breakpointUp('lg') && (
              <HeaderNavigation aria-label="Header navigation">
                {renderNavigationItems()}
              </HeaderNavigation>
            )}
            {/* Account icon */}
            <HeaderGlobalBar>
              {!loading && user && authenticated && (
                <HeaderGlobalAction
                  tooltipAlignment="end"
                  aria-label="Account"
                  isActive={isPanelOpen}
                  onClick={() => openPanel(true)}
                >
                  <UserIcon size={20} />
                </HeaderGlobalAction>
              )}
              {loading && <SkeletonIcon className={styles.loader} />}
            </HeaderGlobalBar>
            {/* Account profile */}
            {!loading && user && authenticated && (
              <ClickAwayListener onClickAway={() => openPanel(false)}>
                <HeaderPanel
                  aria-label="Account panel"
                  className={classNames(styles.panel, { [styles.expanded]: isPanelOpen })}
                  expanded={isPanelOpen}
                >
                  {isPanelOpen && (
                    <Account
                      name={user.name || `${user.organization.name} user`}
                      email={user.email}
                      userRole={user.role}
                      onSuccessfulLogout={() => openPanel(false)}
                    />
                  )}
                </HeaderPanel>
              </ClickAwayListener>
            )}
          </div>
        </Header>
      </Theme>
      <Theme theme="g10">
        <Content className={classNames(styles.content, { [styles.hasWarning]: warningMessage })}>
          {children}
        </Content>
      </Theme>
    </React.Fragment>
  );
};
export default Layout;

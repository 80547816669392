import config from '@config';
import { Gender } from '@types';

const getPersonPlaceholder = (gender?: Gender) => {
  if (gender === 'female') {
    return config.personPlaceholder.woman;
  }

  return config.personPlaceholder.man;
};

export default getPersonPlaceholder;

import urlExist from 'url-exist';

/**
 * Filter out images which URL are not reachable and has small dimension
 * @param {string[]} images
 * @returns {string[]}
 */
const filterImages = async (images: string[]): Promise<string[]> => {
  const results = await Promise.all(images.map((url) => urlExist(url)));

  return images.filter((_image, index) => results[index]);
};

export default filterImages;

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SignupData } from '@types';
import { signup, resendActivationMail } from '@services/auth';
import { FlexGrid, Row, Column } from '@carbon/react';
import CenteredContent from '@components/CenteredContent';
import Card from '@components/Card';
import SignupForm from './SignupForm';
import EmailVerification from './EmailVerification';

const SignupPage = () => {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (data: SignupData) => {
    try {
      setLoading(true);
      const response = await signup(data);

      if (!response.success) {
        setError(response.errorMessage || 'Something went wrong');
      } else {
        setEmail(data.email);
        setSubmitted(true);
      }

      return response.success;
    } catch (err: unknown) {
      setError('Something went wrong');

      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <CenteredContent>
      <FlexGrid condensed>
        <Row>
          <Column sm={4} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 5 }}>
            <Card>
              {!submitted ? (
                <SignupForm
                  loading={loading}
                  errorMessage={error}
                  email={searchParams.get('email') || ''}
                  onSubmit={handleSubmit}
                />
              ) : (
                <EmailVerification onResendMail={() => resendActivationMail(email)} />
              )}
            </Card>
          </Column>
        </Row>
      </FlexGrid>
    </CenteredContent>
  );
};

export default SignupPage;

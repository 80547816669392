import React, { useState, useEffect, useRef } from 'react';
import ErrorMessage from '@components/ErrorMessage';
import { MonthlyStats, YearlyStats } from '@components/Statistics';
import Filters from './Filters';
import YearlyUtilization from './YearlyUtilization';
import MonthlyUtilization from './MonthlyUtilization';
import Heading from '../Heading';
import fetchUserStatistics from './helpers/fetchStatistics';
import fetchOrganizationStatistics from '../helpers/fetchOrganizationStatistics';
import { StatisticsData, UserData, UserStatisticsFilter } from '../types';
import styles from './userStatistics.module.scss';

interface UserStatisticsProps {
  isOpen: boolean;
  loading: boolean;
  isAdmin: boolean;
  userData: UserData;
}

const UserStatistics = ({ isOpen, loading, isAdmin, userData }: UserStatisticsProps) => {
  const fetchedOrganizationId = useRef('');
  const [orgStats, setOrgStats] = useState<Nullable<StatisticsData>>(null);
  const [userStats, setUserStats] = useState<Nullable<StatisticsData>>(null);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);

  const fetchStats = async (filter: UserStatisticsFilter) => {
    try {
      setFetching(true);

      const promises = [fetchUserStatistics({ user: filter.user, year: filter.year })];
      if (fetchedOrganizationId.current !== filter.organization) {
        promises.push(
          fetchOrganizationStatistics({ organization: filter.organization, year: filter.year })
        );
      }

      // fetch stats
      const [fetchedUserStats, fetchedOrgStats] = await Promise.all(promises);

      // set org stats
      setUserStats(fetchedUserStats);

      // set org stats
      if (fetchedOrgStats) {
        fetchedOrganizationId.current = filter.organization;
        setOrgStats(fetchedOrgStats);
      }
    } catch (err) {
      setError(true);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (!loading && userData.id) {
      const currentYear = new Date().getFullYear();
      fetchStats({ user: userData.id, organization: userData.organization.id, year: currentYear });
    }
  }, [userData.id, loading]);

  const handleChange = (filter: UserStatisticsFilter) => {
    if (filter.user) {
      fetchStats(filter);
    }
  };

  return (
    <React.Fragment>
      <Filters loading={loading} isAdmin={isAdmin} userData={userData} onChange={handleChange} />
      {error && (
        <div className={styles.error}>
          <ErrorMessage>Something went wrong during fetching statistics</ErrorMessage>
        </div>
      )}
      {isOpen && (
        <React.Fragment>
          <div className={styles.yearlyStats}>
            <Heading loading={loading || fetching}>Yearly statistics</Heading>
            <div className={styles.yearlyStatsCharts}>
              <YearlyStats
                loading={loading || fetching}
                total={userStats?.yearly.statistics.total || 0}
                match={userStats?.yearly.statistics.match || 0}
                noMatch={userStats?.yearly.statistics.noMatch || 0}
                error={userStats?.yearly.statistics.error || 0}
              />
              <div className={styles.yearlyUtilization}>
                <YearlyUtilization
                  loading={loading || fetching}
                  user={userStats?.yearly.statistics.total || 0}
                  organization={orgStats?.yearly.statistics.total || 0}
                />
              </div>
            </div>
          </div>
          <div className={styles.monthlyChart}>
            <Heading loading={loading || fetching}>Monthly statistics</Heading>
            <MonthlyStats loading={loading || fetching} statistics={userStats?.monthly || []} />
          </div>
          <div className={styles.monthlyChart}>
            <Heading loading={loading || fetching}>Utilization rate</Heading>
            <MonthlyUtilization
              loading={loading || fetching}
              userStatistics={userStats?.monthly || []}
              organizationStatistics={orgStats?.monthly || []}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default UserStatistics;

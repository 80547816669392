import React, { useContext } from 'react';
import { RepeatOne } from '@carbon/icons-react';
import { Button } from '@carbon/react';
import TableContext from './TableContext';

interface RefreshButtonProps {
  className?: string;
  primary?: boolean;
  label?: string;
}

const RefreshButton = ({ className, primary, label }: RefreshButtonProps) => {
  const { refresh } = useContext(TableContext);

  if (label) {
    return (
      <Button
        className={className}
        onClick={refresh}
        renderIcon={RepeatOne}
        kind={primary ? 'primary' : 'ghost'}
        name="Refresh data"
      >
        {label}
      </Button>
    );
  }

  return (
    <Button
      className={className}
      onClick={refresh}
      renderIcon={RepeatOne}
      iconDescription="Refresh data"
      kind={primary ? 'primary' : 'ghost'}
      hasIconOnly
      name="Refresh data"
    />
  );
};

export default RefreshButton;

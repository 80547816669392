import React from 'react';
import { User } from '@types';
import UsersList from '@components/UsersList';

interface UsersPageProps {
  loading: boolean;
  loggedInUser: Nullable<User>;
}

const UsersPage = ({ loading, loggedInUser }: UsersPageProps) => (
  <UsersList
    loading={loading || !loggedInUser}
    organization={loggedInUser?.organization.id || ''}
    loggedInUser={loggedInUser}
  />
);

export default UsersPage;

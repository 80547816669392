/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query fetchApplicationData {\n    application {\n      warningMessage\n    }\n  }\n':
    types.fetchApplicationDataDocument,
  '\n  query fetchMe {\n    me {\n      id\n      name\n      email\n      createdAt\n      role\n      organization {\n        id\n        name\n        createdAt\n      }\n    }\n  }\n':
    types.fetchMeDocument,
  '\n  mutation createUser(\n    $name: String\n    $email: EmailAddress!\n    $role: UserRole!\n    $organization: ID!\n    $sendNotification: Boolean!\n  ) {\n    userCreate(\n      data: {\n        name: $name\n        email: $email\n        role: $role\n        organization: $organization\n        sendNotification: $sendNotification\n      }\n    ) {\n      id\n    }\n  }\n':
    types.createUserDocument,
  '\n  mutation deleteUser($id: ID!) {\n    userDelete(id: $id) {\n      success\n    }\n  }\n':
    types.deleteUserDocument,
  '\n  query fetchUsers($fullText: String, $organization: ID, $sort: UserSort, $limit: Int, $skip: Int) {\n    usersList(\n      filter: { fullText: $fullText, organization: $organization }\n      sort: $sort\n      limit: $limit\n      offset: $skip\n    ) {\n      count\n      items {\n        id\n        createdAt\n        name\n        email\n        status\n        role\n        recentActivity\n        recentVolume\n      }\n    }\n  }\n':
    types.fetchUsersDocument,
  '\n  mutation resendNotification($id: ID!) {\n    userResendNotification(id: $id) {\n      success\n    }\n  }\n':
    types.resendNotificationDocument,
  '\n  mutation updateUser($id: ID!, $data: UserUpdateInput!) {\n    userUpdate(id: $id, data: $data) {\n      id\n    }\n  }\n':
    types.updateUserDocument,
  '\n  query fetchMarker($address: String!) {\n    coordinates(address: $address) {\n      lat\n      lng\n    }\n  }\n':
    types.fetchMarkerDocument,
  '\n  fragment Metadata on Metadata {\n    current\n    inferred\n    validSince\n    lastSeen\n  }\n':
    types.MetadataFragmentDoc,
  '\n  fragment Name on PersonName {\n    value\n    first\n    last\n    middle\n    type\n    metadata {\n      ...Metadata\n    }\n  }\n':
    types.NameFragmentDoc,
  '\n  query fetchPerson($id: ID!) {\n    person(id: $id) {\n      name\n      age\n      dateOfBirth\n      dateOfBirthRange\n      gender\n      languages\n      originCountries\n      images\n      names {\n        ...Name\n      }\n      emails {\n        value\n        type\n        metadata {\n          ...Metadata\n          disposable\n        }\n      }\n      phones {\n        value\n        type\n        metadata {\n          ...Metadata\n          doNotCall\n          voip\n        }\n      }\n      addresses {\n        value\n        type\n        metadata {\n          ...Metadata\n        }\n      }\n      educations {\n        value\n        degree\n        school\n        start\n        end\n        metadata {\n          ...Metadata\n        }\n      }\n      jobs {\n        value\n        title\n        industry\n        organization\n        start\n        end\n        metadata {\n          ...Metadata\n        }\n      }\n      urls {\n        url\n        domain\n        name\n        title\n        phrase\n        category\n        metadata {\n          ...Metadata\n          sponsored\n        }\n      }\n      userIds {\n        value\n        metadata {\n          ...Metadata\n        }\n      }\n      relationships {\n        type\n        subtype\n        names {\n          ...Name\n        }\n      }\n      tags {\n        source\n        classification\n        value\n        metadata {\n          ...Metadata\n        }\n      }\n      vehicles {\n        vin\n        year\n        manufacturer\n        model\n        type\n        metadata {\n          ...Metadata\n        }\n      }\n    }\n  }\n':
    types.fetchPersonDocument,
  '\n  query fetchContracts($organization: ID!) {\n    organization(id: $organization) {\n      name\n      contracts {\n        id\n        start\n        end\n        count\n        status\n        usage\n      }\n    }\n  }\n':
    types.fetchContractsDocument,
  '\n  mutation saveContracts($organization: ID!, $contracts: [ContractInput!]!) {\n    organizationUpdate(id: $organization, data: { contracts: $contracts }) {\n      contracts {\n        id\n        start\n        end\n        count\n        status\n        usage\n      }\n    }\n  }\n':
    types.saveContractsDocument,
  '\n  query fetchOrganizationName($id: ID!) {\n    organization(id: $id) {\n      id\n      name\n    }\n  }\n':
    types.fetchOrganizationNameDocument,
  '\n  mutation createOrganization(\n    $name: String!\n    $orgAdminName: String\n    $orgAdminEmail: EmailAddress!\n    $sendNotification: Boolean!\n    $contract: ContractInput\n  ) {\n    organizationCreate(\n      data: {\n        name: $name\n        admin: { name: $orgAdminName, email: $orgAdminEmail }\n        contract: $contract\n        sendNotification: $sendNotification\n      }\n    ) {\n      id\n    }\n  }\n':
    types.createOrganizationDocument,
  '\n  mutation deleteOrganization($id: ID!) {\n    organizationDelete(id: $id) {\n      success\n    }\n  }\n':
    types.deleteOrganizationDocument,
  '\n  query fetchOrganizations($fullText: String, $sort: OrganizationSort, $limit: Int, $skip: Int) {\n    organizationsList(filter: { fullText: $fullText }, sort: $sort, limit: $limit, offset: $skip) {\n      count\n      items {\n        id\n        createdAt\n        name\n        recentVolume\n        currentContract {\n          id\n          start\n          end\n          count\n          usage\n        }\n        users {\n          count\n        }\n      }\n    }\n  }\n':
    types.fetchOrganizationsDocument,
  '\n  mutation updateOrganization($id: ID!, $name: String!) {\n    organizationUpdate(id: $id, data: { name: $name }) {\n      id\n    }\n  }\n':
    types.updateOrganizationDocument,
  '\n  query fetchRecentActivities($userId: ID!) {\n    searchActivities(filter: { user: $userId }, limit: 10) {\n      items {\n        id\n        date\n        result\n        type\n      }\n    }\n  }\n':
    types.fetchRecentActivitiesDocument,
  '\n  fragment Statistics on Statistics {\n    total\n    match\n    noMatch\n    error\n  }\n':
    types.StatisticsFragmentDoc,
  '\n  query fetchMyStatistics($year: Int!) {\n    userStatistics(year: $year) {\n      yearly {\n        year\n        statistics {\n          ...Statistics\n        }\n      }\n      monthly {\n        month\n        statistics {\n          ...Statistics\n        }\n      }\n    }\n  }\n':
    types.fetchMyStatisticsDocument,
  '\n  mutation updateName($id: ID!, $name: String!) {\n    userUpdate(id: $id, data: { name: $name }) {\n      id\n      name\n    }\n  }\n':
    types.updateNameDocument,
  '\n  query fetchLocation($lat: Latitude!, $lng: Longitude!) {\n    location(lat: $lat, lng: $lng) {\n      city\n      country\n    }\n  }\n':
    types.fetchLocationDocument,
  '\n  fragment SearchResult on SearchResult {\n    count\n    warnings\n    items {\n      id\n      pointer\n      probability\n      name\n      gender\n      images\n      age\n      dateOfBirth\n      dateOfBirthRange\n      address\n      phone\n    }\n  }\n':
    types.SearchResultFragmentDoc,
  '\n  query basicSearch($query: String!, $location: String) {\n    search(query: $query, location: $location) {\n      ...SearchResult\n    }\n  }\n':
    types.basicSearchDocument,
  '\n  query advancedSearch(\n    $firstName: String\n    $lastName: String\n    $middleName: String\n    $age: String\n    $email: String\n    $phone: String\n    $username: String\n    $userId: String\n    $url: String\n    $country: String\n    $state: String\n    $city: String\n    $street: String\n    $zipCode: String\n    $organization: String\n    $industry: String\n  ) {\n    advancedSearch(\n      firstName: $firstName\n      lastName: $lastName\n      middleName: $middleName\n      age: $age\n      email: $email\n      phone: $phone\n      username: $username\n      userId: $userId\n      url: $url\n      country: $country\n      state: $state\n      city: $city\n      street: $street\n      zipCode: $zipCode\n      organization: $organization\n      industry: $industry\n    ) {\n      ...SearchResult\n    }\n  }\n':
    types.advancedSearchDocument,
  '\n  query fetchOrganizationsOptionsForHistory {\n    organizationsList {\n      items {\n        id\n        name\n      }\n    }\n  }\n':
    types.fetchOrganizationsOptionsForHistoryDocument,
  '\n  query fetchSearchHistory(\n    $user: ID\n    $organization: ID\n    $from: Date\n    $to: Date\n    $limit: Int!\n    $after: ID\n    $before: ID\n  ) {\n    searchActivities(\n      filter: { user: $user, organization: $organization, from: $from, to: $to }\n      limit: $limit\n      after: $after\n      before: $before\n    ) {\n      count\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      items {\n        id\n        user\n        organization\n        date\n        result\n        type\n      }\n    }\n  }\n':
    types.fetchSearchHistoryDocument,
  '\n  query fetchUsersOptions {\n    usersList {\n      items {\n        id\n        email\n      }\n    }\n  }\n':
    types.fetchUsersOptionsDocument,
  '\n  query checkOrgExists($id: ID!) {\n    organization(id: $id) {\n      id\n    }\n  }\n':
    types.checkOrgExistsDocument,
  '\n  query checkUserExists($id: ID!) {\n    user(id: $id) {\n      id\n    }\n  }\n':
    types.checkUserExistsDocument,
  '\n  query fetchOrganizationContracts($id: ID!) {\n    organization(id: $id) {\n      id\n      contracts {\n        id\n        start\n        end\n        status\n        count\n        usage\n      }\n    }\n  }\n':
    types.fetchOrganizationContractsDocument,
  '\n  query fetchUserStatistics($user: ID!, $year: Int!) {\n    userStatistics(user: $user, year: $year) {\n      yearly {\n        year\n        statistics {\n          ...Statistics\n        }\n      }\n      monthly {\n        month\n        statistics {\n          ...Statistics\n        }\n      }\n    }\n  }\n':
    types.fetchUserStatisticsDocument,
  '\n  query fetchUsersForStats {\n    usersList {\n      items {\n        id\n        name\n        email\n        createdAt\n        organization {\n          id\n          name\n        }\n      }\n    }\n  }\n':
    types.fetchUsersForStatsDocument,
  '\n  query fetchOrganizationStatistics($organization: ID, $year: Int!) {\n    organizationStatistics(organization: $organization, year: $year) {\n      yearly {\n        year\n        statistics {\n          ...Statistics\n        }\n      }\n      monthly {\n        month\n        statistics {\n          ...Statistics\n        }\n      }\n    }\n  }\n':
    types.fetchOrganizationStatisticsDocument,
  '\n  query fetchOrganizationsOptionsForStats {\n    organizationsList {\n      items {\n        id\n        name\n        createdAt\n      }\n    }\n  }\n':
    types.fetchOrganizationsOptionsForStatsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchApplicationData {\n    application {\n      warningMessage\n    }\n  }\n'
): typeof documents['\n  query fetchApplicationData {\n    application {\n      warningMessage\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchMe {\n    me {\n      id\n      name\n      email\n      createdAt\n      role\n      organization {\n        id\n        name\n        createdAt\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchMe {\n    me {\n      id\n      name\n      email\n      createdAt\n      role\n      organization {\n        id\n        name\n        createdAt\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createUser(\n    $name: String\n    $email: EmailAddress!\n    $role: UserRole!\n    $organization: ID!\n    $sendNotification: Boolean!\n  ) {\n    userCreate(\n      data: {\n        name: $name\n        email: $email\n        role: $role\n        organization: $organization\n        sendNotification: $sendNotification\n      }\n    ) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation createUser(\n    $name: String\n    $email: EmailAddress!\n    $role: UserRole!\n    $organization: ID!\n    $sendNotification: Boolean!\n  ) {\n    userCreate(\n      data: {\n        name: $name\n        email: $email\n        role: $role\n        organization: $organization\n        sendNotification: $sendNotification\n      }\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation deleteUser($id: ID!) {\n    userDelete(id: $id) {\n      success\n    }\n  }\n'
): typeof documents['\n  mutation deleteUser($id: ID!) {\n    userDelete(id: $id) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchUsers($fullText: String, $organization: ID, $sort: UserSort, $limit: Int, $skip: Int) {\n    usersList(\n      filter: { fullText: $fullText, organization: $organization }\n      sort: $sort\n      limit: $limit\n      offset: $skip\n    ) {\n      count\n      items {\n        id\n        createdAt\n        name\n        email\n        status\n        role\n        recentActivity\n        recentVolume\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchUsers($fullText: String, $organization: ID, $sort: UserSort, $limit: Int, $skip: Int) {\n    usersList(\n      filter: { fullText: $fullText, organization: $organization }\n      sort: $sort\n      limit: $limit\n      offset: $skip\n    ) {\n      count\n      items {\n        id\n        createdAt\n        name\n        email\n        status\n        role\n        recentActivity\n        recentVolume\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation resendNotification($id: ID!) {\n    userResendNotification(id: $id) {\n      success\n    }\n  }\n'
): typeof documents['\n  mutation resendNotification($id: ID!) {\n    userResendNotification(id: $id) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateUser($id: ID!, $data: UserUpdateInput!) {\n    userUpdate(id: $id, data: $data) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation updateUser($id: ID!, $data: UserUpdateInput!) {\n    userUpdate(id: $id, data: $data) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchMarker($address: String!) {\n    coordinates(address: $address) {\n      lat\n      lng\n    }\n  }\n'
): typeof documents['\n  query fetchMarker($address: String!) {\n    coordinates(address: $address) {\n      lat\n      lng\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Metadata on Metadata {\n    current\n    inferred\n    validSince\n    lastSeen\n  }\n'
): typeof documents['\n  fragment Metadata on Metadata {\n    current\n    inferred\n    validSince\n    lastSeen\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Name on PersonName {\n    value\n    first\n    last\n    middle\n    type\n    metadata {\n      ...Metadata\n    }\n  }\n'
): typeof documents['\n  fragment Name on PersonName {\n    value\n    first\n    last\n    middle\n    type\n    metadata {\n      ...Metadata\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchPerson($id: ID!) {\n    person(id: $id) {\n      name\n      age\n      dateOfBirth\n      dateOfBirthRange\n      gender\n      languages\n      originCountries\n      images\n      names {\n        ...Name\n      }\n      emails {\n        value\n        type\n        metadata {\n          ...Metadata\n          disposable\n        }\n      }\n      phones {\n        value\n        type\n        metadata {\n          ...Metadata\n          doNotCall\n          voip\n        }\n      }\n      addresses {\n        value\n        type\n        metadata {\n          ...Metadata\n        }\n      }\n      educations {\n        value\n        degree\n        school\n        start\n        end\n        metadata {\n          ...Metadata\n        }\n      }\n      jobs {\n        value\n        title\n        industry\n        organization\n        start\n        end\n        metadata {\n          ...Metadata\n        }\n      }\n      urls {\n        url\n        domain\n        name\n        title\n        phrase\n        category\n        metadata {\n          ...Metadata\n          sponsored\n        }\n      }\n      userIds {\n        value\n        metadata {\n          ...Metadata\n        }\n      }\n      relationships {\n        type\n        subtype\n        names {\n          ...Name\n        }\n      }\n      tags {\n        source\n        classification\n        value\n        metadata {\n          ...Metadata\n        }\n      }\n      vehicles {\n        vin\n        year\n        manufacturer\n        model\n        type\n        metadata {\n          ...Metadata\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchPerson($id: ID!) {\n    person(id: $id) {\n      name\n      age\n      dateOfBirth\n      dateOfBirthRange\n      gender\n      languages\n      originCountries\n      images\n      names {\n        ...Name\n      }\n      emails {\n        value\n        type\n        metadata {\n          ...Metadata\n          disposable\n        }\n      }\n      phones {\n        value\n        type\n        metadata {\n          ...Metadata\n          doNotCall\n          voip\n        }\n      }\n      addresses {\n        value\n        type\n        metadata {\n          ...Metadata\n        }\n      }\n      educations {\n        value\n        degree\n        school\n        start\n        end\n        metadata {\n          ...Metadata\n        }\n      }\n      jobs {\n        value\n        title\n        industry\n        organization\n        start\n        end\n        metadata {\n          ...Metadata\n        }\n      }\n      urls {\n        url\n        domain\n        name\n        title\n        phrase\n        category\n        metadata {\n          ...Metadata\n          sponsored\n        }\n      }\n      userIds {\n        value\n        metadata {\n          ...Metadata\n        }\n      }\n      relationships {\n        type\n        subtype\n        names {\n          ...Name\n        }\n      }\n      tags {\n        source\n        classification\n        value\n        metadata {\n          ...Metadata\n        }\n      }\n      vehicles {\n        vin\n        year\n        manufacturer\n        model\n        type\n        metadata {\n          ...Metadata\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchContracts($organization: ID!) {\n    organization(id: $organization) {\n      name\n      contracts {\n        id\n        start\n        end\n        count\n        status\n        usage\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchContracts($organization: ID!) {\n    organization(id: $organization) {\n      name\n      contracts {\n        id\n        start\n        end\n        count\n        status\n        usage\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation saveContracts($organization: ID!, $contracts: [ContractInput!]!) {\n    organizationUpdate(id: $organization, data: { contracts: $contracts }) {\n      contracts {\n        id\n        start\n        end\n        count\n        status\n        usage\n      }\n    }\n  }\n'
): typeof documents['\n  mutation saveContracts($organization: ID!, $contracts: [ContractInput!]!) {\n    organizationUpdate(id: $organization, data: { contracts: $contracts }) {\n      contracts {\n        id\n        start\n        end\n        count\n        status\n        usage\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchOrganizationName($id: ID!) {\n    organization(id: $id) {\n      id\n      name\n    }\n  }\n'
): typeof documents['\n  query fetchOrganizationName($id: ID!) {\n    organization(id: $id) {\n      id\n      name\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createOrganization(\n    $name: String!\n    $orgAdminName: String\n    $orgAdminEmail: EmailAddress!\n    $sendNotification: Boolean!\n    $contract: ContractInput\n  ) {\n    organizationCreate(\n      data: {\n        name: $name\n        admin: { name: $orgAdminName, email: $orgAdminEmail }\n        contract: $contract\n        sendNotification: $sendNotification\n      }\n    ) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation createOrganization(\n    $name: String!\n    $orgAdminName: String\n    $orgAdminEmail: EmailAddress!\n    $sendNotification: Boolean!\n    $contract: ContractInput\n  ) {\n    organizationCreate(\n      data: {\n        name: $name\n        admin: { name: $orgAdminName, email: $orgAdminEmail }\n        contract: $contract\n        sendNotification: $sendNotification\n      }\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation deleteOrganization($id: ID!) {\n    organizationDelete(id: $id) {\n      success\n    }\n  }\n'
): typeof documents['\n  mutation deleteOrganization($id: ID!) {\n    organizationDelete(id: $id) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchOrganizations($fullText: String, $sort: OrganizationSort, $limit: Int, $skip: Int) {\n    organizationsList(filter: { fullText: $fullText }, sort: $sort, limit: $limit, offset: $skip) {\n      count\n      items {\n        id\n        createdAt\n        name\n        recentVolume\n        currentContract {\n          id\n          start\n          end\n          count\n          usage\n        }\n        users {\n          count\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchOrganizations($fullText: String, $sort: OrganizationSort, $limit: Int, $skip: Int) {\n    organizationsList(filter: { fullText: $fullText }, sort: $sort, limit: $limit, offset: $skip) {\n      count\n      items {\n        id\n        createdAt\n        name\n        recentVolume\n        currentContract {\n          id\n          start\n          end\n          count\n          usage\n        }\n        users {\n          count\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateOrganization($id: ID!, $name: String!) {\n    organizationUpdate(id: $id, data: { name: $name }) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation updateOrganization($id: ID!, $name: String!) {\n    organizationUpdate(id: $id, data: { name: $name }) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchRecentActivities($userId: ID!) {\n    searchActivities(filter: { user: $userId }, limit: 10) {\n      items {\n        id\n        date\n        result\n        type\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchRecentActivities($userId: ID!) {\n    searchActivities(filter: { user: $userId }, limit: 10) {\n      items {\n        id\n        date\n        result\n        type\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Statistics on Statistics {\n    total\n    match\n    noMatch\n    error\n  }\n'
): typeof documents['\n  fragment Statistics on Statistics {\n    total\n    match\n    noMatch\n    error\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchMyStatistics($year: Int!) {\n    userStatistics(year: $year) {\n      yearly {\n        year\n        statistics {\n          ...Statistics\n        }\n      }\n      monthly {\n        month\n        statistics {\n          ...Statistics\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchMyStatistics($year: Int!) {\n    userStatistics(year: $year) {\n      yearly {\n        year\n        statistics {\n          ...Statistics\n        }\n      }\n      monthly {\n        month\n        statistics {\n          ...Statistics\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateName($id: ID!, $name: String!) {\n    userUpdate(id: $id, data: { name: $name }) {\n      id\n      name\n    }\n  }\n'
): typeof documents['\n  mutation updateName($id: ID!, $name: String!) {\n    userUpdate(id: $id, data: { name: $name }) {\n      id\n      name\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchLocation($lat: Latitude!, $lng: Longitude!) {\n    location(lat: $lat, lng: $lng) {\n      city\n      country\n    }\n  }\n'
): typeof documents['\n  query fetchLocation($lat: Latitude!, $lng: Longitude!) {\n    location(lat: $lat, lng: $lng) {\n      city\n      country\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SearchResult on SearchResult {\n    count\n    warnings\n    items {\n      id\n      pointer\n      probability\n      name\n      gender\n      images\n      age\n      dateOfBirth\n      dateOfBirthRange\n      address\n      phone\n    }\n  }\n'
): typeof documents['\n  fragment SearchResult on SearchResult {\n    count\n    warnings\n    items {\n      id\n      pointer\n      probability\n      name\n      gender\n      images\n      age\n      dateOfBirth\n      dateOfBirthRange\n      address\n      phone\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query basicSearch($query: String!, $location: String) {\n    search(query: $query, location: $location) {\n      ...SearchResult\n    }\n  }\n'
): typeof documents['\n  query basicSearch($query: String!, $location: String) {\n    search(query: $query, location: $location) {\n      ...SearchResult\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query advancedSearch(\n    $firstName: String\n    $lastName: String\n    $middleName: String\n    $age: String\n    $email: String\n    $phone: String\n    $username: String\n    $userId: String\n    $url: String\n    $country: String\n    $state: String\n    $city: String\n    $street: String\n    $zipCode: String\n    $organization: String\n    $industry: String\n  ) {\n    advancedSearch(\n      firstName: $firstName\n      lastName: $lastName\n      middleName: $middleName\n      age: $age\n      email: $email\n      phone: $phone\n      username: $username\n      userId: $userId\n      url: $url\n      country: $country\n      state: $state\n      city: $city\n      street: $street\n      zipCode: $zipCode\n      organization: $organization\n      industry: $industry\n    ) {\n      ...SearchResult\n    }\n  }\n'
): typeof documents['\n  query advancedSearch(\n    $firstName: String\n    $lastName: String\n    $middleName: String\n    $age: String\n    $email: String\n    $phone: String\n    $username: String\n    $userId: String\n    $url: String\n    $country: String\n    $state: String\n    $city: String\n    $street: String\n    $zipCode: String\n    $organization: String\n    $industry: String\n  ) {\n    advancedSearch(\n      firstName: $firstName\n      lastName: $lastName\n      middleName: $middleName\n      age: $age\n      email: $email\n      phone: $phone\n      username: $username\n      userId: $userId\n      url: $url\n      country: $country\n      state: $state\n      city: $city\n      street: $street\n      zipCode: $zipCode\n      organization: $organization\n      industry: $industry\n    ) {\n      ...SearchResult\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchOrganizationsOptionsForHistory {\n    organizationsList {\n      items {\n        id\n        name\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchOrganizationsOptionsForHistory {\n    organizationsList {\n      items {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchSearchHistory(\n    $user: ID\n    $organization: ID\n    $from: Date\n    $to: Date\n    $limit: Int!\n    $after: ID\n    $before: ID\n  ) {\n    searchActivities(\n      filter: { user: $user, organization: $organization, from: $from, to: $to }\n      limit: $limit\n      after: $after\n      before: $before\n    ) {\n      count\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      items {\n        id\n        user\n        organization\n        date\n        result\n        type\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchSearchHistory(\n    $user: ID\n    $organization: ID\n    $from: Date\n    $to: Date\n    $limit: Int!\n    $after: ID\n    $before: ID\n  ) {\n    searchActivities(\n      filter: { user: $user, organization: $organization, from: $from, to: $to }\n      limit: $limit\n      after: $after\n      before: $before\n    ) {\n      count\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      items {\n        id\n        user\n        organization\n        date\n        result\n        type\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchUsersOptions {\n    usersList {\n      items {\n        id\n        email\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchUsersOptions {\n    usersList {\n      items {\n        id\n        email\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query checkOrgExists($id: ID!) {\n    organization(id: $id) {\n      id\n    }\n  }\n'
): typeof documents['\n  query checkOrgExists($id: ID!) {\n    organization(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query checkUserExists($id: ID!) {\n    user(id: $id) {\n      id\n    }\n  }\n'
): typeof documents['\n  query checkUserExists($id: ID!) {\n    user(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchOrganizationContracts($id: ID!) {\n    organization(id: $id) {\n      id\n      contracts {\n        id\n        start\n        end\n        status\n        count\n        usage\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchOrganizationContracts($id: ID!) {\n    organization(id: $id) {\n      id\n      contracts {\n        id\n        start\n        end\n        status\n        count\n        usage\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchUserStatistics($user: ID!, $year: Int!) {\n    userStatistics(user: $user, year: $year) {\n      yearly {\n        year\n        statistics {\n          ...Statistics\n        }\n      }\n      monthly {\n        month\n        statistics {\n          ...Statistics\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchUserStatistics($user: ID!, $year: Int!) {\n    userStatistics(user: $user, year: $year) {\n      yearly {\n        year\n        statistics {\n          ...Statistics\n        }\n      }\n      monthly {\n        month\n        statistics {\n          ...Statistics\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchUsersForStats {\n    usersList {\n      items {\n        id\n        name\n        email\n        createdAt\n        organization {\n          id\n          name\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchUsersForStats {\n    usersList {\n      items {\n        id\n        name\n        email\n        createdAt\n        organization {\n          id\n          name\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchOrganizationStatistics($organization: ID, $year: Int!) {\n    organizationStatistics(organization: $organization, year: $year) {\n      yearly {\n        year\n        statistics {\n          ...Statistics\n        }\n      }\n      monthly {\n        month\n        statistics {\n          ...Statistics\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchOrganizationStatistics($organization: ID, $year: Int!) {\n    organizationStatistics(organization: $organization, year: $year) {\n      yearly {\n        year\n        statistics {\n          ...Statistics\n        }\n      }\n      monthly {\n        month\n        statistics {\n          ...Statistics\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchOrganizationsOptionsForStats {\n    organizationsList {\n      items {\n        id\n        name\n        createdAt\n      }\n    }\n  }\n'
): typeof documents['\n  query fetchOrganizationsOptionsForStats {\n    organizationsList {\n      items {\n        id\n        name\n        createdAt\n      }\n    }\n  }\n'];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;

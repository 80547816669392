import { ApolloError } from '@apollo/client';
import { SearchResponse } from '@types';
import { SearchParams, Match } from '@mantis/types';
import { GENDER_MAP } from '@mantis/data';
import { gql } from '@services/gql';
import client from '@services/client';
import logger from '@services/logger';
import replaceNullsWithUndefined from '@utils/replaceNullsWithUndefined';

gql(/* GraphQL */ `
  fragment SearchResult on SearchResult {
    count
    warnings
    items {
      id
      pointer
      probability
      name
      gender
      images
      age
      dateOfBirth
      dateOfBirthRange
      address
      phone
    }
  }
`);

const BASIC_SEARCH = gql(/* GraphQL */ `
  query basicSearch($query: String!, $location: String) {
    search(query: $query, location: $location) {
      ...SearchResult
    }
  }
`);

const ADVANCED_SEARCH = gql(/* GraphQL */ `
  query advancedSearch(
    $firstName: String
    $lastName: String
    $middleName: String
    $age: String
    $email: String
    $phone: String
    $username: String
    $userId: String
    $url: String
    $country: String
    $state: String
    $city: String
    $street: String
    $zipCode: String
    $organization: String
    $industry: String
  ) {
    advancedSearch(
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      age: $age
      email: $email
      phone: $phone
      username: $username
      userId: $userId
      url: $url
      country: $country
      state: $state
      city: $city
      street: $street
      zipCode: $zipCode
      organization: $organization
      industry: $industry
    ) {
      ...SearchResult
    }
  }
`);

const fetchResult = async (params: SearchParams): Promise<SearchResponse> => {
  try {
    let result;

    // basic search
    if ('query' in params) {
      const response = await client.query({
        query: BASIC_SEARCH,
        variables: params,
      });

      result = response.data.search;
    } else {
      // advanced search
      const response = await client.query({
        query: ADVANCED_SEARCH,
        variables: params,
      });

      result = response.data.advancedSearch;
    }

    return {
      count: result.count,
      warnings: result.warnings,
      items: result.items.map(
        (match): Match => ({
          ...replaceNullsWithUndefined(match),
          gender: match.gender ? GENDER_MAP[match.gender] : undefined,
        })
      ),
    };
  } catch (error: unknown) {
    if (!(error instanceof ApolloError)) {
      const context = `${'query' in params ? 'Basic' : 'Advanced'} search`;

      if (error instanceof Error) {
        logger.error(error.message, { context });
      } else {
        logger.error(error, { context });
      }
    }

    throw error;
  }
};

export default fetchResult;

import React, { useState, useContext } from 'react';
import { Button, InlineLoading, Tag } from '@carbon/react';
import { User, UserAdmin, Logout } from '@carbon/icons-react';
import { UserRole } from '@types';
import useToast from '@hooks/useToast';
import AuthContext from '@context/AuthContext';
import Navigate from '@components/Navigate';
import baseStyles from '@styles/base.module.scss';
import spacing from '@styles/spacing.module.scss';
import styles from './account.module.scss';

export interface AccountProps {
  name: string;
  email: string;
  userRole: UserRole;
  onSuccessfulLogout: () => void;
}

const USER_ROLE_MAP = {
  admin: 'Admin',
  user: 'User',
  organizationAdmin: 'Organization admin',
} as const;

const Account = ({ name, email, userRole, onSuccessfulLogout }: AccountProps) => {
  const toast = useToast();
  const { logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    try {
      setLoading(true);
      await logout();
      onSuccessfulLogout();
    } catch (error) {
      setLoading(false);
      toast.error({ title: 'Logout action', subtitle: 'Something went wrong' });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.profile}>
        <div className={baseStyles.center}>
          <div>
            <div className={styles.name}>{name}</div>
            <div className={styles.email}>{email}</div>
          </div>
          <div className={styles.icon}>
            {['admin', 'organizationAdmin'].includes(userRole) ? (
              <UserAdmin size={20} />
            ) : (
              <User size={24} />
            )}
          </div>
        </div>
        <Tag size="sm" className={styles.role}>
          {USER_ROLE_MAP[userRole]}
        </Tag>
        <div className={spacing.mt4}>
          <Navigate to="/profile">My profile</Navigate>
        </div>
      </div>
      <Button
        className={styles.logout}
        onClick={handleLogout}
        kind="secondary"
        disabled={loading}
        renderIcon={() => (!loading ? <Logout className={spacing.ml2} size={16} /> : null)}
      >
        Logout
        {loading && (
          <div className={spacing.ml3}>
            <InlineLoading />
          </div>
        )}
      </Button>
    </div>
  );
};

export default Account;

import React from 'react';
import BaseHeading, { HeadingSkeleton } from '@components/Heading';
import spacing from '@styles/spacing.module.scss';

interface HeadingProps {
  loading: boolean;
  children: React.ReactNode;
}

const Heading = ({ loading, children }: HeadingProps) => {
  if (loading) {
    return <HeadingSkeleton width={110} className={spacing.mb4} />;
  }

  return (
    <BaseHeading level={3} className={spacing.mb4}>
      {children}
    </BaseHeading>
  );
};

export default Heading;

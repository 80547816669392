import orderBy from 'lodash/orderBy';
import { useReducer, useRef, useCallback } from 'react';
import { nanoid } from 'nanoid';
import { Contract, SortOption, SortOrder } from '@types';
import fetchContracts from './helpers/fetchContracts';
import saveContracts from './helpers/saveContracts';
import { SortField, ContractData } from '../types';

interface ContractsOption {
  initialSort?: SortOption<SortField>;
}

interface ContractsState {
  error: boolean;
  loading: boolean;
  organization: string;
  contracts: Contract[];
  sortedBy: string;
  orderedBy: SortOrder;
}

interface ActionLoading {
  type: 'LOAD';
  payload: {
    loading: boolean;
  };
}

interface ActionFetch {
  type: 'FETCH';
}

interface ActionSort {
  type: 'SORT';
  payload: {
    sort: Partial<SortOption<SortField>>;
  };
}

interface ActionSuccess {
  type: 'SUCCESS';
  payload: {
    organization: string;
    contracts: Contract[];
  };
}

interface ActionError {
  type: 'ERROR';
}

interface ActionSet {
  type: 'SET';
  payload: {
    contracts: Contract[];
  };
}

type ContractsAction =
  | ActionLoading
  | ActionFetch
  | ActionSort
  | ActionSuccess
  | ActionError
  | ActionSet;

const reducer = (state: ContractsState, action: ContractsAction): ContractsState => {
  switch (action.type) {
    case 'LOAD':
      return {
        ...state,
        loading: action.payload.loading,
      };
    case 'FETCH':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        organization: action.payload.organization,
        contracts: orderBy(
          action.payload.contracts,
          state.sortedBy,
          state.orderedBy === 'DESC' ? 'desc' : 'asc'
        ),
      };
    case 'SET':
      return {
        ...state,
        contracts: orderBy(
          action.payload.contracts,
          state.sortedBy,
          state.orderedBy === 'DESC' ? 'desc' : 'asc'
        ),
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: true,
      };
    case 'SORT': {
      const sortedBy = action.payload.sort.field || state.sortedBy;
      const orderedBy = action.payload.sort.order || state.orderedBy;

      return {
        ...state,
        sortedBy,
        orderedBy,
        contracts: orderBy(state.contracts, sortedBy, orderedBy === 'DESC' ? 'desc' : 'asc'),
      };
    }
    default: {
      return state;
    }
  }
};

const useContracts = (organizationId: string, options: ContractsOption) => {
  const fetchedOrgId = useRef('');
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    error: false,
    sortedBy: options.initialSort?.field || 'start',
    orderedBy: options.initialSort?.order || 'DESC',
    organization: '',
    contracts: [],
  });

  const fetch = useCallback(async () => {
    try {
      fetchedOrgId.current = organizationId;
      dispatch({ type: 'FETCH' });
      const response = await fetchContracts(organizationId);
      dispatch({
        type: 'SUCCESS',
        payload: {
          organization: response.organization,
          contracts: response.contracts,
        },
      });
    } catch (error) {
      dispatch({ type: 'ERROR' });
    }
  }, [organizationId, fetchedOrgId.current]);

  const sortBy = useCallback((sort: Partial<SortOption<SortField>>) => {
    dispatch({
      type: 'SORT',
      payload: { sort },
    });
  }, []);

  const create = useCallback(
    async (contract: ContractData) => {
      const newContract = {
        id: nanoid(10),
        ...contract,
      };

      dispatch({ type: 'LOAD', payload: { loading: true } });

      try {
        const contracts = await saveContracts(organizationId, [...state.contracts, newContract]);
        dispatch({
          type: 'SET',
          payload: {
            contracts,
          },
        });
      } finally {
        dispatch({ type: 'LOAD', payload: { loading: false } });
      }
    },
    [organizationId, state.contracts]
  );

  const remove = useCallback(
    async (id: string) => {
      if (!state.contracts.some((contract) => contract.id === id)) {
        return;
      }

      dispatch({ type: 'LOAD', payload: { loading: true } });

      try {
        const contracts = await saveContracts(
          organizationId,
          state.contracts.filter((contract) => contract.id !== id)
        );

        dispatch({
          type: 'SET',
          payload: {
            contracts,
          },
        });
      } finally {
        dispatch({ type: 'LOAD', payload: { loading: false } });
      }
    },
    [organizationId, state.contracts]
  );

  const update = useCallback(
    async (id: string, data: ContractData) => {
      if (!state.contracts.some((contract) => id === contract.id)) {
        return;
      }

      dispatch({ type: 'LOAD', payload: { loading: true } });

      try {
        const contracts = await saveContracts(organizationId, [
          ...state.contracts.filter((contract) => contract.id !== id),
          data,
        ]);

        dispatch({
          type: 'SET',
          payload: {
            contracts,
          },
        });
      } finally {
        dispatch({ type: 'LOAD', payload: { loading: false } });
      }
    },
    [state.contracts]
  );

  return {
    error: state.error,
    loading: state.loading,
    sortedBy: state.sortedBy,
    orderedBy: state.orderedBy,
    organization: state.organization,
    contracts: state.contracts,
    fetch,
    sortBy,
    create,
    remove,
    update,
  };
};

export default useContracts;

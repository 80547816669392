import React, { useState, useCallback, useEffect } from 'react';
import { Layer, Dropdown, SelectSkeleton } from '@carbon/react';
import { SelectBoxChangeEvent, SelectOption } from '@types';
import LoadingWrapper from '@components/LoadingWrapper';

interface SelectProps {
  id: string;
  title: string;
  items: SelectOption[];
  hideLabel?: boolean;
  placeholder?: string;
  loading?: boolean;
  selected?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const Select = ({
  id,
  title,
  items,
  hideLabel = false,
  placeholder,
  loading,
  selected,
  disabled = false,
  onChange,
}: SelectProps) => {
  const [value, setValue] = useState<SelectOption>();

  useEffect(() => {
    const foundValue = items.find((item) => item.value === selected);
    if (foundValue !== value) {
      setValue(foundValue);
    }
  }, [items, selected]);

  const handleChange = useCallback(
    (item: SelectBoxChangeEvent<SelectOption>) => {
      setValue(item.selectedItem);
      onChange(item.selectedItem?.value || '');
    },
    [onChange]
  );

  const parseValue = useCallback((item: SelectOption) => item?.label || '', []);

  if (loading) {
    return (
      <LoadingWrapper ariaLabel="Loading select box">
        <SelectSkeleton width="140px" hideLabel={hideLabel} />
      </LoadingWrapper>
    );
  }

  return (
    <Layer>
      <Dropdown
        id={id}
        label={title}
        titleText={title}
        hideLabel={hideLabel}
        placeholder={placeholder}
        selectedItem={value}
        items={items}
        itemToString={parseValue}
        onChange={handleChange}
        disabled={disabled}
      />
    </Layer>
  );
};

export default Select;

import React from 'react';
import classNames from 'classnames';
import List from '@components/List';
import { minLength, minLowercase, minUppercase, minNumbers, minSymbols } from './validate';
import styles from './passwordRequirements.module.scss';

export interface PasswordRequirementsProps {
  password: string;
}

const PasswordRequirements = ({ password }: PasswordRequirementsProps) => (
  <List>
    <List.Item className={classNames({ [styles.fulfilled]: minLength(password) })}>
      password must be at least 8 characters
    </List.Item>
    <List.Item className={classNames({ [styles.fulfilled]: minLowercase(password) })}>
      password must contain at least 1 lowercase letter
    </List.Item>
    <List.Item className={classNames({ [styles.fulfilled]: minUppercase(password) })}>
      password must contain at least 1 uppercase letter
    </List.Item>
    <List.Item className={classNames({ [styles.fulfilled]: minNumbers(password) })}>
      password must contain at least 1 number
    </List.Item>
    <List.Item className={classNames({ [styles.fulfilled]: minSymbols(password) })}>
      password must contain at least 1 symbol
    </List.Item>
  </List>
);

export default PasswordRequirements;

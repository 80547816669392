import React, { useMemo, useCallback } from 'react';
import { SelectOption } from '@types';
import Select from '@components/Select';
import styles from './statistics.module.scss';

interface SelectYearProps {
  loading: boolean;
  year: number;
  onChange: (year: number) => void;
}

const SelectYear = ({ loading, year: selectedYear, onChange }: SelectYearProps) => {
  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const options: SelectOption[] = [];

    for (let year = 2022; year <= currentYear; year += 1) {
      options.push({ label: year.toString(), value: year.toString() });
    }

    return options;
  }, []);

  const handleYearChange = useCallback((value: string) => {
    onChange(parseInt(value, 10));
  }, []);

  return (
    <div className={styles.selectYear}>
      <Select
        id="year"
        title="Year"
        hideLabel
        selected={selectedYear.toString()}
        items={yearOptions}
        loading={loading}
        onChange={handleYearChange}
      />
    </div>
  );
};

export default SelectYear;

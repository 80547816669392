import { useCallback } from 'react';
import useLoader from '@hooks/useLoader';
import useModal from '@hooks/useModal';
import createOrganization from './helpers/createOrganization';
import updateOrganization from './helpers/updateOrganization';
import deleteOrganization from './helpers/deleteOrganization';
import { CreateOrganizationData } from '../types';

type Action = 'editOrganization' | 'createOrganization' | 'deleteOrganization';

const useOrganizationActions = () => {
  const { openModal, closeModal, isModalOpen } = useModal<Action>();
  const updateLoader = useLoader();
  const createLoader = useLoader();
  const deleteLoader = useLoader();

  const isLoading = useCallback(
    (action: Action) => {
      if (action === 'createOrganization') {
        return createLoader.loading;
      }
      if (action === 'editOrganization') {
        return updateLoader.loading;
      }

      return deleteLoader.loading;
    },
    [createLoader.loading, updateLoader.loading, deleteLoader.loading]
  );

  const getError = useCallback(
    (action: Action) => {
      if (action === 'createOrganization') {
        return createLoader.error;
      }
      if (action === 'editOrganization') {
        return updateLoader.error;
      }

      return deleteLoader.error;
    },
    [createLoader.error, updateLoader.error, deleteLoader.error]
  );

  const closeModalAction = useCallback(() => {
    closeModal();

    // clear error messages when modal is closed
    updateLoader.actions.reset();
    createLoader.actions.reset();
    deleteLoader.actions.reset();
  }, []);

  const createOrganizationAction = useCallback(
    async (data: CreateOrganizationData): Promise<boolean> => {
      const { start, success, error } = createLoader.actions;

      try {
        start();
        await createOrganization(data);
        success();
        closeModalAction();

        return true;
      } catch (err: unknown) {
        if (err instanceof Error) {
          error(err.message);
        } else {
          error('Something went wrong');
        }

        return false;
      }
    },
    []
  );

  const updateOrganizationAction = useCallback(
    async (id: string, name: string): Promise<boolean> => {
      const { start, success, error } = updateLoader.actions;

      try {
        start();
        await updateOrganization(id, name);
        success();
        closeModalAction();

        return true;
      } catch (err: unknown) {
        if (err instanceof Error) {
          error(err.message);
        } else {
          error('Something went wrong');
        }

        return false;
      }
    },
    []
  );

  const deleteOrganizationAction = useCallback(async (id: string): Promise<boolean> => {
    const { start, success, error } = deleteLoader.actions;

    try {
      start();
      await deleteOrganization(id);
      success();
      closeModalAction();

      return true;
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
      } else {
        error('Something went wrong');
      }

      return false;
    }
  }, []);

  return {
    isLoading,
    getError,
    openModal,
    isModalOpen,
    closeModal: closeModalAction,
    createOrganization: createOrganizationAction,
    updateOrganization: updateOrganizationAction,
    deleteOrganization: deleteOrganizationAction,
  };
};

export default useOrganizationActions;

import React, { useCallback } from 'react';
import LoadingWrapper from '@components/LoadingWrapper';
import PieChart, { PieChartSkeleton } from '@components/PieChart';
import percentage from '@utils/percentage';
import styles from './yearlyStats.module.scss';

interface YearlyStatsProps {
  loading: boolean;
  total: number;
  match: number;
  noMatch: number;
  error: number;
}

const YearlyStats = ({ loading, total, match, noMatch, error }: YearlyStatsProps) => {
  if (loading) {
    return (
      <LoadingWrapper ariaLabel="Loading yearly statistics">
        <PieChartSkeleton items={3} />
      </LoadingWrapper>
    );
  }

  const renderLegend = useCallback(
    (name: string, value: number) => (
      <React.Fragment>
        {name}: {value}
        <div className={styles.percentage}>{`(${percentage(value, total)}%)`}</div>
      </React.Fragment>
    ),
    []
  );

  return (
    <PieChart
      label="Total"
      text={total.toString()}
      renderLegend={renderLegend}
      empty={!total}
      data={[
        { name: 'Matches', value: match, color: 'green' },
        { name: 'No matches', value: noMatch, color: 'gray' },
        { name: 'Errors', value: error, color: 'red' },
      ]}
    />
  );
};

export default React.memo(YearlyStats);

import { OffsetDataFetchResolverOptions, OffsetDataFetchResolverResponse } from '@types';
import { USER_ROLE_MAP, USER_STATUS_MAP } from '@mantis/data';
import client from '@services/client';
import { gql } from '@services/gql';
import { User, FetchUserFilter } from '../types';

const FETCH_USERS = gql(/* GraphQL */ `
  query fetchUsers($fullText: String, $organization: ID, $sort: UserSort, $limit: Int, $skip: Int) {
    usersList(
      filter: { fullText: $fullText, organization: $organization }
      sort: $sort
      limit: $limit
      offset: $skip
    ) {
      count
      items {
        id
        createdAt
        name
        email
        status
        role
        recentActivity
        recentVolume
      }
    }
  }
`);

const fetchUsers = async (
  options: OffsetDataFetchResolverOptions<FetchUserFilter>
): Promise<OffsetDataFetchResolverResponse<User>> => {
  const response = await client.query({
    query: FETCH_USERS,
    variables: {
      fullText: options.fullText,
      organization: options.filterBy?.organization || null,
      limit: options.limit,
      skip: options.skip,
      sort: {
        [options.sortBy || 'createdAt']: options.order,
      },
    },
  });

  const count = response.data?.usersList.count || 0;
  const users = response.data?.usersList.items || [];
  const data = users.map((user) => ({
    id: user.id,
    createdAt: user.createdAt,
    name: user.name || undefined,
    email: user.email,
    status: USER_STATUS_MAP[user.status],
    role: USER_ROLE_MAP[user.role],
    recentActivity: user.recentActivity || undefined,
    recentVolume: user.recentVolume,
  }));

  return {
    count,
    data,
  };
};

export default fetchUsers;

import React, { useState, useEffect } from 'react';
import { Gender } from '@types';
import filterImages from '@utils/filterImages';
import GallerySkeleton from './GallerySkeleton';
import Gallery from './Gallery';

interface GalleryContainerProps {
  name: string;
  gender?: Gender;
  images: string[];
}

const GalleryContainer = ({ name, gender, images }: GalleryContainerProps) => {
  const [loading, setLoading] = useState(images.length > 0);
  const [filteredImages, setFilteredImages] = useState<string[]>([]);

  useEffect(() => {
    const filterImagesFn = async () => {
      const existingImages = await filterImages(images);

      if (existingImages.length > 0) {
        setFilteredImages(existingImages);
      }

      setLoading(false);
    };

    if (images.length > 0) {
      filterImagesFn();
    }
  }, [images]);

  if (loading) {
    return <GallerySkeleton />;
  }

  return <Gallery name={name} gender={gender} images={filteredImages} />;
};

export default GalleryContainer;

import React from 'react';
import { SkeletonText, Stack } from '@carbon/react';

const ProfileDataSkeleton = () => (
  <Stack gap={5}>
    <SkeletonText heading width="120px" />
    <div>
      <SkeletonText width="75px" />
      <SkeletonText width="150px" />
    </div>
    <div>
      <SkeletonText width="75px" />
      <SkeletonText width="170px" />
    </div>
    <div>
      <SkeletonText width="70px" />
      <SkeletonText width="100px" />
    </div>
    <div>
      <SkeletonText width="90px" />
      <SkeletonText width="110px" />
    </div>
  </Stack>
);

export default ProfileDataSkeleton;

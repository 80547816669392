import { Person } from '@mantis/types';

type ParsedProfile = Omit<Person, 'phone' | 'address'> & {
  phone: string;
  address: string;
};

const parseProfile = (person: Person) => {
  const profile: ParsedProfile = {
    ...person,
    phone: '',
    address: '',
  };

  if (profile.phones.length > 0) {
    profile.phone = profile.phones[0].value;
  }

  if (profile.addresses.length > 0) {
    profile.address = profile.addresses[0].value;
  }

  return profile;
};

export default parseProfile;

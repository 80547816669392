import React, { useState } from 'react';
import { FlexGrid, Row, Column } from '@carbon/react';
import { User } from '@types';
import useToast from '@hooks/useToast';
import useAuth from '@hooks/useAuth';
import CenteredContent from '@components/CenteredContent';
import Card from '@components/Card';
import spacing from '@styles/spacing.module.scss';
import ProfileData from './ProfileData';
import RecentActivities from './RecentActivities';
import Statistics from './Statistics';
import EditName from './EditName';
import updateName from './helpers/updateName';

interface ProfilePageProps {
  loading: boolean;
  loggedInUser: Nullable<User>;
}

const ProfilePage = ({ loading, loggedInUser }: ProfilePageProps) => {
  const toast = useToast();
  const { refetchMe } = useAuth();
  const [isOpen, setOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [hasError, setError] = useState(false);

  const handleOpen = () => {
    if (hasError) {
      setError(false);
    }

    setOpen(true);
  };

  const handleSubmit = async (newName: string) => {
    if (loggedInUser) {
      setUpdating(true);
      const success = await updateName(loggedInUser.id, newName);

      if (success) {
        await refetchMe();
        toast.success({ title: 'Update name', subtitle: 'Name was successfully updated' });
      }

      setError(!success);
      setUpdating(false);
      setOpen(false);
    }
  };

  return (
    <CenteredContent>
      <FlexGrid condensed>
        <Row>
          <Column sm={4} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 5 }}>
            <Card>
              <ProfileData
                loading={loading}
                name={loggedInUser?.name}
                email={loggedInUser?.email}
                userRole={loggedInUser?.role}
                organization={loggedInUser?.organization.name}
                onEdit={handleOpen}
              />
              {!loading && loggedInUser && (
                <EditName
                  isOpen={isOpen}
                  loading={updating}
                  name={loggedInUser.name || ''}
                  onClose={() => setOpen(false)}
                  onSubmit={handleSubmit}
                  errorMessage={hasError ? 'Something went wrong' : ''}
                />
              )}
            </Card>
            <Card className={spacing.mt5}>
              <RecentActivities loading={loading} userId={loggedInUser?.id || ''} />
            </Card>
            <Card className={spacing.mt5}>
              <Statistics loading={loading} />
            </Card>
          </Column>
        </Row>
      </FlexGrid>
    </CenteredContent>
  );
};

export default ProfilePage;

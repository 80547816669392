import { RetryLink } from '@apollo/client/link/retry';
// services
import logger from '@services/logger';

const retryLink = () =>
  new RetryLink({
    delay: {
      initial: 500,
      max: Infinity,
      jitter: true,
    },
    attempts: (count, operation, error) => {
      logger.info(`Retry graphql request ${count}`, {
        query: operation.operationName,
        variables: operation.variables,
      });

      return !!error && count < 3;
    },
  });

export default retryLink;

import React from 'react';
import classNames from 'classnames';
import styles from './group.module.scss';

interface GroupProps {
  title: string;
  children: React.ReactNode;
  condensed?: boolean;
}

const Group = ({ title, children, condensed = false }: GroupProps) => (
  <div className={styles.container} key={title}>
    <h4 className={classNames(styles.title, { [styles.condensed]: condensed })}>{title}</h4>
    {children}
  </div>
);

export default Group;

import React from 'react';
import { DateTime } from 'luxon';
import styles from './dateRange.module.scss';

interface DateRangeProps {
  start: string;
  end?: string;
}

const DateRange = ({ start, end }: DateRangeProps) => {
  const dateRange = (() => {
    if (!end) {
      const startDate = DateTime.fromISO(start);

      return `${startDate.toFormat('LLL yyyy')} - present`;
    }

    const startDate = DateTime.fromISO(start);
    const endDate = DateTime.fromISO(end);

    return `${startDate.toFormat('LLL yyyy')} - ${endDate.toFormat('LLL yyyy')}`;
  })();

  return <span className={styles.date}>{dateRange}</span>;
};

export default DateRange;

import client from '@services/client';
import { gql } from '@services/gql';
import { SelectOption } from '@types';

const FETCH_USERS = gql(/* GraphQL */ `
  query fetchUsersOptions {
    usersList {
      items {
        id
        email
      }
    }
  }
`);

const fetchUsers = async (): Promise<SelectOption[]> => {
  const response = await client.query({ query: FETCH_USERS });

  return response.data.usersList.items.map((user) => ({
    value: user.id,
    label: user.email,
  }));
};

export default fetchUsers;

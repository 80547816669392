import React, { useState, useEffect, useCallback, useMemo } from 'react';
import normalize from 'lodash/deburr';
import fuzzysort from 'fuzzysort';
import classNames from 'classnames';
import { ComboBox as CarbonComboBox, Layer, SelectSkeleton } from '@carbon/react';
import { SelectBoxChangeEvent, SelectOption } from '@types';
import LoadingWrapper from '@components/LoadingWrapper';
import styles from './comboBox.module.scss';

interface ComboBoxProps {
  id: string;
  title: string;
  hideLabel?: boolean;
  items: SelectOption[];
  placeholder?: string;
  selected?: string;
  loading?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const ComboBox = ({
  id,
  title,
  hideLabel = false,
  placeholder,
  selected,
  loading,
  items,
  disabled = false,
  onChange,
}: ComboBoxProps) => {
  const [filteredItems, setFilteredItems] = useState<SelectOption[]>(items);
  const [selectedItem, setSelectedItem] = useState<Nullable<SelectOption>>(
    items.find((item) => item.value === selected) || null
  );

  const itemsMap = useMemo(() => {
    const map: Record<string, SelectOption> = {};

    items.forEach((item) => {
      map[normalize(item.label)] = item;
    });

    return map;
  }, [items]);

  // use for fuzzy search
  const searchLabels = useMemo(
    () => items.map((item) => fuzzysort.prepare(normalize(item.label))),
    [items]
  );

  useEffect(() => {
    const foundItem = items.find((item) => item.value === selected);
    if (foundItem && foundItem !== selectedItem) {
      setSelectedItem(foundItem);
    }
  }, [items, selected, selectedItem]);

  const handleChange = useCallback(
    (item: SelectBoxChangeEvent<SelectOption>) => {
      onChange(item.selectedItem?.value || '');
    },
    [onChange]
  );

  const parseValue = useCallback((item: SelectOption) => item?.label || '', []);

  const handleInputChange = useCallback(
    (text: string) => {
      // if no search text or search text exactly matches an option label -> show all options
      if (!text || itemsMap[normalize(text)]) {
        setFilteredItems(items);

        return;
      }

      const results = fuzzysort.go(normalize(text), searchLabels, {
        threshold: -1000,
        limit: 50,
        all: true,
      });

      setFilteredItems(results.map((match) => itemsMap[match.target]));
    },
    [searchLabels, selectedItem]
  );

  if (loading) {
    return (
      <LoadingWrapper ariaLabel="Loading select box">
        <SelectSkeleton width="140px" hideLabel={hideLabel} />
      </LoadingWrapper>
    );
  }

  return (
    <Layer>
      <CarbonComboBox
        id={id}
        className={classNames(styles.container, { [styles.hiddenLabel]: hideLabel })}
        titleText={title}
        placeholder={placeholder}
        items={filteredItems}
        disabled={disabled}
        selectedItem={selectedItem || ''}
        itemToString={parseValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
      />
    </Layer>
  );
};

export default ComboBox;

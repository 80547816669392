import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { SearchResultContext } from '@types';
import SearchResult from './SearchResult';

const SearchResultsContainer = () => {
  const { searchParams, count, items, warnings, scrollIntoView } =
    useOutletContext<SearchResultContext>();

  return (
    <SearchResult
      searchParams={searchParams}
      count={count}
      items={items}
      warnings={warnings}
      scrollIntoView={scrollIntoView}
    />
  );
};

export default SearchResultsContainer;

import { AdvancedSearchParams } from '@mantis/types';
import { FormParams } from '../types';

const buildSearchParams = (params: FormParams): AdvancedSearchParams => {
  // trim values
  const { userId, userIdService, ...rest } = params;
  const fieldKeys = Object.keys(rest) as Array<keyof typeof rest>;
  const searchParams = fieldKeys.reduce((acc, key) => {
    acc[key] = (params[key] || '').trim();

    return acc;
  }, {} as AdvancedSearchParams);

  if (userId && userIdService) {
    searchParams.userId = `${userId.trim()}@${userIdService.trim()}`;
  } else {
    searchParams.userId = '';
  }

  return searchParams;
};

export default buildSearchParams;

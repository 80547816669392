import React from 'react';
import capitalize from 'lodash/capitalize';
import { ProgressBar, Layer } from '@carbon/react';
import { ArrowLeft } from '@carbon/icons-react';
import { DataTable, TableRow, TableCell, type TableHeader } from '@components/DataTable';
import Navigate from '@components/Navigate';
import ErrorMessage from '@components/ErrorMessage';
import { OverflowMenu, MenuItem } from '@components/OverflowMenu';
import { Contract, SortOption } from '@types';
import { formatDate } from '@utils/formatDate';
import formatNumber from '@utils/formatNumber';
import percentage from '@utils/percentage';
import baseStyles from '@styles/base.module.scss';
import spacing from '@styles/spacing.module.scss';
import { SortField } from '../types';

interface ContractsTableProps {
  loading: boolean;
  fetching: boolean;
  error: boolean;
  initialSort: SortOption<SortField>;
  data: Contract[];
  organization: string;
  onAdd: () => void;
  onEdit: (contract: Contract) => void;
  onRemove: (id: string) => void;
  onSortBy: (sort: Partial<SortOption<SortField>>) => void;
  onRefetch: () => void;
}

const headers: TableHeader[] = [
  {
    id: 'start',
    value: 'Start date',
    isSortable: true,
  },
  {
    id: 'end',
    value: 'End date',
    isSortable: true,
  },
  {
    id: 'count',
    value: 'Number of searches',
    isSortable: true,
  },
  {
    id: 'status',
    value: 'Status',
    isSortable: false,
  },
  {
    id: 'usage',
    value: 'Usage',
    isSortable: false,
  },
  {
    id: 'actions',
    value: 'Actions',
    isSortable: false,
  },
];

const ContractsTable = ({
  loading,
  fetching,
  error,
  initialSort,
  data,
  organization,
  onAdd,
  onEdit,
  onRemove,
  onRefetch,
  onSortBy,
}: ContractsTableProps) => {
  const renderTitle = () => (
    <div className={baseStyles.spaceBetween}>
      <span>Contracts</span>
      <Navigate icon={ArrowLeft} iconPosition="before" to="/organizations">
        back
      </Navigate>
    </div>
  );

  const handleEdit = (contract: Contract) => () => {
    onEdit(contract);
  };

  const handleDelete = (id: string) => () => {
    onRemove(id);
  };

  return (
    <React.Fragment>
      {error && (
        <div className={spacing.mb4}>
          <ErrorMessage>Something went wrong during data fetching</ErrorMessage>
        </div>
      )}
      <DataTable
        title={renderTitle()}
        description={`for ${organization} organization`}
        loading={loading}
        fetching={fetching}
        initialSort={initialSort}
        buttonText="Add"
        headers={headers}
        onButtonClick={onAdd}
        onSort={onSortBy}
        onRefresh={onRefetch}
      >
        {data.map((contract) => (
          <TableRow id={contract.id} key={contract.id}>
            <TableCell>{formatDate(contract.start)}</TableCell>
            <TableCell>{formatDate(contract.end)}</TableCell>
            <TableCell>{formatNumber(contract.count)}</TableCell>
            <TableCell>{capitalize(contract.status)}</TableCell>
            <TableCell>
              <Layer>
                <ProgressBar
                  label="Contract usage"
                  hideLabel
                  max={contract.count}
                  size="small"
                  value={contract.usage}
                  helperText={`${formatNumber(contract.usage)}/${formatNumber(
                    contract.count
                  )} (${percentage(contract.usage, contract.count)}%)`}
                />
              </Layer>
            </TableCell>
            <TableCell>
              <OverflowMenu ariaLabel="Actions">
                <MenuItem label="Edit contract" onClick={handleEdit(contract)} />
                <MenuItem
                  label="Delete contract"
                  onClick={handleDelete(contract.id)}
                  kind="danger"
                />
              </OverflowMenu>
            </TableCell>
          </TableRow>
        ))}
      </DataTable>
    </React.Fragment>
  );
};

export default ContractsTable;

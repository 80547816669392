import React from 'react';
import { Collaborate } from '@carbon/icons-react';
import { Person, PersonName, PersonRelationship } from '@mantis/types';
import ClickableRecord from '../components/ClickableRecord';
import { Tag } from '../components/RecordTags';
import Section from '../components/Section';
import Records from '../components/Records';
import Group from '../components/Group';

interface RelationshipGroup {
  title: string;
  items: PersonRelationship[];
}

const GROUP_NAME_MAP = {
  friend: 'Friends',
  family: 'Family',
  work: 'Work',
  other: 'Others',
} as const;

const Relationships = ({ relationships }: Pick<Person, 'relationships'>) => {
  const groupRelationships = () => {
    const groups: RelationshipGroup[] = [];

    relationships.forEach((relationship) => {
      const group = groups.find((item) => item.title === GROUP_NAME_MAP[relationship.type]);

      if (group) {
        group.items.push(relationship);
      } else {
        groups.push({ title: GROUP_NAME_MAP[relationship.type], items: [relationship] });
      }
    });

    return groups;
  };

  const getSearchLink = (name: PersonName) => {
    const searchParams = new URLSearchParams({
      firstName: name.first,
      lastName: name.last,
      middleName: name.middle || '',
    });

    return `/search/result?${searchParams.toString()}`;
  };

  const getTags = (name: PersonName) => {
    const tags: Tag[] = [];

    if (name.type) {
      tags.push({ value: name.type, type: 'gray' });
    }

    return tags;
  };

  if (relationships.length === 0) {
    return <span>No relationships were found</span>;
  }

  const renderRecords = (items: PersonRelationship[]) => (
    <Records>
      {items.map((relationship) =>
        relationship.names.map((name) => (
          <div key={name.value}>
            {relationship.subtype}
            <ClickableRecord
              link={getSearchLink(name)}
              metadata={name.metadata}
              tags={getTags(name)}
            >
              {name.value}
            </ClickableRecord>
          </div>
        ))
      )}
    </Records>
  );

  const renderContent = () => {
    const groups = groupRelationships();

    if (groups.length === 1 && groups[0].title === 'Others') {
      return renderRecords(groups[0].items);
    }

    return groupRelationships().map((group) => (
      <Group title={group.title} key={group.title}>
        {renderRecords(group.items)}
      </Group>
    ));
  };

  return (
    <Section icon={<Collaborate size={20} />} title="Relationships">
      {renderContent()}
    </Section>
  );
};

export default Relationships;

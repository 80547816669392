import React from 'react';
import useTypedParams from '@hooks/useTypedParams';
import ContractsList from './ContractsList';

interface ContractsPageProps {
  loading: boolean;
}

const ContractsPage = ({ loading }: ContractsPageProps) => {
  const { id } = useTypedParams(['id']);

  return <ContractsList loading={loading} organizationId={id} />;
};

export default ContractsPage;

import React, { useState, useEffect } from 'react';
import ErrorMessage from '@components/ErrorMessage';
import { MonthlyStats, YearlyStats } from '@components/Statistics';
import Filters from './Filters';
import Heading from '../Heading';
import fetchStatistics from '../helpers/fetchOrganizationStatistics';
import { StatisticsData, OrganizationStatisticsFilter, UserOrganization } from '../types';
import styles from './organizationStatistics.module.scss';

interface OrganizationStatisticsProps {
  isOpen: boolean;
  loading: boolean;
  isAdmin: boolean;
  userOrganization: UserOrganization;
}

const OrganizationStatistics = ({
  isOpen,
  loading,
  isAdmin,
  userOrganization,
}: OrganizationStatisticsProps) => {
  const [data, setData] = useState<Nullable<StatisticsData>>(null);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);

  const fetchStats = async (filter: OrganizationStatisticsFilter) => {
    try {
      setFetching(true);

      const fetchedData = await fetchStatistics(filter);
      setData(fetchedData);
    } catch (err) {
      setError(true);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (!loading && userOrganization.id) {
      const currentYear = new Date().getFullYear();
      fetchStats({ organization: userOrganization.id, year: currentYear });
    }
  }, [userOrganization.id, loading]);

  const handleFilter = (filter: OrganizationStatisticsFilter) => {
    if (filter.organization) {
      fetchStats(filter);
    }
  };

  return (
    <React.Fragment>
      <Filters
        isAdmin={isAdmin}
        loading={loading}
        userOrganization={userOrganization}
        onChange={handleFilter}
      />
      {error && (
        <div className={styles.error}>
          <ErrorMessage>Something went wrong during fetching statistics</ErrorMessage>
        </div>
      )}
      {isOpen && (
        <React.Fragment>
          <div className={styles.yearlyStats}>
            <Heading loading={loading || fetching}>Yearly statistics</Heading>
            <YearlyStats
              loading={loading || fetching}
              total={data?.yearly.statistics.total || 0}
              match={data?.yearly.statistics.match || 0}
              noMatch={data?.yearly.statistics.noMatch || 0}
              error={data?.yearly.statistics.error || 0}
            />
          </div>
          <div className={styles.monthlyStats}>
            <Heading loading={loading || fetching}>Monthly statistics</Heading>
            <MonthlyStats loading={loading || fetching} statistics={data?.monthly || []} />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OrganizationStatistics;

import React from 'react';
import Tooltip from '@components/Tooltip';
import spacing from '@styles/spacing.module.scss';
import baseStyles from '@styles/base.module.scss';

const RecentVolumeHeader = () => (
  <div className={baseStyles.verticalCenter}>
    <span className={spacing.mr2}>Recent volume</span>
    <Tooltip align="bottom-right" noBorder noInteractive>
      Number of requests in last 30 days
    </Tooltip>
  </div>
);

export default RecentVolumeHeader;

import React from 'react';
import classNames from 'classnames';
import styles from './records.module.scss';

interface RecordsProps {
  children: React.ReactNode;
  condensed?: boolean;
}

const Records = ({ children, condensed = false }: RecordsProps) => (
  <React.Fragment>
    {React.Children.map(children, (record, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={classNames({
          [styles.record]: index,
          [styles.condensed]: index && condensed,
        })}
      >
        {record}
      </div>
    ))}
  </React.Fragment>
);

export default Records;

import React, { useState, useEffect, useCallback } from 'react';
import { Stack } from '@carbon/react';
import { Contract as ContractType } from '@types';
import ErrorMessage from '@components/ErrorMessage';
import LoadingWrapper from '@components/LoadingWrapper';
import ContractsSkeleton from './ContractSkeleton';
import Contract from './Contract';
import Filter from './Filter';
import fetchContracts from './helpers/fetchContracts';
import styles from './contracts.module.scss';

interface ContractsListProps {
  loading: boolean;
  isAdmin: boolean;
  organizationId: string;
}

const ContractsList = ({ loading, isAdmin, organizationId }: ContractsListProps) => {
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [contracts, setContracts] = useState<ContractType[]>([]);

  const fetchContractsFn = useCallback(async (id: string) => {
    try {
      setError(false);
      setFetching(true);
      const fetchedContracts = await fetchContracts(id);
      setContracts(fetchedContracts);
    } catch (err) {
      setError(true);
    } finally {
      setFetching(false);
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      fetchContractsFn(organizationId);
    }
  }, [loading]);

  return (
    <React.Fragment>
      {isAdmin && (
        <div className={styles.filter}>
          <Filter loading={loading} organizationId={organizationId} onChange={fetchContractsFn} />
        </div>
      )}
      {loading || fetching ? (
        <LoadingWrapper ariaLabel="Loading contracts..">
          <ContractsSkeleton />
        </LoadingWrapper>
      ) : (
        <Stack gap={6}>
          {error && <ErrorMessage>Something went wrong during fetching contracts</ErrorMessage>}
          {contracts.map((contract) => (
            <Contract
              key={contract.id}
              start={contract.start}
              end={contract.end}
              status={contract.status}
              count={contract.count}
              usage={contract.usage}
            />
          ))}
        </Stack>
      )}
    </React.Fragment>
  );
};

export default ContractsList;

import React from 'react';
import { PersonFieldMetadata } from '@mantis/types';
import useBreakpoint from '@hooks/useBreakpoint';
import spacing from '@styles/spacing.module.scss';
import baseStyles from '@styles/base.module.scss';
import DateRange from '@components/DateRange';
import MetadataInfo from '../../components/MetadataInfo';
import RecordTags from '../../components/RecordTags';
import styles from './education.module.scss';

interface EducationProps {
  school: string;
  degree?: string;
  start?: string;
  end?: string;
  metadata: PersonFieldMetadata;
}

const Education = ({ school, degree, start, end, metadata }: EducationProps) => {
  const { breakpointDown } = useBreakpoint();

  if (breakpointDown('sm')) {
    return (
      <div className={baseStyles.dFlexColumn}>
        <div className={baseStyles.spaceBetween}>
          {degree ? `${degree}, ${school}` : school}
          {(metadata.validSince || metadata.lastSeen) && (
            <div className={spacing.ml3}>
              <MetadataInfo metadata={metadata} />
            </div>
          )}
        </div>
        {start && (
          <span className={spacing.mt2}>
            <DateRange start={start} end={end} />
          </span>
        )}
        <RecordTags metadata={metadata} className={spacing.mt2} />
      </div>
    );
  }

  return (
    <div className={baseStyles.dFlex}>
      {degree ? `${degree}, ${school}` : school}
      {start && (
        <span className={styles.dateWrapper}>
          <DateRange start={start} end={end} />
        </span>
      )}
      <RecordTags metadata={metadata} className={spacing.ml3} />
      {(metadata.validSince || metadata.lastSeen) && (
        <div className={spacing.ml3}>
          <MetadataInfo metadata={metadata} />
        </div>
      )}
    </div>
  );
};

export default Education;

import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Person } from '@mantis/types';
import { Accordion as AccordionContainer, AccordionItem, AccordionSkeleton } from '@carbon/react';
import Profile from '../Tabs/Profile';
import Locations from '../Tabs/Locations';
import DigitalFootprint from '../Tabs/DigitalFootprint';
import Relationships from '../Tabs/Relationships';
import Others from '../Tabs/Others';
import TABS from '../tabs';
import styles from './accordion.module.scss';

interface AccordionProps {
  person: Nullable<Person>;
  loading: boolean;
}

interface AccordionClickEvent {
  isOpen: boolean;
}

const Accordion = ({ person, loading }: AccordionProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isTabInitiallyOpen = useCallback((title: string) => searchParams.get('tab') === title, []);

  const isTabOpen = useCallback(
    (title: string) => searchParams.get('tab') === title,
    [searchParams]
  );

  const handleItemClick =
    (tab: ValueOf<typeof TABS>) =>
    ({ isOpen }: AccordionClickEvent) => {
      if (!isOpen) {
        searchParams.delete('tab');
        setSearchParams(searchParams);
      } else {
        setSearchParams({ tab: isOpen ? tab : '' });
      }
    };

  if (loading) {
    return <AccordionSkeleton count={5} open={false} size="lg" isFlush />;
  }

  if (!person) {
    return null;
  }

  return (
    <AccordionContainer isFlush size="lg">
      <AccordionItem
        open={isTabInitiallyOpen(TABS.PROFILE)}
        onHeadingClick={handleItemClick(TABS.PROFILE)}
        className={styles.item}
        title={<h3 className={styles.heading}>Profile</h3>}
      >
        <Profile
          names={person.names}
          emails={person.emails}
          phones={person.phones}
          educations={person.educations}
          jobs={person.jobs}
        />
      </AccordionItem>
      <AccordionItem
        open={isTabInitiallyOpen(TABS.LOCATIONS)}
        onHeadingClick={handleItemClick(TABS.LOCATIONS)}
        className={styles.item}
        title={<h3 className={styles.heading}>Locations</h3>}
      >
        <Locations isOpen={isTabOpen(TABS.LOCATIONS)} addresses={person.addresses} />
      </AccordionItem>
      <AccordionItem
        open={isTabInitiallyOpen(TABS.DIGITAL_FOOTPRINT)}
        onHeadingClick={handleItemClick(TABS.DIGITAL_FOOTPRINT)}
        className={styles.item}
        title={<h3 className={styles.heading}>Digital footprint</h3>}
      >
        <DigitalFootprint urls={person.urls} userIds={person.userIds} />
      </AccordionItem>
      <AccordionItem
        open={isTabInitiallyOpen(TABS.RELATIONS)}
        onHeadingClick={handleItemClick(TABS.RELATIONS)}
        className={styles.item}
        title={<h3 className={styles.heading}>Relations</h3>}
      >
        <Relationships relationships={person.relationships} />
      </AccordionItem>
      <AccordionItem
        open={isTabInitiallyOpen(TABS.OTHERS)}
        onHeadingClick={handleItemClick(TABS.OTHERS)}
        className={styles.item}
        title={<h3 className={styles.heading}>Others</h3>}
      >
        <Others tags={person.tags} vehicles={person.vehicles} />
      </AccordionItem>
    </AccordionContainer>
  );
};

export default Accordion;

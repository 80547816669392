import React from 'react';
import pluralize from 'pluralize';
import classNames from 'classnames';
import { Match } from '@mantis/types';
import Title from './Title';
import styles from './profileInfo.module.scss';

type ProfileData = Pick<
  Match,
  'name' | 'gender' | 'age' | 'dateOfBirth' | 'dateOfBirthRange' | 'address' | 'phone'
> & {
  languages?: string[];
  originCountries?: string[];
};

interface ProfileInfoProps {
  profile: ProfileData;
  condensed?: boolean;
  header?: React.ReactNode;
}

const ProfileInfo = ({ profile, condensed, header }: ProfileInfoProps) => {
  const renderHeader = () => {
    if (header) return header;
    if (profile.name) return <Title>{profile.name}</Title>;

    return null;
  };

  const renderRecord = (field: string, value: string) => (
    <React.Fragment>
      <div className={styles.field}>{field}</div>
      <div>{value}</div>
    </React.Fragment>
  );

  const renderDateOfBirth = (field: string, dateOfBirth: string, dateOfBirthRange?: string[]) => (
    <React.Fragment>
      <div className={styles.field}>{field}</div>
      <div>
        {!dateOfBirthRange ? dateOfBirth : `${dateOfBirthRange[0]} - ${dateOfBirthRange[1]}`}
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {renderHeader()}
      <div className={classNames(styles.grid, { [styles.condensed]: condensed })}>
        {profile.gender && renderRecord('Gender', profile.gender)}
        {profile.age && renderRecord('Age', profile.age)}
        {profile.dateOfBirth &&
          renderDateOfBirth('Date of birth', profile.dateOfBirth, profile.dateOfBirthRange)}
        {profile.phone && renderRecord('Phone', profile.phone)}
        {profile.languages &&
          profile.languages?.length > 0 &&
          renderRecord(
            pluralize('Language', profile.languages.length),
            profile.languages.join(', ')
          )}
        {profile.originCountries &&
          profile.originCountries.length > 0 &&
          renderRecord(
            pluralize('Origin country', profile.originCountries.length),
            profile.originCountries.join(', ')
          )}
        {profile.address && renderRecord('Possible location', profile.address)}
      </div>
    </React.Fragment>
  );
};

export default ProfileInfo;

import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { createSearchParams, useSearchParams, useNavigate, Outlet } from 'react-router-dom';
import classNames from 'classnames';
import { FlexGrid, Row, Column, Tabs, TabList, Tab, TabPanels, TabPanel } from '@carbon/react';
import CenteredContent from '@components/CenteredContent';
import Card from '@components/Card';
import { AdvancedSearchParams, BasicSearchParams, SearchParams } from '@mantis/types';
import { SearchResultContext, SearchResponse } from '@types';
import spacing from '@styles/spacing.module.scss';
import SearchSkeleton from './SearchPageSkeleton';
import BasicSearchForm from './BasicSearchForm';
import AdvancedSearchForm from './AdvancedSearchForm';
import fetchMyLocation from './helpers/fetchMyLocation';
import fetchResult from './helpers/fetchResult';

interface SearchPageProps {
  loading: boolean;
}

const SearchPage = ({ loading }: SearchPageProps) => {
  const navigate = useNavigate();
  const resultElement = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [result, setResult] = useState<SearchResponse | null>(null);

  const parsedParams = Object.fromEntries(searchParams) as SearchParams;
  const searchParamsString = searchParams.toString();

  useEffect(() => {
    if (!loading && resultElement.current) {
      resultElement.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading]);

  useEffect(() => {
    const fetchResultFn = async () => {
      try {
        setSubmitting(true);
        setError('');
        setResult(null);

        const searchResult = await fetchResult(parsedParams);
        setResult(searchResult);
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('Something went wrong');
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });
      } finally {
        setSubmitting(false);
      }
    };

    if (searchParamsString) {
      fetchResultFn();
    }
  }, [searchParamsString]);

  const handleSearch = useCallback((params: SearchParams) => {
    // remove empty params
    const paramKeys = Object.keys(params) as Array<keyof SearchParams>;
    const filledParams = paramKeys.reduce((acc, key) => {
      if (params[key]) {
        acc[key] = params[key];
      }

      return acc;
    }, {} as SearchParams);

    navigate({
      pathname: 'result',
      search: createSearchParams(filledParams).toString(),
    });
  }, []);

  const defaultSelectedIndex = useMemo((): number => {
    if (
      ['lastName', 'email', 'phone', 'username', 'userId'].some((field) => searchParams.has(field))
    ) {
      return 1;
    }

    return 0;
  }, [searchParamsString]);

  const initialBasicSearchParams = useMemo((): BasicSearchParams => {
    if (searchParamsString && 'query' in parsedParams) {
      return parsedParams;
    }

    return { query: '' };
  }, [searchParamsString]);

  const initialAdvancedSearchParams = useMemo((): AdvancedSearchParams => {
    if (searchParamsString && !('query' in parsedParams)) {
      return parsedParams;
    }

    return {};
  }, [searchParamsString]);

  const searchResultContext = useMemo((): SearchResultContext => {
    const context: SearchResultContext = {
      searchParams: parsedParams,
      count: result?.count || 0,
      items: result?.items || [],
      warnings: result?.warnings || [],
      scrollIntoView: !!searchParamsString,
    };

    return context;
  }, [searchParamsString, result]);

  return (
    <CenteredContent>
      <FlexGrid condensed>
        <Row>
          <Column
            sm={4}
            md={8}
            lg={{ span: 12, offset: 2 }}
            xlg={{ span: 10, offset: 3 }}
            max={{ span: 8, offset: 4 }}
          >
            <Card>
              {loading ? (
                <SearchSkeleton />
              ) : (
                <Tabs defaultSelectedIndex={defaultSelectedIndex}>
                  <TabList aria-label="Search types" activation="manual">
                    <Tab>Search</Tab>
                    <Tab>Advanced search</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel className={classNames(spacing.p0, spacing.mt6)}>
                      <BasicSearchForm
                        key={searchParamsString}
                        onSubmit={handleSearch}
                        onUseMyLocation={fetchMyLocation}
                        initialValues={initialBasicSearchParams}
                        loading={submitting}
                        errorMessage={error}
                      />
                    </TabPanel>
                    <TabPanel className={classNames(spacing.p0, spacing.mt6)}>
                      <AdvancedSearchForm
                        onSubmit={handleSearch}
                        initialValues={initialAdvancedSearchParams}
                        loading={submitting}
                        errorMessage={error}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              )}
            </Card>
            <div ref={resultElement}>
              {result && !submitting && <Outlet context={searchResultContext} />}
            </div>
          </Column>
        </Row>
      </FlexGrid>
    </CenteredContent>
  );
};

export default SearchPage;

import { OffsetDataFetchResolverOptions, OffsetDataFetchResolverResponse } from '@types';
import client from '@services/client';
import { gql } from '@services/gql';
import { Organization } from '../../types';

const FETCH_ORGANIZATIONS = gql(/* GraphQL */ `
  query fetchOrganizations($fullText: String, $sort: OrganizationSort, $limit: Int, $skip: Int) {
    organizationsList(filter: { fullText: $fullText }, sort: $sort, limit: $limit, offset: $skip) {
      count
      items {
        id
        createdAt
        name
        recentVolume
        currentContract {
          id
          start
          end
          count
          usage
        }
        users {
          count
        }
      }
    }
  }
`);

const fetchOrganizations = async (
  options: OffsetDataFetchResolverOptions
): Promise<OffsetDataFetchResolverResponse<Organization>> => {
  const response = await client.query({
    query: FETCH_ORGANIZATIONS,
    variables: {
      fullText: options.fullText,
      limit: options.limit,
      skip: options.skip,
      sort: {
        [options.sortBy || 'createdAt']: options.order,
      },
    },
  });

  const count = response.data?.organizationsList?.count || 0;
  const organizations = response.data?.organizationsList?.items || [];
  const data = organizations.map((organization) => ({
    id: organization.id,
    createdAt: organization.createdAt,
    name: organization.name,
    recentVolume: organization.recentVolume,
    numberOfUsers: organization.users.count || 0,
    contracts: [],
    currentContract: organization.currentContract
      ? {
          ...organization.currentContract,
          status: 'active' as const,
        }
      : null,
  }));

  return {
    count,
    data,
  };
};

export default fetchOrganizations;

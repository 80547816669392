import client from '@services/client';
import { gql } from '@services/gql';

const UPDATE_NAME = gql(/* GraphQL */ `
  mutation updateName($id: ID!, $name: String!) {
    userUpdate(id: $id, data: { name: $name }) {
      id
      name
    }
  }
`);

const updateName = async (id: string, value: string): Promise<boolean> => {
  try {
    await client.mutate({ mutation: UPDATE_NAME, variables: { id, name: value } });

    return true;
  } catch (error) {
    return false;
  }
};

export default updateName;

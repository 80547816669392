import { useRef, useEffect } from 'react';

const useOnClickOutside = <T extends HTMLElement>(
  handler: (event?: MouseEvent | TouchEvent) => void
) => {
  const handlerRef = useRef<typeof handler>();
  const ref = useRef<T>(null);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // do nothing if clicking ref's element or descendent elements
      if (
        !ref.current ||
        !(event.target instanceof HTMLElement) ||
        ref.current.contains(event.target) ||
        !handlerRef.current
      ) {
        return;
      }

      handlerRef.current(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, []);

  return ref;
};

export default useOnClickOutside;

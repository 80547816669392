import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@carbon/react';
import { Person } from '@mantis/types';
import { TabChangeEvent } from '@types';
import DetailTabsSkeleton from './DetailTabsSkeleton';
import Profile from '../Tabs/Profile';
import Locations from '../Tabs/Locations';
import DigitalFootprint from '../Tabs/DigitalFootprint';
import Relationships from '../Tabs/Relationships';
import Others from '../Tabs/Others';
import TABS from '../tabs';
import styles from './detailTabs.module.scss';

interface DetailTabsProps {
  person: Nullable<Person>;
  loading: boolean;
}

const DetailTabs = ({ loading, person }: DetailTabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getActiveTabIndex = () => {
    if (searchParams.get('tab')) {
      const selectedTabIndex = Object.values(TABS).findIndex(
        (tab) => tab === searchParams.get('tab')
      );

      if (selectedTabIndex > -1) {
        return selectedTabIndex;
      }
    }

    return 0;
  };

  const handleTabChange = ({ selectedIndex }: TabChangeEvent) => {
    setSearchParams({ tab: Object.values(TABS)[selectedIndex] });
  };

  if (loading) {
    return <DetailTabsSkeleton />;
  }

  if (!person) {
    return null;
  }

  return (
    <Tabs selectedIndex={getActiveTabIndex()} onChange={handleTabChange}>
      <TabList aria-label="Person details" activation="manual" className={styles.tabList}>
        <Tab className={styles.tab}>Profile</Tab>
        <Tab className={styles.tab}>Locations</Tab>
        <Tab className={styles.tab}>Digital Footprint</Tab>
        <Tab className={styles.tab}>Relations</Tab>
        <Tab className={styles.tab}>Others</Tab>
      </TabList>
      <TabPanels>
        <TabPanel className={styles.tabPanel}>
          <Profile
            names={person.names}
            emails={person.emails}
            phones={person.phones}
            educations={person.educations}
            jobs={person.jobs}
          />
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <Locations isOpen={getActiveTabIndex() === 1} addresses={person.addresses} />
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <DigitalFootprint urls={person.urls} userIds={person.userIds} />
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <Relationships relationships={person.relationships} />
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <Others tags={person.tags} vehicles={person.vehicles} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default DetailTabs;

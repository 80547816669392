import React from 'react';
import classNames from 'classnames';
import styles from './section.module.scss';

interface SectionProps {
  title: string;
  icon: React.ReactElement;
  children: React.ReactNode;
  className?: string;
}

const Section = ({ title, icon, children, className }: SectionProps) => (
  <div className={classNames(styles.container, className)}>
    <h3 className={styles.title}>
      <span className={styles.icon}>{icon}</span>
      {title}
    </h3>
    <div className={styles.body}>{children}</div>
  </div>
);

export default Section;

/**
 * Return human readable formatted number. 10000 -> 10 000
 * @param {number} number
 * @returns {number}
 */
const formatNumber = (number: number) => {
  if (number.toString().length === 4) {
    return number.toString();
  }

  return number.toLocaleString('en-US').replaceAll(',', ' ');
};

export default formatNumber;

import client from '@services/client';
import { gql } from '@services/gql';
import logger from '@services/logger';
import { OrganizationStatisticsFilter, StatisticsData } from '../types';

gql(/* GraphQL */ `
  fragment Statistics on Statistics {
    total
    match
    noMatch
    error
  }
`);

const FETCH_STATISTICS = gql(/* GraphQL */ `
  query fetchOrganizationStatistics($organization: ID, $year: Int!) {
    organizationStatistics(organization: $organization, year: $year) {
      yearly {
        year
        statistics {
          ...Statistics
        }
      }
      monthly {
        month
        statistics {
          ...Statistics
        }
      }
    }
  }
`);

const fetchStatistics = async (
  filter: OrganizationStatisticsFilter
): Promise<Nullable<StatisticsData>> => {
  try {
    const response = await client.query({
      query: FETCH_STATISTICS,
      variables: { organization: filter.organization, year: filter.year },
    });

    return response.data.organizationStatistics || null;
  } catch (error: unknown) {
    if (error instanceof Error) {
      logger.error(error.message, { filter });
    }

    throw error;
  }
};

export default fetchStatistics;

import client from '@services/client';
import { gql } from '@services/gql';
import { DateTime } from 'luxon';
import { UserSelectOption } from '../../types';

const FETCH_USERS = gql(/* GraphQL */ `
  query fetchUsersForStats {
    usersList {
      items {
        id
        name
        email
        createdAt
        organization {
          id
          name
        }
      }
    }
  }
`);

const fetchUsers = async (): Promise<UserSelectOption[]> => {
  const response = await client.query({ query: FETCH_USERS });

  return response.data.usersList.items.map((user) => ({
    value: user.id,
    label: `${user.name || user.email} (${user.organization.name})`,
    organization: user.organization.id,
    createdYear: DateTime.fromISO(user.createdAt).year,
  }));
};

export default fetchUsers;

import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import errorType from '@mantis/errors';
import logger from '@services/logger';
import { refreshTokens } from '@services/auth';
import loggedInUserVar from '@store/loggedInUser';

const errorLink = () =>
  // eslint-disable-next-line consistent-return
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      // if user is not authenticated -> try to refresh token or redirect to login page
      if (graphQLErrors.some((error) => error?.extensions?.code === errorType.UNAUTHENTICATED)) {
        return fromPromise(refreshTokens().then((success) => success))
          .filter((success) => {
            if (!success) {
              loggedInUserVar(null);
            }

            return success;
          })
          .flatMap(() => forward(operation));
      }

      graphQLErrors.forEach((error) => {
        if (error?.extensions?.code !== errorType.SEARCH_INCORRECT_PARAMS) {
          logger.error(`[Graphql error]: ${error.message}`, {
            code: error?.extensions?.code || '',
            exception: error?.extensions?.exception || '',
            operationName: operation.operationName,
            variables: operation.variables,
          });
        }
      });
    }

    if (networkError) {
      logger.error(`[Network error]: ${networkError}`, {
        operationName: operation?.operationName,
        variables: operation?.variables,
      });
    }
  });

export default errorLink;

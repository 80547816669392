import React from 'react';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import { SortOption, OffsetPaginationData, UserRole } from '@types';
import Navigate from '@components/Navigate';
import ErrorMessage from '@components/ErrorMessage';
import { OverflowMenu, MenuItem } from '@components/OverflowMenu';
import { DataTable, TableRow, TableCell, type TableHeader } from '@components/DataTable';
import { formatDateTime } from '@utils/formatDate';
import spacing from '@styles/spacing.module.scss';
import RecentVolumeHeader from './RecentVolumeHeader';
import { SortField, User } from './types';

interface UsersTableProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  loading: boolean;
  fetching: boolean;
  initialSort: SortOption<SortField>;
  data: User[];
  error: boolean;
  count: number;
  page: number;
  loggedInUserRole: UserRole;
  onAdd: () => void;
  onSearchByText: (text: string) => void;
  onSortBy: (sort: Partial<SortOption<SortField>>) => void;
  onPaginate: (data: OffsetPaginationData) => void;
  onRefetch: () => void;
  onResendNotification: (id: string) => void;
  onEdit: (user: User) => void;
  onRemove: (user: User) => void;
}

const headers: TableHeader[] = [
  {
    id: 'name',
    value: 'Name',
    isSortable: true,
  },
  {
    id: 'email',
    value: 'Email',
    isSortable: true,
  },
  {
    id: 'createdAt',
    value: 'Created',
    isSortable: true,
  },
  {
    id: 'role',
    value: 'Role',
    isSortable: true,
  },
  {
    id: 'status',
    value: 'Status',
    isSortable: true,
  },
  {
    id: 'recentActivity',
    value: 'Recent activity',
    isSortable: true,
  },
  {
    id: 'recentVolume',
    value: <RecentVolumeHeader />,
    isSortable: true,
  },
  {
    id: 'actions',
    value: 'Actions',
  },
];

const UsersTable = ({
  title,
  loading,
  fetching,
  initialSort,
  page,
  count,
  data,
  error,
  description,
  loggedInUserRole,
  onAdd,
  onSearchByText,
  onSortBy,
  onPaginate,
  onRefetch,
  onResendNotification,
  onRemove,
  onEdit,
}: UsersTableProps) => {
  const formatRole = (role: UserRole) => {
    if (role === 'admin' || role === 'user') {
      return capitalize(role);
    }

    // Org admin role
    return loggedInUserRole === 'admin' ? startCase(role) : 'Admin';
  };

  const renderRecentActivity = (user: User) => {
    if (!user.recentActivity) {
      return '-';
    }

    if (loggedInUserRole === 'admin') {
      return formatDateTime(user.recentActivity);
    }

    return (
      <Navigate to={`/history?user=${user.id}`}>{formatDateTime(user.recentActivity)}</Navigate>
    );
  };

  const handleResendNotification = (id: string) => () => {
    onResendNotification(id);
  };

  const handleEdit = (user: User) => () => {
    onEdit(user);
  };

  const handleRemove = (user: User) => () => {
    onRemove(user);
  };

  return (
    <React.Fragment>
      {error && (
        <div className={spacing.mb4}>
          <ErrorMessage>Something went wrong during data fetching</ErrorMessage>
        </div>
      )}
      <DataTable
        title={title}
        description={description}
        isSearchable
        loading={loading}
        fetching={fetching}
        onSearch={onSearchByText}
        searchPlaceholder="Search by name or email"
        buttonText="Add"
        headers={headers}
        initialSort={initialSort}
        totalItems={count}
        page={page}
        onButtonClick={onAdd}
        onSort={onSortBy}
        onNavigation={onPaginate}
        onRefresh={onRefetch}
      >
        {data.map((user) => (
          <TableRow id={user.id} key={user.id}>
            <TableCell>{user.name || '-'}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{formatDateTime(user.createdAt)}</TableCell>
            <TableCell>{formatRole(user.role)}</TableCell>
            <TableCell>{startCase(user.status)}</TableCell>
            <TableCell>{renderRecentActivity(user)}</TableCell>
            <TableCell>{user.recentVolume || '-'}</TableCell>
            <TableCell>
              <OverflowMenu ariaLabel="Actions">
                {user.status === 'pending' && (
                  <MenuItem
                    label="Resend notification"
                    onClick={handleResendNotification(user.id)}
                  />
                )}
                <MenuItem label="Edit user" onClick={handleEdit(user)} />
                <MenuItem kind="danger" label="Delete user" onClick={handleRemove(user)} />
              </OverflowMenu>
            </TableCell>
          </TableRow>
        ))}
      </DataTable>
    </React.Fragment>
  );
};

export default UsersTable;

import React from 'react';
import Item from './Item';
import styles from './list.module.scss';

export interface ListProps {
  children: React.ReactNode;
}

const List = ({ children }: ListProps) => <ul className={styles.container}>{children}</ul>;

List.Item = Item;

export default List;

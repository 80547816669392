import React from 'react';
import classNames from 'classnames';
import styles from './linkButton.module.scss';

export interface LinkButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
}

const LinkButton = ({ children, onClick, className }: LinkButtonProps) => {
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <span
      role="button"
      className={classNames('cds--link', styles.link, className)}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyPress}
    >
      {children}
    </span>
  );
};

export default LinkButton;

import React from 'react';
// components
import { FlexGrid, Row, Column } from '@carbon/react';
import CenteredContent from '@components/CenteredContent';
import Card from '@components/Card';

interface ContainerProps {
  children: React.ReactNode;
}

const Container = ({ children }: ContainerProps) => (
  <CenteredContent>
    <FlexGrid condensed>
      <Row>
        <Column sm={4} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 5 }}>
          <Card>{children}</Card>
        </Column>
      </Row>
    </FlexGrid>
  </CenteredContent>
);

export default Container;

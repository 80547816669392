import React from 'react';
import isString from 'lodash/isString';
import spacing from '@styles/spacing.module.scss';

interface RecordProps {
  title: string | React.ReactElement;
  children: React.ReactNode;
}

const Record = ({ title, children }: RecordProps) => (
  <div>
    <div className={spacing.mb1}>{isString(title) ? <b>{title}</b> : title}</div>
    {children}
  </div>
);

export default Record;

import logger from '@logdna/browser';
import config from '@config';

logger.init(config.logger.token, {
  url: config.logger.endpoint,
  app: 'Mantis client',
  tags: ['mantis', 'client'],
  disabled: config.isDev,
  console: {
    enable: !config.isDev,
    integrations: ['warn', 'error'],
  },
});

export default logger;

import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Modal as CarbonModal, Loading } from '@carbon/react';
import baseStyles from '@styles/base.module.scss';
import spacing from '@styles/spacing.module.scss';
import styles from './modal.module.scss';

interface ModalProps {
  isOpen: boolean;
  loading?: boolean;
  loadingText?: string;
  visibleOverflow?: boolean;
  children: React.ReactNode;
  onClose: () => void;
  onSubmit?: () => void;
  label?: React.ReactNode;
  heading?: React.ReactNode;
  passiveModal?: boolean;
  danger?: boolean;
  allowCloseOnClickOutside?: boolean;
  primaryButtonDisabled?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  selectorPrimaryFocus?: string;
  hasScrollingContent?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

const Modal = ({
  isOpen,
  loading = false,
  loadingText = 'Submitting...',
  visibleOverflow = false,
  children,
  onClose,
  onSubmit,
  label = null,
  heading = null,
  passiveModal = false,
  danger = false,
  allowCloseOnClickOutside = false,
  secondaryButtonText,
  primaryButtonText,
  primaryButtonDisabled,
  selectorPrimaryFocus,
  hasScrollingContent = false,
  size = 'md',
}: ModalProps) => {
  const renderPrimaryButton = () => {
    if (!loading && primaryButtonText) {
      return primaryButtonText;
    }

    if (loading && primaryButtonText) {
      return (
        <div className={baseStyles.dFlex}>
          <div className={spacing.mr4}>
            <Loading small withOverlay={false} />
          </div>
          {loadingText}
        </div>
      );
    }

    return null;
  };

  return ReactDOM.createPortal(
    <CarbonModal
      open={isOpen}
      modalLabel={label}
      modalHeading={heading}
      modalAriaLabel={heading}
      aria-label={heading}
      passiveModal={passiveModal}
      danger={danger}
      preventCloseOnClickOutside={!allowCloseOnClickOutside}
      secondaryButtonText={secondaryButtonText}
      primaryButtonText={renderPrimaryButton()}
      primaryButtonDisabled={primaryButtonDisabled || loading}
      shouldSubmitOnEnter={false}
      selectorPrimaryFocus={selectorPrimaryFocus}
      size={size}
      onRequestClose={onClose}
      onRequestSubmit={onSubmit}
      className={classNames(styles.container, { [styles.visibleOverflow]: visibleOverflow })}
      hasScrollingContent={hasScrollingContent}
    >
      {children}
    </CarbonModal>,
    document.body
  );
};

export default Modal;

import React from 'react';
import { Layer, ProgressBar } from '@carbon/react';
import formatNumber from '@utils/formatNumber';
import { formatDate } from '@utils/formatDate';
import percentage from '@utils/percentage';
import { Contract } from '@types';

interface ActiveContractProps {
  contract: Contract;
}

const ActiveContract = ({ contract }: ActiveContractProps) => (
  <Layer>
    <ProgressBar
      label="Contract usage"
      hideLabel
      max={contract.count}
      size="small"
      value={contract.usage}
      helperText={`${formatNumber(contract.usage)}/${formatNumber(contract.count)} (${percentage(
        contract.usage,
        contract.count
      )}%) | ${formatDate(contract.start)} - ${formatDate(contract.end)}`}
    />
  </Layer>
);

export default ActiveContract;

import React from 'react';
import { InlineNotification } from '@carbon/react';
import styles from './searchResultStatus.module.scss';

interface SearchResultStatusProps {
  query: string;
  count: number;
  warnings: string[];
}

const SearchResultStatus = ({ query, count, warnings }: SearchResultStatusProps) => {
  const getMessage = (): string => {
    if (!count) {
      return 'No match was found for';
    }

    if (count === 1) {
      return 'There is one possible match for';
    }

    return `There are ${count} possible matches for`;
  };

  return (
    <React.Fragment>
      {warnings.map((warning) => (
        <InlineNotification
          key={warning}
          aria-label="closes notification"
          statusIconDescription="notification"
          className={styles.warning}
          subtitle={warning}
          kind="warning"
          lowContrast
        />
      ))}
      <InlineNotification
        className={styles.container}
        title={query}
        subtitle={getMessage()}
        kind="info"
        hideCloseButton
        lowContrast
      />
    </React.Fragment>
  );
};

export default SearchResultStatus;

import React from 'react';
import styles from './profileInfo.module.scss';

interface TitleProps {
  children: string;
}

const Title = ({ children }: TitleProps) => <h2 className={styles.name}>{children}</h2>;

export default Title;

import React, { useState, useEffect } from 'react';
import { Form, TextInput } from '@carbon/react';
import Modal from '@components/Modal';
import ErrorMessage from '@components/ErrorMessage';
import spacing from '@styles/spacing.module.scss';

interface EditNameProps {
  name: string;
  isOpen: boolean;
  loading: boolean;
  errorMessage?: string;
  onClose: () => void;
  onSubmit: (value: string) => void;
}

const EditName = ({ isOpen, loading, name, errorMessage, onClose, onSubmit }: EditNameProps) => {
  const [value, setValue] = useState(name);

  // update input if name prop is changed
  useEffect(() => {
    if (name !== value) {
      setValue(name);
    }
  }, [name]);

  // reset changes when modal is re-opened
  useEffect(() => {
    if (isOpen && value !== name) {
      setValue(name);
    }
  }, [isOpen]);

  const handleSubmit = () => {
    if (!loading) {
      onSubmit(value);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Modal
      isOpen={isOpen}
      heading="Edit name"
      loading={loading}
      loadingText="Updating..."
      primaryButtonText="Update"
      secondaryButtonText="Cancel"
      selectorPrimaryFocus="input[id='name']"
      primaryButtonDisabled={name === value}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <Form onSubmit={handleSubmit}>
        <TextInput
          id="name"
          labelText="Name (optional)"
          hideLabel
          title="Name"
          value={value}
          onChange={handleChange}
        />
        {errorMessage && !loading && (
          <div className={spacing.mt4}>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default EditName;

import React from 'react';
import { MenuItem as CarbonMenuItem } from '@carbon/react';

interface MenuItemProps {
  label: string;
  renderIcon?: React.ReactNode;
  kind?: 'default' | 'danger';
  onClick: () => void;
}

const MenuItem = ({ label, renderIcon, kind = 'default', onClick }: MenuItemProps) => (
  <CarbonMenuItem label={label} renderIcon={renderIcon} kind={kind} onClick={onClick} />
);

export default MenuItem;

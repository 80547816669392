import errorType from '@mantis/errors';
import client from '@services/client';
import { gql } from '@services/gql';
import parseError from '@utils/parseError';

const UPDATE_ORGANIZATION = gql(/* GraphQL */ `
  mutation updateOrganization($id: ID!, $name: String!) {
    organizationUpdate(id: $id, data: { name: $name }) {
      id
    }
  }
`);

const updateOrganization = async (id: string, name: string): Promise<string> => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_ORGANIZATION,
      variables: {
        id,
        name,
      },
    });

    if (!response.data) {
      throw new Error('Something went wrong');
    }

    return response.data.organizationUpdate.id;
  } catch (error: unknown) {
    const parsedError = parseError(error);

    if (parsedError.type && parsedError.type in errorType) {
      throw new Error(parsedError.message);
    }

    throw new Error('Something went wrong');
  }
};

export default updateOrganization;

import React, { useState, useEffect } from 'react';
import { SkeletonText } from '@carbon/react';
import LoadingWrapper from '@components/LoadingWrapper';
import Heading from '@components/Heading';
import useToast from '@hooks/useToast';
import spacing from '@styles/spacing.module.scss';
import { MonthlyStats, YearlyStats } from '@components/Statistics';
import { StatisticsData } from './types';
import fetchStatistics from './helpers/fetchStatistics';
import SelectYear from './SelectYear';

interface StatisticsProps {
  loading: boolean;
}

const Statistics = ({ loading }: StatisticsProps) => {
  const toast = useToast();
  const [year, setYear] = useState(new Date().getFullYear());
  const [fetching, setFetching] = useState(true);
  const [statistics, setStatistics] = useState<Nullable<StatisticsData>>(null);

  useEffect(() => {
    const fetchStatisticsFn = async () => {
      try {
        setFetching(true);
        const fetchedStatistics = await fetchStatistics(year);
        setStatistics(fetchedStatistics);
      } catch (error) {
        toast.warning({ title: 'Fetch statistics', subtitle: 'Failed to fetch' });
      } finally {
        setFetching(false);
      }
    };

    if (!loading) {
      fetchStatisticsFn();
    }
  }, [loading, year]);

  return (
    <React.Fragment>
      {loading ? (
        <LoadingWrapper ariaLabel="Loading statistics heading">
          <SkeletonText className={spacing.mb4} heading width="165px" />
        </LoadingWrapper>
      ) : (
        <Heading className={spacing.mb4}>Yearly statistics</Heading>
      )}
      <div className={spacing.mb4}>
        <SelectYear loading={loading} year={year} onChange={setYear} />
      </div>
      <YearlyStats
        loading={loading || fetching}
        total={statistics?.yearly.statistics.total || 0}
        match={statistics?.yearly.statistics.match || 0}
        noMatch={statistics?.yearly.statistics.noMatch || 0}
        error={statistics?.yearly.statistics.error || 0}
      />
      <div className={spacing.mt3}>
        <MonthlyStats
          loading={loading || fetching}
          statistics={statistics?.monthly || []}
          layout="vertical"
        />
      </div>
    </React.Fragment>
  );
};

export default Statistics;

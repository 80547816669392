import React from 'react';
import classNames from 'classnames';
import { Tag as TagElement } from '@carbon/react';
import { PersonFieldMetadata } from '@mantis/types';
import styles from './recordTags.module.scss';

export interface Tag {
  value: string;
  type?: 'green' | 'red' | 'gray' | 'cyan';
}

interface RecordTagsProps {
  metadata: PersonFieldMetadata;
  tags?: Tag[];
  className?: string;
}

const RecordTags = ({ metadata, tags = [], className }: RecordTagsProps) => {
  const tagsList = (() => {
    const list: Tag[] = [];

    if (metadata.current) {
      list.push({ value: 'current', type: 'green' });
    }
    if (metadata.inferred) {
      list.push({ value: 'inferred', type: 'gray' });
    }

    return [...list, ...tags];
  })();

  if (tagsList.length === 0) {
    return null;
  }

  return (
    <div className={classNames(styles.container, className)}>
      {tagsList.map((tag) => (
        <TagElement key={tag.value} size="sm" type={tag.type || 'gray'} className={styles.tag}>
          {tag.value}
        </TagElement>
      ))}
    </div>
  );
};

export default RecordTags;

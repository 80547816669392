import React from 'react';
import capitalize from 'lodash/capitalize';
import { Edit } from '@carbon/icons-react';
import { Stack } from '@carbon/react';
import { UserRole } from '@types';
import Heading from '@components/Heading';
import LinkButton from '@components/LinkButton';
import ProfileDataSkeleton from './ProfileDataSkeleton';
import Record from './Record';
import styles from './profileData.module.scss';

interface ProfileDataProps {
  loading: boolean;
  name?: string;
  email?: string;
  userRole?: UserRole;
  organization?: string;
  onEdit: () => void;
}

const ProfileData = ({
  loading,
  name,
  email,
  userRole,
  organization,
  onEdit,
}: ProfileDataProps) => {
  const renderNameTitle = () => (
    <div>
      <b>Name</b>
      <LinkButton className={styles.editButton} onClick={onEdit}>
        <span className={styles.editLabel}>Edit</span>
        <Edit size={13} />
      </LinkButton>
    </div>
  );

  if (loading) {
    return <ProfileDataSkeleton />;
  }

  return (
    <React.Fragment>
      <Heading className={styles.heading}>Profile</Heading>
      <Stack gap={5}>
        <Record title={renderNameTitle()}>{name || <i>Not provided</i>}</Record>
        <Record title="Email">{email}</Record>
        <Record title="Role">
          {userRole === 'organizationAdmin' ? 'Organization admin' : capitalize(userRole)}
        </Record>
        <Record title="Organization">{organization}</Record>
      </Stack>
    </React.Fragment>
  );
};

export default ProfileData;

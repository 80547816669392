import React from 'react';

export interface ItemProps {
  children: React.ReactNode;
  className?: string;
}

const Item = ({ children, className }: ItemProps) => <li className={className}>{children}</li>;

export default Item;

import { AdvancedSearchParams } from '@mantis/types';

const isMinRequirementsFulfilled = (params: AdvancedSearchParams): boolean => {
  if (params.firstName && params.lastName) {
    return true;
  }

  if (params.email || params.phone || params.username || params.userId) {
    return true;
  }

  return false;
};

export default isMinRequirementsFulfilled;

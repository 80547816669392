import React, { useEffect, useRef } from 'react';
import { ToastNotification } from '@carbon/react';
import { ToastType } from './types';
import styles from './toasts.module.scss';

interface ToastProps {
  title: string;
  subtitle?: string;
  type: ToastType;
  onClose: () => void;
}

const Toast = ({ title, subtitle, type, onClose }: ToastProps) => {
  const timerId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timerId.current = setTimeout(() => {
      onClose();
    }, 5000);

    return () => {
      if (timerId) {
        clearInterval(timerId.current);
      }
    };
  }, []);

  const handleClose = () => {
    if (timerId) {
      clearInterval(timerId.current);
    }

    onClose();

    return false;
  };

  return (
    <ToastNotification
      className={styles.toast}
      title={title}
      subtitle={subtitle}
      kind={type}
      onClose={handleClose}
    />
  );
};

export default Toast;

import React from 'react';
import logger from '@services/logger';
import ErrorMessage from '@components/ErrorMessage';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  errorMessage?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

const logError = (error: unknown) => {
  if (error instanceof Error) {
    logger.error(error.message, { error });
  } else {
    logger.error('Error caught in ErrorBoundary component', { error });
  }
};

class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: unknown) {
    logError(error);

    return { hasError: true };
  }

  componentDidCatch(error: unknown) {
    this.setState({
      hasError: true,
    });

    logError(error);
  }

  render() {
    const { hasError } = this.state;
    const { children, errorMessage } = this.props;

    if (hasError) {
      return <ErrorMessage>{errorMessage || 'Something went wrong'}</ErrorMessage>;
    }

    return children;
  }
}

export default ErrorBoundary;

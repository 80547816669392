import { useReducer, useCallback } from 'react';
import { ToastType, Toast } from './types';

interface ToastsState {
  items: Toast[];
}

type AddToastAction = {
  type: 'ADD';
  payload: {
    title: string;
    subtitle?: string;
    type: ToastType;
  };
};

type CloseToastAction = {
  type: 'CLOSE';
  payload: {
    id: string;
  };
};

type ToastAction = AddToastAction | CloseToastAction;

const generateId = () => Math.floor(Math.random() * Date.now()).toString(16);

const reducer = (state: ToastsState, action: ToastAction): ToastsState => {
  switch (action.type) {
    case 'ADD':
      return { items: [...state.items, { id: generateId(), ...action.payload }] };
    case 'CLOSE':
      return { items: state.items.filter((item) => item.id !== action.payload.id) };
    default:
      return state;
  }
};

const useToastsState = () => {
  const [state, dispatch] = useReducer(reducer, {
    items: [],
  });

  const add = useCallback((toast: Omit<Toast, 'id'>) => {
    dispatch({ type: 'ADD', payload: toast });
  }, []);

  const close = useCallback((id: string) => {
    dispatch({ type: 'CLOSE', payload: { id } });
  }, []);

  return { toasts: state.items, add, close };
};

export default useToastsState;

import { useReducer, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { SortOrder } from '@types';

interface TableStateOptions {
  initialSortBy?: string;
  initialOrder?: SortOrder;
}

interface TableState {
  ids: string[];
  selected: string[];
  sortBy: string;
  order: SortOrder;
}

type TableActionSet = {
  type: 'SET';
  payload: {
    id: string;
  };
};

type TableActionUnset = {
  type: 'UNSET';
  payload: {
    id: string;
  };
};

type TableActionSortBy = {
  type: 'SORT';
  payload: {
    field: string;
  };
};

type TableActionToggleAll = {
  type: 'TOGGLE_ALL';
};

type TableActionToggle = {
  type: 'TOGGLE';
  payload: {
    id: string;
  };
};

type TableActionResetSelection = {
  type: 'RESET_SELECTION';
};

type TableAction =
  | TableActionSet
  | TableActionUnset
  | TableActionSortBy
  | TableActionToggleAll
  | TableActionToggle
  | TableActionResetSelection;

const reducer = (state: TableState, action: TableAction): TableState => {
  const toggleOrder = (order: SortOrder): SortOrder => (order === 'ASC' ? 'DESC' : 'ASC');

  switch (action.type) {
    case 'SET':
      if (state.ids.includes(action.payload.id)) {
        return state;
      }

      return {
        ...state,
        ids: [...state.ids, action.payload.id],
      };
    case 'UNSET':
      return {
        ...state,
        ids: state.ids.filter((id) => id !== action.payload.id),
      };
    case 'TOGGLE':
      if (!state.ids.includes(action.payload.id)) {
        return state;
      }

      if (state.selected.includes(action.payload.id)) {
        return {
          ...state,
          selected: state.selected.filter((id) => id !== action.payload.id),
        };
      }

      return {
        ...state,
        selected: [...state.selected, action.payload.id],
      };
    case 'TOGGLE_ALL':
      if (state.selected.length > 0) {
        return {
          ...state,
          selected: [],
        };
      }

      return {
        ...state,
        selected: [...state.ids],
      };
    case 'RESET_SELECTION':
      return {
        ...state,
        selected: [],
      };
    case 'SORT':
      return {
        ...state,
        sortBy: action.payload.field,
        order: state.sortBy === action.payload.field ? toggleOrder(state.order) : 'ASC',
      };
    default:
      return state;
  }
};

const useTableState = ({ initialSortBy, initialOrder }: TableStateOptions) => {
  const [state, dispatch] = useReducer(reducer, {
    ids: [],
    selected: [],
    sortBy: initialSortBy || '',
    order: initialOrder || 'ASC',
  });

  const setSortBy = useCallback((field: string) => {
    dispatch({ type: 'SORT', payload: { field } });
  }, []);

  /**
   * Register a row within a selectable table
   * @param {string} id
   */
  const subscribe = useCallback((id: string) => {
    dispatch({ type: 'SET', payload: { id } });
  }, []);

  /**
   * Deregister a row within a selectable table
   * @param {string} id
   */
  const unsubscribe = useCallback((id: string) => {
    dispatch({ type: 'UNSET', payload: { id } });
  }, []);

  const selectAll = useCallback(() => {
    dispatch({ type: 'TOGGLE_ALL' });
  }, []);

  const select = useCallback((id: string) => {
    dispatch({ type: 'TOGGLE', payload: { id } });
  }, []);

  const resetAllSelection = useCallback(() => {
    dispatch({ type: 'RESET_SELECTION' });
  }, []);

  const isSelected = useCallback((id: string) => state.selected.includes(id), [state.selected]);

  const isAllSelected = useCallback(
    () => isEqual(state.selected.sort(), state.ids.sort()),
    [state.selected, state.ids]
  );

  return {
    sortBy: state.sortBy,
    order: state.order,
    selected: state.selected,
    setSortBy,
    subscribe,
    unsubscribe,
    selectAll,
    select,
    resetAllSelection,
    isSelected,
    isAllSelected,
  };
};

export default useTableState;

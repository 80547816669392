import React from 'react';
import times from 'lodash/times';
import { TableRow, TableCell, SkeletonText } from '@carbon/react';
import styles from './dataTable.module.scss';

interface TableBodySkeletonProps {
  columnCount: number;
  rowCount?: number;
  isSelectable?: boolean;
}

const TableBodySkeleton = ({
  rowCount = 5,
  columnCount,
  isSelectable = false,
}: TableBodySkeletonProps) => (
  <React.Fragment>
    {times(rowCount, (rowIndex) => (
      <TableRow id={rowIndex} key={rowIndex}>
        {isSelectable && (
          <TableCell>
            <SkeletonText className={styles.checkboxSkeleton} />
          </TableCell>
        )}
        {times(columnCount, (columnIndex) => (
          <TableCell key={columnIndex}>
            <SkeletonText className={styles.cellSkeleton} />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </React.Fragment>
);

export default TableBodySkeleton;

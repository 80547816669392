import React from 'react';
import classNames from 'classnames';
import styles from './heading.module.scss';

export interface HeadingProps {
  children: React.ReactNode;
  className?: string;
  level?: 1 | 2 | 3;
}

const Heading = ({ children, className, level = 1 }: HeadingProps) => {
  const Tag: keyof JSX.IntrinsicElements = `h${level}`;

  return <Tag className={classNames(styles[`level-${level}`], className)}>{children}</Tag>;
};

export default Heading;

import React, { useState } from 'react';
import classNames from 'classnames';
import { Information } from '@carbon/icons-react';
import { Tooltip as CarbonTooltip, Popover, PopoverContent } from '@carbon/react';
import styles from './tooltip.module.scss';

interface TooltipProps {
  children: React.ReactNode;
  noInteractive?: boolean;
  noBorder?: boolean;
  align?:
    | 'top'
    | 'top-left'
    | 'top-right'
    | 'bottom'
    | 'bottom-left'
    | 'bottom-right'
    | 'left'
    | 'left-bottom'
    | 'left-top'
    | 'right'
    | 'right-bottom'
    | 'right-top';
}

const Tooltip = ({ children, align, noBorder, noInteractive }: TooltipProps) => {
  const [isOpen, setOpen] = useState(false);

  if (noInteractive) {
    return (
      <Popover className="cds--popover--high-contrast" open={isOpen} align={align}>
        <div
          className={classNames(styles.button, { [styles.noBorder]: noBorder })}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <Information />
        </div>
        <PopoverContent className="cds--tooltip-content cds--popover-content">
          {children}
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <CarbonTooltip label={children} align={align || 'top'} className={styles.container}>
      <button type="button" className={classNames(styles.button, { [styles.noBorder]: noBorder })}>
        <Information />
      </button>
    </CarbonTooltip>
  );
};

export default Tooltip;

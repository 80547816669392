import React from 'react';
import { User, SuccessResponse, LoginData } from '@types';

interface AuthContext {
  user: Nullable<User>;
  loading: boolean;
  login: (data: LoginData) => Promise<SuccessResponse>;
  logout: () => Promise<boolean>;
  refetchMe: () => Promise<void>;
}

export default React.createContext<AuthContext>({
  user: null,
  loading: false,
  login: async () => ({ success: false }),
  logout: async () => false,
  refetchMe: async () => {},
});

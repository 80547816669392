import { useCallback } from 'react';
import useLoader from '@hooks/useLoader';
import useModal from '@hooks/useModal';
import createUser from './helpers/createUser';
import updateUser from './helpers/updateUser';
import deleteUser from './helpers/deleteUser';
import { CreateUserData, EditUserData } from './types';

type Action = 'editUser' | 'createUser' | 'deleteUser';

const useUserActions = () => {
  const { openModal, closeModal, isModalOpen } = useModal<Action>();
  const updateLoader = useLoader();
  const createLoader = useLoader();
  const deleteLoader = useLoader();

  const isLoading = useCallback(
    (action: Action) => {
      if (action === 'createUser') {
        return createLoader.loading;
      }
      if (action === 'editUser') {
        return updateLoader.loading;
      }

      return deleteLoader.loading;
    },
    [createLoader.loading, updateLoader.loading, deleteLoader.loading]
  );

  const getError = useCallback(
    (action: Action) => {
      if (action === 'createUser') {
        return createLoader.error;
      }
      if (action === 'editUser') {
        return updateLoader.error;
      }

      return deleteLoader.error;
    },
    [createLoader.error, updateLoader.error, deleteLoader.error]
  );

  const closeModalAction = useCallback(() => {
    closeModal();

    // clear error messages when modal is closed
    updateLoader.actions.reset();
    createLoader.actions.reset();
    deleteLoader.actions.reset();
  }, []);

  const createUserAction = useCallback(async (data: CreateUserData): Promise<boolean> => {
    const { start, success, error } = createLoader.actions;

    try {
      start();
      await createUser(data);
      success();
      closeModalAction();

      return true;
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
      } else {
        error('Something went wrong');
      }

      return false;
    }
  }, []);

  const updateUserAction = useCallback(async (id: string, data: EditUserData): Promise<boolean> => {
    const { start, success, error } = updateLoader.actions;

    try {
      start();
      await updateUser(id, data);
      success();
      closeModalAction();

      return true;
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
      } else {
        error('Something went wrong');
      }

      return false;
    }
  }, []);

  const deleteUserAction = useCallback(async (id: string): Promise<boolean> => {
    const { start, success, error } = deleteLoader.actions;

    try {
      start();
      await deleteUser(id);
      success();
      closeModalAction();

      return true;
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
      } else {
        error('Something went wrong');
      }

      return false;
    }
  }, []);

  return {
    isLoading,
    getError,
    openModal,
    isModalOpen,
    closeModal: closeModalAction,
    createUser: createUserAction,
    updateUser: updateUserAction,
    deleteUser: deleteUserAction,
  };
};

export default useUserActions;

import React from 'react';
import { InlineNotification } from '@carbon/react';
import styles from './layout.module.scss';

export interface WarningMessageProps {
  message: string;
}

const WarningMessage = ({ message }: WarningMessageProps) => (
  <InlineNotification
    className={styles.warningMessage}
    aria-label="closes notification"
    statusIconDescription="notification"
    subtitle={message}
    title="Warning:"
    kind="warning-alt"
    role="status"
    hideCloseButton
    lowContrast
  />
);

export default WarningMessage;

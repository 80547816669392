import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form, Stack, TextInput } from '@carbon/react';
import usePrevious from '@hooks/usePrevious';
import Modal from '@components/Modal';
import ErrorMessage from '@components/ErrorMessage';

interface EditOrganizationProps {
  isOpen: boolean;
  loading: boolean;
  errorMessage?: string;
  name: string;
  onClose: () => void;
  onSubmit: (name: string) => void;
}

interface Fields {
  name: string;
}

const EditOrganizationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Organization name is required'),
});

const EditOrganization = ({
  isOpen,
  loading,
  errorMessage,
  name,
  onClose,
  onSubmit,
}: EditOrganizationProps) => {
  const previouslyOpen = usePrevious(isOpen);

  const { values, errors, touched, handleChange, handleSubmit, resetForm, setValues } = useFormik({
    initialValues: { name },
    validationSchema: EditOrganizationSchema,
    onSubmit: async (data: Fields) => {
      if (!loading) {
        onSubmit(data.name.trim());
      }
    },
  });

  // reset form once it's closed
  useEffect(() => {
    if (!isOpen && previouslyOpen) {
      resetForm({
        values: {
          name,
        },
      });
    }
  }, [isOpen, previouslyOpen]);

  // update field inputs if name prop is changed
  useEffect(() => {
    if (values.name !== name) {
      setValues({ name });
    }
  }, [name]);

  return (
    <Modal
      isOpen={isOpen}
      loading={loading}
      loadingText="Submitting..."
      heading="Edit organization"
      primaryButtonText="Submit"
      secondaryButtonText="Cancel"
      selectorPrimaryFocus="input[id='name']"
      onClose={onClose}
      onSubmit={handleSubmit}
      size="sm"
    >
      <Form onSubmit={handleSubmit}>
        <Stack gap={6}>
          {errorMessage && !loading && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <TextInput
            id="name"
            labelText="Name"
            title="Name"
            placeholder="Organization name"
            value={values.name}
            onChange={handleChange}
            invalid={errors.name && touched.name}
            invalidText={errors.name}
          />
        </Stack>
      </Form>
    </Modal>
  );
};

export default EditOrganization;

import client from '@services/client';
import { gql } from '@services/gql';

const FETCH_ORGANIZATION_NAME = gql(/* GraphQL */ `
  query fetchOrganizationName($id: ID!) {
    organization(id: $id) {
      id
      name
    }
  }
`);

const fetchOrganizationName = async (id: string): Promise<string> => {
  const response = await client.query({
    query: FETCH_ORGANIZATION_NAME,
    variables: { id },
    fetchPolicy: 'cache-first',
  });

  return response.data.organization?.name || '';
};

export default fetchOrganizationName;

import React, { useState, useEffect, useCallback } from 'react';
import omitBy from 'lodash/omitBy';
import isEqual from 'lodash/isEqual';
import { useSearchParams } from 'react-router-dom';
import { SearchActivity, UserRole } from '@types';
import useCursorDataFetch from '@hooks/useCursorDataFetch';
import SearchHistoryTable from './SearchHistoryTable';
import fetchSearchActivities from './helpers/fetchSearchHistory';
import filterSearchParams from './helpers/filterSearchParams';
import organizationExists from './helpers/organizationExists';
import userExists from './helpers/userExits';
import { FetchSearchHistoryFilter } from './types';

interface SearchHistoryProps {
  loading: boolean;
  userRole: UserRole;
}

const SearchHistory = ({ loading, userRole }: SearchHistoryProps) => {
  const [invalidSearchParams, setInvalidSearchParams] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    loading: fetching,
    data,
    error,
    count,
    filteredBy,
    setPageSize,
    nextPage,
    previousPage,
    filterBy,
    reset,
    refetch,
  } = useCursorDataFetch<SearchActivity, FetchSearchHistoryFilter>(fetchSearchActivities, {
    pageSize: 50,
  });

  // fetch after loading and when url is changed
  useEffect(() => {
    if (!loading) {
      if (searchParams.toString()) {
        // validate search params
        const params = Object.fromEntries(searchParams);
        const filteredParams = filterSearchParams(params, userRole);

        // set filtered params if initial search params were invalid
        if (!isEqual(params, filteredParams)) {
          if (!filteredParams) {
            setSearchParams('');
          } else {
            setSearchParams(new URLSearchParams(filteredParams as Record<string, string>));
          }
        }

        // check if user or org params are valid and still exist in DB
        if (filteredParams && filteredParams.user) {
          userExists(filteredParams.user)
            .then((exists) => {
              if (!exists) {
                setInvalidSearchParams(true);
              }
            })
            .catch(() => {});
        }
        if (filteredParams && filteredParams.organization) {
          organizationExists(filteredParams.organization)
            .then((exists) => {
              if (!exists) {
                setInvalidSearchParams(true);
              }
            })
            .catch(() => {});
        }

        if (filteredParams) {
          // fetch by params from URL
          filterBy(filteredParams);
        } else {
          // no params in URL -> fetch by default params
          reset();
        }
      } else {
        reset();
      }
    }
  }, [loading, searchParams.toString()]);

  const handleFilter = useCallback(
    (filter: FetchSearchHistoryFilter) => {
      const params = { ...filteredBy, ...filter };
      const filteredParams = omitBy(params, (value) => !value) as Record<string, string>;

      if ((filter.user || filter.organization) && invalidSearchParams) {
        setInvalidSearchParams(false);
      }

      setSearchParams(filteredParams);
    },
    [filteredBy, invalidSearchParams]
  );

  const handleReset = useCallback(() => {
    setSearchParams('');
    setInvalidSearchParams(false);
  }, []);

  return (
    <SearchHistoryTable
      loading={loading}
      fetching={fetching}
      error={error}
      count={count}
      data={data}
      userRole={userRole}
      filteredBy={filteredBy || {}}
      invalidSearchParams={invalidSearchParams}
      onReset={handleReset}
      onFilter={handleFilter}
      onPageSizeChange={setPageSize}
      onRefetch={refetch}
      onNextPage={nextPage}
      onPreviousPage={previousPage}
    />
  );
};

export default SearchHistory;

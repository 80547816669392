import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { useIsMounted } from 'usehooks-ts';
import capitalize from 'lodash/capitalize';
import { SignupData } from '@types';
import Heading from '@components/Heading';
import Navigate from '@components/Navigate';
import ErrorMessage from '@components/ErrorMessage';
import { Divider } from '@components/Form';
import PasswordRequirements from '@components/PasswordRequirements';
import { Layer, Form, Stack, TextInput, PasswordInput, Button, InlineLoading } from '@carbon/react';

// Extend yup
YupPassword(Yup);

interface Fields {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface SignupFormProps {
  onSubmit: (data: SignupData) => void;
  loading?: boolean;
  email?: string;
  errorMessage?: string;
}

const SignupSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string().trim().required('Email is required').email('Invalid email'),
  password: Yup.string().trim().required('Password is required').password(),
  confirmPassword: Yup.string()
    .trim()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

const SignupForm = ({
  onSubmit,
  loading = false,
  email = '',
  errorMessage = '',
}: SignupFormProps) => {
  const isMounted = useIsMounted();
  const [passwordFocused, setPasswordFocus] = useState(false);

  const initialValues: Fields = { name: '', email, password: '', confirmPassword: '' };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    onSubmit: (data: Fields) => {
      onSubmit({
        name: data.name.trim(),
        email: data.email.trim(),
        password: data.password.trim(),
      });
    },
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={6}>
        <Heading>Sign up to IBM Mantis</Heading>
        {errorMessage && !loading && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Layer>
          <TextInput
            id="name"
            labelText="Name (optional)"
            placeholder="User name"
            title="User name"
            value={values.name}
            onChange={handleChange}
            invalid={errors.name && touched.name}
            invalidText={errors.name}
          />
        </Layer>
        <Layer>
          <TextInput
            id="email"
            labelText="Email"
            placeholder="email@test.com"
            title="User email"
            value={values.email}
            onChange={handleChange}
            invalid={errors.email && touched.email}
            invalidText={errors.email}
          />
        </Layer>
        <Layer>
          <PasswordInput
            id="password"
            placeholder="Password"
            title="User password"
            value={values.password}
            onChange={handleChange}
            onFocus={() => setPasswordFocus(true)}
            // clicking on submit button or "password visibility toggle" from password input, calls
            // the onBlur callback which mutates the state which prevents submit button or toggle
            // onClick callback https://github.com/facebook/react/issues/4210
            // fixed with setTimeout
            onBlur={() => setTimeout(() => isMounted() && setPasswordFocus(false), 100)}
            invalid={errors.password && touched.password}
            invalidText={errors.password ? capitalize(errors.password) : ''}
            labelText="Password"
            helperText={passwordFocused && <PasswordRequirements password={values.password} />}
          />
        </Layer>
        <Layer>
          <PasswordInput
            id="confirmPassword"
            placeholder="Confirm password"
            title="Confirm password"
            value={values.confirmPassword}
            onChange={handleChange}
            invalid={errors.confirmPassword && touched.confirmPassword}
            invalidText={errors.confirmPassword}
            labelText="Confirm password"
          />
        </Layer>
        {!loading ? (
          <Button type="submit">Sign up</Button>
        ) : (
          <InlineLoading description="Sign up..." />
        )}
      </Stack>
      <Divider />
      <div>
        Already have an account ? <Navigate to="/login">Log in</Navigate>
      </div>
    </Form>
  );
};

export default SignupForm;

import React from 'react';
import { SortOption, OffsetPaginationData } from '@types';
import Navigate, { useNavigate } from '@components/Navigate';
import ErrorMessage from '@components/ErrorMessage';
import { OverflowMenu, MenuItem } from '@components/OverflowMenu';
import { DataTable, TableRow, TableCell, type TableHeader } from '@components/DataTable';
import { formatDateTime } from '@utils/formatDate';
import spacing from '@styles/spacing.module.scss';
import RecentVolumeHeader from './RecentVolumeHeader';
import ActiveContract from './ActiveContract';
import { Organization } from '../types';

interface OrganizationsListProps {
  loading: boolean;
  fetching: boolean;
  initialSort: SortOption;
  data: Organization[];
  error: boolean;
  count: number;
  onAdd: () => void;
  onEdit: (organization: Organization) => void;
  onRemove: (organization: Organization) => void;
  onSearchByText: (text: string) => void;
  onSortBy: (sort: Partial<SortOption>) => void;
  onPaginate: (data: OffsetPaginationData) => void;
  onRefetch: () => void;
}

const headers: TableHeader[] = [
  {
    id: 'name',
    value: 'Name',
    isSortable: true,
  },
  {
    id: 'createdAt',
    value: 'Created',
    isSortable: true,
  },
  {
    id: 'usersCount',
    value: 'Number of users',
    isSortable: true,
  },
  {
    id: 'recentVolume',
    value: <RecentVolumeHeader />,
    isSortable: true,
  },
  {
    id: 'activeContract',
    value: 'Active contract',
    isSortable: false,
  },
  {
    id: 'actions',
    value: 'Actions',
    isSortable: false,
  },
];

const OrganizationsList = ({
  loading,
  fetching,
  initialSort,
  count,
  data,
  error,
  onAdd,
  onEdit,
  onRemove,
  onSearchByText,
  onSortBy,
  onPaginate,
  onRefetch,
}: OrganizationsListProps) => {
  const navigate = useNavigate();

  const handleEdit = (organization: Organization) => () => {
    onEdit(organization);
  };

  const handleDelete = (organization: Organization) => () => {
    onRemove(organization);
  };

  const handleManageContractsClick = (id: string) => () => {
    navigate(`/organization/${id}/contracts`);
  };

  return (
    <React.Fragment>
      {error && (
        <div className={spacing.mb4}>
          <ErrorMessage>Something went wrong during data fetching</ErrorMessage>
        </div>
      )}
      <DataTable
        title="Organizations"
        isSearchable
        loading={loading}
        fetching={fetching}
        onSearch={onSearchByText}
        searchPlaceholder="Search by name"
        buttonText="Add"
        headers={headers}
        initialSort={initialSort}
        totalItems={count}
        onButtonClick={onAdd}
        onSort={onSortBy}
        onNavigation={onPaginate}
        onRefresh={onRefetch}
      >
        {data.map((organization) => (
          <TableRow id={organization.id} key={organization.id}>
            <TableCell>
              <Navigate to={`/organization/${organization.id}/users`}>{organization.name}</Navigate>
            </TableCell>
            <TableCell>{formatDateTime(organization.createdAt)}</TableCell>
            <TableCell>{organization.numberOfUsers}</TableCell>
            <TableCell>{organization.recentVolume}</TableCell>
            <TableCell>
              {organization.currentContract ? (
                <ActiveContract contract={organization.currentContract} />
              ) : (
                '-'
              )}
            </TableCell>
            <TableCell>
              <OverflowMenu ariaLabel="Actions">
                <MenuItem label="Edit organization" onClick={handleEdit(organization)} />
                <MenuItem
                  label="Manage contracts"
                  onClick={handleManageContractsClick(organization.id)}
                />
                <MenuItem
                  label="Delete organization"
                  onClick={handleDelete(organization)}
                  kind="danger"
                />
              </OverflowMenu>
            </TableCell>
          </TableRow>
        ))}
      </DataTable>
    </React.Fragment>
  );
};

export default OrganizationsList;

import React from 'react';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from '@carbon/react';
import styles from './navigate.module.scss';

export interface NavigateProps {
  to: string;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  icon?: React.ReactElement;
  iconPosition?: 'before' | 'after';
  noStyle?: boolean;
  disableFocus?: boolean;
}

const Navigate = ({
  to,
  children,
  className,
  disabled = false,
  size = 'md',
  icon,
  iconPosition = 'after',
  noStyle = false,
  disableFocus = false,
}: NavigateProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event: Event) => {
    event.preventDefault();

    if (!disabled) {
      navigate(to, { state: { from: location } });
    }
  };

  return (
    <Link
      href={to}
      className={classNames(className, {
        [styles.noStyle]: noStyle,
        [styles.iconBefore]: iconPosition === 'before',
      })}
      disabled={disabled}
      size={size}
      renderIcon={icon}
      onClick={handleClick}
      tabIndex={disableFocus ? '-1' : '0'}
    >
      {children}
    </Link>
  );
};

export default Navigate;

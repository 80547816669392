import React from 'react';
import { CheckmarkFilled } from '@carbon/icons-react';
import { Divider } from '@components/Form';
import Navigate from '@components/Navigate';
import ButtonLoader from '@components/ButtonLoader';
import LinkButton from '@components/LinkButton';
import spacing from '@styles/spacing.module.scss';
import styles from './emailVerification.module.scss';

export interface EmailVerificationProps {
  email: string;
  onResendMail: () => Promise<boolean>;
  onNewRequest: () => void;
}

const EmailVerification = ({ email, onResendMail, onNewRequest }: EmailVerificationProps) => (
  <div className={styles.container}>
    <div className={styles.headingWrapper}>
      <CheckmarkFilled size={20} className={styles.icon} />
      <h1 className={styles.heading}>Reset link has been sent</h1>
    </div>
    <p>
      Check your email <b>{email}</b> to find the reset password link. If you can&apos;t find the
      email, please make sure you&apos;ve entered the email address you signed up with, and check
      your spam folder. In case you entered a wrong email, make a new reset password request
      <LinkButton onClick={onNewRequest} className={spacing.mx2}>
        here
      </LinkButton>
      or click below to re-send email.
    </p>
    <ButtonLoader
      classes={{ root: spacing.mt5 }}
      onClick={onResendMail}
      loadingText="Sending..."
      successText="Sent!"
    >
      Resend email
    </ButtonLoader>
    <Divider />
    Don&apos;t have an account ? <Navigate to="/signup">Sing up</Navigate>
  </div>
);

export default EmailVerification;

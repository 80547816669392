import React, { useMemo, useCallback } from 'react';
import MonthlyBarChart, {
  MonthlyBarChartData,
  MonthlyBarChartOption,
} from '@components/MonthlyBarChart';
import { MonthlyStatistics } from '@types';

interface MonthlyStatsProps {
  loading: boolean;
  statistics: MonthlyStatistics[];
  layout?: 'horizontal' | 'vertical';
}

const MonthlyStats = ({ loading, statistics, layout }: MonthlyStatsProps) => {
  const data: MonthlyBarChartData[] = useMemo(
    () =>
      statistics.map((monthlyStats) => ({
        month: monthlyStats.month,
        total: monthlyStats.statistics.total,
        match: monthlyStats.statistics.match,
        noMatch: monthlyStats.statistics.noMatch,
        error: monthlyStats.statistics.error,
      })),
    [statistics]
  );

  const barOptions: MonthlyBarChartOption[] = useMemo(
    () => [
      { key: 'match', color: 'green' },
      { key: 'noMatch', color: 'gray' },
      { key: 'error', color: 'red' },
    ],
    []
  );

  const formatLabel = useCallback(
    (month: number) => {
      const monthData = statistics.find((item) => item.month === month);

      if (monthData) {
        return monthData.statistics.total ? monthData.statistics.total.toString() : '';
      }

      return '';
    },
    [statistics]
  );

  const formatTooltip = useCallback((value: number, key: string): [number, string] => {
    switch (key) {
      case 'match':
        return [value, 'Matches'];
      case 'noMatch':
        return [value, 'No matches'];
      case 'error':
        return [value, 'Errors'];
      default:
        return [value, ''];
    }
  }, []);

  return (
    <MonthlyBarChart
      loading={loading}
      loadingAriaLabel="Loading monthly statistics"
      data={data}
      barOptions={barOptions}
      layout={layout}
      labelFormatter={formatLabel}
      tooltipFormatter={formatTooltip}
    />
  );
};

export default React.memo(MonthlyStats);

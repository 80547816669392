import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Form, FormGroup, Stack, TextInput, Checkbox } from '@carbon/react';
import usePrevious from '@hooks/usePrevious';
import Modal from '@components/Modal';
import ErrorMessage from '@components/ErrorMessage';
import DatePicker from '@components/DatePicker';
import NumberInput from '@components/NumberInput';
import spacing from '@styles/spacing.module.scss';
import CreateOrganizationSchema from './data/formSchema';
import { CreateOrganizationData } from '../types';

interface CreateOrganizationProps {
  isOpen: boolean;
  loading: boolean;
  errorMessage?: string;
  onClose: () => void;
  onSubmit: (data: CreateOrganizationData) => void;
}

interface Fields {
  name: string;
  admin: {
    name: string;
    email: string;
  };
  contract: {
    start: string;
    end: string;
    count: number;
  };
  includeContract: boolean;
  sendNotification: boolean;
}

const CreateOrganization = ({
  isOpen,
  errorMessage,
  loading,
  onClose,
  onSubmit,
}: CreateOrganizationProps) => {
  const previouslyOpen = usePrevious(isOpen);
  const initialValues: Fields = {
    name: '',
    admin: { name: '', email: '' },
    contract: { start: '', end: '', count: 0 },
    includeContract: false,
    sendNotification: true,
  };
  const { values, errors, touched, handleChange, handleSubmit, resetForm, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: CreateOrganizationSchema,
      onSubmit: async (data: Fields) => {
        if (!loading) {
          const parsedData: CreateOrganizationData = {
            name: data.name.trim(),
            admin: { name: data.admin.name.trim(), email: data.admin.email.trim() },
            sendNotification: data.sendNotification,
          };

          if (data.includeContract) {
            parsedData.contract = data.contract;
          }

          onSubmit(parsedData);
        }
      },
    });

  // reset form once it's closed
  useEffect(() => {
    if (!isOpen && previouslyOpen) {
      resetForm({
        values: {
          ...initialValues,
          includeContract: values.includeContract,
        },
      });
    }
  }, [isOpen, previouslyOpen]);

  const handleDateChange = (field: 'start' | 'end') => (date: Nullable<string>) => {
    setFieldValue(`contract.${field}`, date || '');
  };

  const handleCountChange = (value: Nullable<number>) => {
    setFieldValue('contract.count', value || 0);
  };

  return (
    <Modal
      isOpen={isOpen}
      loading={loading}
      loadingText="Submitting..."
      heading="Add organization"
      primaryButtonText="Submit"
      secondaryButtonText="Cancel"
      selectorPrimaryFocus="input[id='name']"
      onClose={onClose}
      onSubmit={handleSubmit}
      size="sm"
    >
      <Form onSubmit={handleSubmit}>
        <Stack gap={6}>
          {errorMessage && !loading && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <TextInput
            id="name"
            labelText="Name"
            title="Name"
            placeholder="Organization name"
            value={values.name}
            onChange={handleChange}
            invalid={errors.name && touched.name}
            invalidText={errors.name}
          />
          <TextInput
            id="admin.name"
            labelText="Organization admin name (optional)"
            title="Organization admin name"
            value={values.admin.name}
            onChange={handleChange}
            invalid={errors.admin?.name && touched.admin?.name}
            invalidText={errors.admin?.name}
          />
          <TextInput
            id="admin.email"
            labelText="Organization admin email"
            title="Organization admin email"
            value={values.admin.email}
            onChange={handleChange}
            invalid={errors.admin?.email && touched.admin?.email}
            invalidText={errors.admin?.email}
          />
          <div>
            <Checkbox
              id="sendNotification"
              labelText="Send invitation email"
              checked={values.sendNotification}
              onChange={handleChange}
            />
            <Checkbox
              id="includeContract"
              labelText="Add contract details"
              checked={values.includeContract}
              onChange={handleChange}
            />
          </div>
          {values.includeContract && (
            <FormGroup legendText="Contract" className={spacing.pb2}>
              <Stack gap={6}>
                <DatePicker
                  id="contract.start"
                  title="Start date"
                  onChange={handleDateChange('start')}
                  invalid={!!errors.contract?.start && touched.contract?.start}
                  invalidText={errors.contract?.start}
                  maxDate={values.contract.end ? new Date(values.contract.end) : null}
                />
                <DatePicker
                  id="contract.end"
                  title="End date"
                  onChange={handleDateChange('end')}
                  invalid={!!errors.contract?.end && touched.contract?.end}
                  invalidText={errors.contract?.end}
                  minDate={values.contract.start ? new Date(values.contract.start) : null}
                />
                <NumberInput
                  id="contract.count"
                  label="Number of searches"
                  allowEmpty
                  min={0}
                  value={values.contract?.count || null}
                  onChange={handleCountChange}
                  invalid={!!errors.contract?.count && touched.contract?.count}
                  invalidText={errors.contract?.count}
                />
              </Stack>
            </FormGroup>
          )}
        </Stack>
      </Form>
    </Modal>
  );
};

export default CreateOrganization;

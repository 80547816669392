import includes from 'lodash/includes';
import { AdvancedSearchParams } from '@mantis/types';
import { SERVICE_PROVIDERS } from '@mantis/data';
import { FormParams } from '../types';
import fields from '../data/fields';

const fieldKeys = Object.keys(fields) as Array<keyof typeof fields>;
const defaultParams: FormParams = fieldKeys.reduce(
  (acc, key) => {
    acc[key] = '';

    return acc;
  },
  { userIdService: '' } as FormParams
);

const initFormParams = (initialValues: AdvancedSearchParams = {}): FormParams => {
  const params = { ...defaultParams, ...initialValues };

  if (initialValues.userId && /@/.test(initialValues.userId)) {
    const userIdParts = initialValues.userId.trim().split('@');
    const userIdService = userIdParts[1];

    params.userId = userIdParts[0] || '';

    if (userIdService && includes(SERVICE_PROVIDERS, userIdService)) {
      params.userIdService = userIdService;
    }
  }

  return params;
};

export default initFormParams;

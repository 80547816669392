import { AdvancedSearchParams } from '@mantis/types';
import { InputField } from '../types';

const fields: Record<keyof AdvancedSearchParams, InputField> = {
  firstName: { label: 'First Name', placeholder: 'Minimum 2 characters' },
  lastName: { label: 'Last Name', placeholder: 'Minimum 2 characters' },
  middleName: { label: 'Middle Name', placeholder: 'Middle name or middle initial' },
  age: { label: 'Age', placeholder: 'An exact (xx) or approximate (xx-xx) age' },
  email: { label: 'Email', placeholder: 'Email address' },
  phone: { label: 'Phone', placeholder: 'Home/work/mobile phone number' },
  username: { label: 'Username', placeholder: 'Username/screen-name, min 3 characters' },
  userId: { label: 'User ID', placeholder: 'Unique ID' },
  url: { label: 'Url', placeholder: 'Profile URL from a service or social media' },
  country: { label: 'Country', placeholder: 'Country' },
  state: { label: 'State', placeholder: 'State' },
  city: { label: 'City', placeholder: 'City' },
  street: { label: 'Street', placeholder: 'Street and house number' },
  zipCode: { label: 'ZIP code', placeholder: 'ZIP code' },
  organization: { label: 'Organization', placeholder: 'Name of the employing organization' },
  industry: { label: 'Industry', placeholder: 'The employing organization industry' },
};

export default fields;

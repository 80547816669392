import errorType from '@mantis/errors';
import { USER_ROLE_MAP } from '@mantis/data';
import client from '@services/client';
import { gql } from '@services/gql';
import parseError from '@utils/parseError';
import { CreateUserData } from '../types';

const CREATE_USER = gql(/* GraphQL */ `
  mutation createUser(
    $name: String
    $email: EmailAddress!
    $role: UserRole!
    $organization: ID!
    $sendNotification: Boolean!
  ) {
    userCreate(
      data: {
        name: $name
        email: $email
        role: $role
        organization: $organization
        sendNotification: $sendNotification
      }
    ) {
      id
    }
  }
`);

const createUser = async (data: CreateUserData): Promise<string> => {
  try {
    const response = await client.mutate({
      mutation: CREATE_USER,
      variables: {
        name: data.name,
        email: data.email,
        role: USER_ROLE_MAP[data.role],
        organization: data.organization,
        sendNotification: data.sendNotification,
      },
    });

    if (!response.data) {
      throw new Error('Something went wrong');
    }

    return response.data.userCreate.id;
  } catch (error: unknown) {
    const parsedError = parseError(error);

    if (parsedError.type === errorType.VALIDATION_ERROR) {
      throw new Error(parsedError.message);
    }

    throw new Error('Something went wrong');
  }
};

export default createUser;

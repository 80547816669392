import { gql } from '@services/gql';
import client from '@services/client';
import { USER_ROLE_MAP } from '@mantis/data';
import { User } from '@types';

const FETCH_ME = gql(/* GraphQL */ `
  query fetchMe {
    me {
      id
      name
      email
      createdAt
      role
      organization {
        id
        name
        createdAt
      }
    }
  }
`);

const fetchMe = async (): Promise<User | null> => {
  const response = await client.query({ query: FETCH_ME });
  const user = response?.data.me;

  if (!user) {
    return null;
  }

  return {
    ...user,
    name: user.name || undefined,
    role: USER_ROLE_MAP[user.role],
  };
};

export default fetchMe;

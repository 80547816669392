import React from 'react';
import { ErrorFilled } from '@carbon/icons-react';
import { FlexGrid, Row, Column } from '@carbon/react';
import CenteredContent from '@components/CenteredContent';
import Card from '@components/Card';
import styles from './errorContent.module.scss';

interface ErrorContentProps {
  title: string;
  children: React.ReactNode;
  /**
   * Render without layout style
   */
  plain?: boolean;
}

const ErrorContent = ({ title, children, plain = false }: ErrorContentProps) => {
  const renderContent = () => (
    <div className={styles.container}>
      <div className={styles.headingWrapper}>
        <ErrorFilled size={20} className={styles.icon} />
        <h1 className={styles.heading}>{title}</h1>
      </div>
      {children}
    </div>
  );

  if (plain) {
    return renderContent();
  }

  return (
    <CenteredContent>
      <FlexGrid condensed>
        <Row>
          <Column
            sm={4}
            md={{ span: 6, offset: 1 }}
            lg={{ span: 8, offset: 4 }}
            xlg={{ span: 6, offset: 5 }}
          >
            <Card>{renderContent()}</Card>
          </Column>
        </Row>
      </FlexGrid>
    </CenteredContent>
  );
};

export default ErrorContent;

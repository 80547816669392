import React, { useState, useEffect } from 'react';
import { Gender } from '@types';
import getPersonPlaceholder from '@utils/getPersonPlaceholder';
import filterImages from '@utils/filterImages';
import ImageLoader from './ImageLoader';

interface ImageLoaderContainerProps {
  images: string[];
  alt: string;
  gender?: Gender;
  matchPointer: string;
}

const ImageLoaderContainer = ({ images, alt, gender, matchPointer }: ImageLoaderContainerProps) => {
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    const filterImagesFn = async () => {
      const filteredImages = await filterImages(images);

      if (filteredImages.length > 0) {
        setImageSrc(filteredImages[0]);
      } else {
        setImageSrc(getPersonPlaceholder(gender));
      }

      setLoading(false);
    };

    filterImagesFn();
  }, [images]);

  return (
    <ImageLoader
      src={imageSrc}
      alt={alt}
      gender={gender}
      loading={loading}
      matchPointer={matchPointer}
    />
  );
};

export default ImageLoaderContainer;

import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';
import isMongoId from 'validator/lib/isMongoId';
import { UserRole } from '@types';
import { FetchSearchHistoryFilter } from '../types';

/**
 * Check if date format is valid and if the date is NOT in future
 * @param date {string}
 * @returns {boolean}
 */
const isDateValid = (dateValue: string): boolean => {
  try {
    const date = DateTime.fromISO(dateValue);

    if (!date.isValid) {
      return false;
    }

    return date <= DateTime.now();
  } catch (err) {
    return false;
  }
};

const filterSearchParams = (
  params: Record<string, string>,
  role: UserRole
): Nullable<FetchSearchHistoryFilter> => {
  const filteredParams: FetchSearchHistoryFilter = {};

  // validate user
  if ('user' in params && params.user && isMongoId(params.user) && role === 'organizationAdmin') {
    filteredParams.user = params.user;
  }
  // validate organization
  if (
    'organization' in params &&
    params.organization &&
    isMongoId(params.organization) &&
    role === 'admin'
  ) {
    filteredParams.organization = params.organization;
  }
  // validate dates
  if ('from' in params && params.from && isDateValid(params.from)) {
    filteredParams.from = params.from;
  }
  if ('to' in params && params.to && isDateValid(params.to)) {
    if (filteredParams.from) {
      const startDate = DateTime.fromISO(filteredParams.from);
      const endDate = DateTime.fromISO(params.to);

      if (endDate >= startDate) {
        filteredParams.to = params.to;
      }
    } else {
      filteredParams.to = params.to;
    }
  }

  return !isEmpty(filteredParams) ? filteredParams : null;
};

export default filterSearchParams;

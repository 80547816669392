import client from '@services/client';
import { gql } from '@services/gql';
import { CONTRACT_STATUS_MAP } from '@mantis/data';
import { Contract } from '@types';

interface Response {
  organization: string;
  contracts: Contract[];
}

const FETCH_CONTRACTS = gql(/* GraphQL */ `
  query fetchContracts($organization: ID!) {
    organization(id: $organization) {
      name
      contracts {
        id
        start
        end
        count
        status
        usage
      }
    }
  }
`);

const fetchContracts = async (organizationId: string): Promise<Response> => {
  const response = await client.query({
    query: FETCH_CONTRACTS,
    variables: {
      organization: organizationId,
    },
  });

  return {
    organization: response.data.organization?.name || '',
    contracts: (response.data.organization?.contracts || []).map(({ status, ...rest }) => ({
      ...rest,
      status: CONTRACT_STATUS_MAP[status],
    })),
  };
};

export default fetchContracts;

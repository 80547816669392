import React from 'react';
import { SkeletonText, Stack } from '@carbon/react';
import baseStyles from '@styles/base.module.scss';
import styles from './recentActivities.module.scss';

interface RecentActivitiesSkeletonProps {
  includeHeading: boolean;
}

const RecentActivitiesSkeleton = ({ includeHeading }: RecentActivitiesSkeletonProps) => (
  <div role="alert" aria-busy="true" aria-label="Loading..">
    {includeHeading && <SkeletonText className={styles.heading} heading width="180px" />}
    <Stack gap={5}>
      <div className={baseStyles.spaceBetween}>
        <SkeletonText width="120px" />
        <SkeletonText width="70px" />
      </div>
      <div className={baseStyles.spaceBetween}>
        <SkeletonText width="120px" />
        <SkeletonText width="100px" />
      </div>
      <div className={baseStyles.spaceBetween}>
        <SkeletonText width="120px" />
        <SkeletonText width="70px" />
      </div>
      <div className={baseStyles.spaceBetween}>
        <SkeletonText width="120px" />
        <SkeletonText width="80px" />
      </div>
    </Stack>
  </div>
);

export default RecentActivitiesSkeleton;

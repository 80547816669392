import React from 'react';
import Modal from '@components/Modal';
import ErrorMessage from '@components/ErrorMessage';
import spacing from '@styles/spacing.module.scss';

interface DeleteOrganizationProps {
  organization: string;
  isOpen: boolean;
  loading: boolean;
  errorMessage?: string;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteOrganization = ({
  isOpen,
  loading,
  organization,
  errorMessage,
  onClose,
  onSubmit,
}: DeleteOrganizationProps) => (
  <Modal
    danger
    isOpen={isOpen}
    heading="Delete organization"
    loading={loading}
    loadingText="Deleting..."
    primaryButtonText="Delete"
    secondaryButtonText="Cancel"
    onClose={onClose}
    onSubmit={onSubmit}
  >
    Do you really want to delete organization <b>{organization}</b>? This process cannot be undone.
    {errorMessage && !loading && (
      <div className={spacing.mt4}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    )}
  </Modal>
);

export default React.memo(DeleteOrganization);

import React from 'react';
import { User, Email, Phone, Education as EducationIcon, Portfolio } from '@carbon/icons-react';
import { Person, PersonEmail, PersonPhoneType, PersonPhone } from '@mantis/types';
import classNames from 'classnames';
import spacing from '@styles/spacing.module.scss';
import ClickableRecord from '../components/ClickableRecord';
import { Tag } from '../components/RecordTags';
import Section from '../components/Section';
import Records from '../components/Records';
import Education from './Education';
import Job from './Job';
import styles from './profile.module.scss';

type ProfileProps = Pick<Person, 'names' | 'phones' | 'emails' | 'jobs' | 'educations'>;
type SearchParam = 'firstName' | 'lastName' | 'middleName' | 'email' | 'phone';

const PHONE_TYPE_LABELS: Record<PersonPhoneType, string> = {
  mobile: 'mobile',
  homePhone: 'home',
  homeFax: 'home fax',
  workPhone: 'work',
  workFax: 'work fax',
  pager: 'pages',
} as const;

const Profile = ({ names, phones, emails, jobs, educations }: ProfileProps) => {
  const getSearchLink = (params: Partial<Record<SearchParam, string>>) => {
    const searchParams = new URLSearchParams(params);

    return `/search/result?${searchParams.toString()}`;
  };

  const getEmailTags = (email: PersonEmail) => {
    const tags: Tag[] = [];

    if (email.type) {
      tags.push({ value: email.type, type: 'cyan' });
    }
    if (email.metadata.disposable) {
      tags.push({ value: 'disposable', type: 'gray' });
    }

    return tags;
  };

  const getPhoneTags = (phone: PersonPhone) => {
    const tags: Tag[] = [];

    if (phone.type) {
      tags.push({ value: PHONE_TYPE_LABELS[phone.type], type: 'cyan' });
    }
    if (phone.metadata.voip) {
      tags.push({ value: 'voip', type: 'green' });
    }
    if (phone.metadata.doNotCall) {
      tags.push({ value: 'do not call', type: 'red' });
    }

    return tags;
  };

  return (
    <div className={styles.container}>
      {/* Names */}
      {names.length > 0 && (
        <div className={spacing.mt4}>
          <Section icon={<User size={20} />} title="Names">
            <Records>
              {names.map((name) => (
                <ClickableRecord
                  key={name.value}
                  link={getSearchLink({
                    firstName: name.first,
                    lastName: name.last,
                    middleName: name.middle || '',
                  })}
                  metadata={name.metadata}
                >
                  {name.value}
                </ClickableRecord>
              ))}
            </Records>
          </Section>
        </div>
      )}

      {(emails.length > 0 || phones.length > 0) && (
        <div
          className={classNames(styles.contactWrapper, {
            [styles.fullWidth]: emails.length === 0 || phones.length === 0,
          })}
        >
          {/* Email addresses */}
          {emails.length > 0 && (
            <Section icon={<Email size={20} />} title="Email Addresses">
              <Records>
                {emails.map((email) => (
                  <ClickableRecord
                    key={email.value}
                    link={getSearchLink({ email: email.value })}
                    tags={getEmailTags(email)}
                    metadata={email.metadata}
                  >
                    {email.value}
                  </ClickableRecord>
                ))}
              </Records>
            </Section>
          )}

          {/* Phone numbers */}
          {phones.length > 0 && (
            <Section icon={<Phone size={20} />} title="Phone Numbers">
              <Records>
                {phones.map((phone) => (
                  <ClickableRecord
                    key={phone.value}
                    link={getSearchLink({ phone: phone.value })}
                    tags={getPhoneTags(phone)}
                    metadata={phone.metadata}
                  >
                    {phone.value}
                  </ClickableRecord>
                ))}
              </Records>
            </Section>
          )}
        </div>
      )}

      {/* Educations */}
      {educations.length > 0 && (
        <div className={spacing.mt4}>
          <Section icon={<EducationIcon size={20} />} title="Educations">
            <Records>
              {educations.map((education) => (
                <Education
                  key={education.value}
                  school={education.school}
                  degree={education.degree}
                  start={education.start}
                  end={education.end}
                  metadata={education.metadata}
                />
              ))}
            </Records>
          </Section>
        </div>
      )}

      {/* Jobs */}
      {jobs.length > 0 && (
        <div className={spacing.mt4}>
          <Section icon={<Portfolio size={20} />} title="Workplaces">
            <Records>
              {jobs.map((job) => (
                <Job
                  key={job.value}
                  title={job.title}
                  industry={job.industry}
                  organization={job.organization}
                  start={job.start}
                  end={job.end}
                  metadata={job.metadata}
                />
              ))}
            </Records>
          </Section>
        </div>
      )}
    </div>
  );
};

export default Profile;

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoginData } from '@types';
import useAuth from '@hooks/useAuth';
import { isLoginRemembered } from '@services/auth';
import { FlexGrid, Row, Column } from '@carbon/react';
import CenteredContent from '@components/CenteredContent';
import Card from '@components/Card';
import LoginForm from './LoginForm';

const AUTH_ROUTES = ['/login', '/signup', '/signup/verify', '/forgot-password', '/reset-password'];

const LoginPage = () => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (data: LoginData) => {
    try {
      setLoading(true);
      const response = await auth.login(data);

      if (!response.success) {
        setError(response.errorMessage || 'Something went wrong');
        setLoading(false);
      } else {
        const origin = location.state?.from?.pathname || '/search';
        // instead of redirecting to auth routes redirect to search page
        if (AUTH_ROUTES.includes(origin)) {
          navigate('/search', { replace: true });
        } else {
          navigate(origin, { replace: true });
        }
      }

      return response.success;
    } catch (err: unknown) {
      setLoading(false);
      setError('Something went wrong');

      return false;
    }
  };

  return (
    <CenteredContent>
      <FlexGrid condensed>
        <Row>
          <Column sm={4} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 5 }}>
            <Card>
              <LoginForm
                loading={loading}
                errorMessage={error}
                onSubmit={handleSubmit}
                loginRemembered={isLoginRemembered()}
              />
            </Card>
          </Column>
        </Row>
      </FlexGrid>
    </CenteredContent>
  );
};

export default LoginPage;

import { useRef, useReducer, useEffect } from 'react';
import { useIsMounted } from 'usehooks-ts';

interface ImageOnLoadOptions {
  delayLoading?: boolean;
  delayLoadingTime?: number;
  fallback?: string;
}

interface ImageDimension {
  width: number;
  height: number;
}

interface ImageState {
  src: string;
  loading: boolean;
  loaded: boolean;
  error: boolean;
  dimension: ImageDimension;
}

type ImageActionNew = {
  type: 'NEW';
  payload: {
    src: string;
  };
};

type ImageActionLoading = {
  type: 'LOADING';
};

type ImageActionLoaded = {
  type: 'LOADED';
  payload: Pick<ImageOnLoadOptions, 'fallback'> & {
    img: HTMLImageElement;
  };
};

type ImageActionError = {
  type: 'ERROR';
  payload: Pick<ImageOnLoadOptions, 'fallback'>;
};

type ImageAction = ImageActionNew | ImageActionLoading | ImageActionLoaded | ImageActionError;

const ACTIONS = {
  NEW: 'NEW',
  LOADED: 'LOADED',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
} as const;

const reducer = (state: ImageState, action: ImageAction) => {
  switch (action.type) {
    case ACTIONS.NEW:
      return { ...state, src: action.payload.src };
    case ACTIONS.LOADED:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: state.src === action.payload.fallback,
        dimension: {
          width: action.payload.img.naturalWidth || 0,
          height: action.payload.img.naturalHeight || 0,
        },
      };
    case ACTIONS.LOADING:
      if (state.loaded || state.error) {
        return state;
      }

      return { ...state, loading: true };
    case ACTIONS.ERROR:
      if (action.payload.fallback) {
        return {
          ...state,
          src: action.payload.fallback,
        };
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    default:
      return state;
  }
};

const useImageOnLoad = (src: string, options?: ImageOnLoadOptions) => {
  const isMounted = useIsMounted();
  const mergedOptions: ImageOnLoadOptions = {
    delayLoading: true,
    delayLoadingTime: 200,
    fallback: '',
    ...options,
  };

  const timerId = useRef<NodeJS.Timeout>();
  const [state, dispatch] = useReducer(reducer, {
    src,
    loading: !mergedOptions.delayLoading,
    loaded: false,
    error: false,
    dimension: {
      width: 0,
      height: 0,
    },
  });

  useEffect(() => {
    if (src !== state.src) {
      dispatch({ type: ACTIONS.NEW, payload: { src } });
    }
  }, [src]);

  useEffect(() => {
    const img = new Image();
    img.src = state.src;
    img.onload = () => {
      if (isMounted()) {
        dispatch({ type: ACTIONS.LOADED, payload: { ...mergedOptions, img } });
      }
    };
    img.onerror = () => {
      if (isMounted()) {
        dispatch({ type: ACTIONS.ERROR, payload: mergedOptions });
      }
    };

    if (mergedOptions.delayLoading) {
      timerId.current = setTimeout(() => {
        dispatch({ type: ACTIONS.LOADING });
      }, mergedOptions.delayLoadingTime);
    }

    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
      }
    };
  }, [state.src]);

  return {
    loading: state.loading,
    loaded: state.loaded,
    error: state.error,
    dimension: state.dimension,
  };
};

export default useImageOnLoad;

import { MATCH_RESULT_TYPE_MAP, SEARCH_ACTIVITY_TYPE_MAP } from '@mantis/data';
import client from '@services/client';
import { gql } from '@services/gql';
import { RecentSearchActivity } from '../types';

const FETCH_RECENT_ACTIVITIES = gql(/* GraphQL */ `
  query fetchRecentActivities($userId: ID!) {
    searchActivities(filter: { user: $userId }, limit: 10) {
      items {
        id
        date
        result
        type
      }
    }
  }
`);

const fetchRecentActivities = async (userId: string): Promise<RecentSearchActivity[]> => {
  const response = await client.query({ query: FETCH_RECENT_ACTIVITIES, variables: { userId } });

  return response.data.searchActivities.items.map((item) => ({
    ...item,
    result: MATCH_RESULT_TYPE_MAP[item.result],
    type: SEARCH_ACTIVITY_TYPE_MAP[item.type],
  }));
};

export default fetchRecentActivities;

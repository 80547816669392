import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoginData } from '@types';
import Heading from '@components/Heading';
import Navigate from '@components/Navigate';
import ErrorMessage from '@components/ErrorMessage';
import { Divider } from '@components/Form';
import {
  Layer,
  Form,
  Stack,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  InlineLoading,
} from '@carbon/react';
import styles from './loginForm.module.scss';

interface Fields {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface LoginFormProps {
  onSubmit: (data: LoginData) => boolean | Promise<boolean>;
  loading?: boolean;
  errorMessage?: string;
  loginRemembered?: boolean;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().trim().required('Email is required').email('Invalid email'),
  password: Yup.string().trim().required('Password is required'),
  rememberMe: Yup.bool().required(),
});

const LoginForm = ({
  onSubmit,
  loading = false,
  errorMessage = '',
  loginRemembered = true,
}: LoginFormProps) => {
  const initialValues: Fields = { email: '', password: '', rememberMe: loginRemembered };
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldTouched } =
    useFormik({
      initialValues,
      validationSchema: LoginSchema,
      onSubmit: async (data: Fields) => {
        const successful = await onSubmit({
          email: data.email.trim(),
          password: data.password.trim(),
          rememberMe: data.rememberMe,
        });

        // reset password field if login failed
        if (!successful) {
          setFieldValue('password', '');
          setFieldTouched('password', false);
        }
      },
    });

  return (
    <Form onSubmit={handleSubmit} className={styles.form}>
      <Stack gap={6}>
        <Heading>Log in to IBM Mantis</Heading>
        {errorMessage && !loading && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Layer>
          <TextInput
            id="email"
            labelText="Email"
            placeholder="email@test.com"
            title="User email"
            value={values.email}
            onChange={handleChange}
            invalid={errors.email && touched.email}
            invalidText={errors.email}
          />
        </Layer>
        <Layer>
          <PasswordInput
            id="password"
            placeholder="Password"
            title="User password"
            value={values.password}
            onChange={handleChange}
            invalid={errors.password && touched.password}
            invalidText={errors.password}
            labelText={
              <div className={styles.passwordLabel}>
                Password
                <Navigate to="/forgot-password" className={styles.forgotPassword}>
                  Forgot password ?
                </Navigate>
              </div>
            }
          />
        </Layer>
        <Checkbox
          id="rememberMe"
          labelText="Remember me"
          checked={values.rememberMe}
          onChange={handleChange}
        />
        {!loading ? (
          <Button type="submit">Log in</Button>
        ) : (
          <InlineLoading description="Log in..." />
        )}
      </Stack>
      <Divider />
      <div>
        Don&apos;t have an account ? <Navigate to="/signup">Sing up</Navigate>
      </div>
    </Form>
  );
};

export default LoginForm;

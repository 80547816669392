import errorType from '@mantis/errors';
import client from '@services/client';
import { gql } from '@services/gql';
import parseError from '@utils/parseError';
import { CreateOrganizationData } from '../../types';

const CREATE_ORGANIZATION = gql(/* GraphQL */ `
  mutation createOrganization(
    $name: String!
    $orgAdminName: String
    $orgAdminEmail: EmailAddress!
    $sendNotification: Boolean!
    $contract: ContractInput
  ) {
    organizationCreate(
      data: {
        name: $name
        admin: { name: $orgAdminName, email: $orgAdminEmail }
        contract: $contract
        sendNotification: $sendNotification
      }
    ) {
      id
    }
  }
`);

const createOrganization = async (data: CreateOrganizationData): Promise<string> => {
  try {
    const response = await client.mutate({
      mutation: CREATE_ORGANIZATION,
      variables: {
        name: data.name,
        orgAdminName: data.admin.name,
        orgAdminEmail: data.admin.email,
        sendNotification: data.sendNotification,
        contract: data.contract,
      },
    });

    if (!response.data) {
      throw new Error('Something went wrong');
    }

    return response.data.organizationCreate.id;
  } catch (error: unknown) {
    const parsedError = parseError(error);

    if (parsedError.type === errorType.VALIDATION_ERROR) {
      throw new Error(parsedError.message);
    }

    throw new Error('Something went wrong');
  }
};

export default createOrganization;

import { useNavigate as useRouterNavigation, useLocation } from 'react-router-dom';

const useNavigate = () => {
  const location = useLocation();
  const navigate = useRouterNavigation();

  return (to: string) => {
    navigate(to, { state: { from: location } });
  };
};

export default useNavigate;

import React from 'react';
import { SortOrder } from '@types';
import type { TableClasses } from './DataTable';

interface TableContext {
  sortBy: string;
  order: SortOrder;
  fetching: boolean;
  isSelectable: boolean;
  selected: string[];
  searchPlaceholder: string;
  classes: TableClasses;
  subscribe: (id: string) => void;
  unsubscribe: (id: string) => void;
  select: (id: string) => void;
  refresh: () => void;
  search: (text: string) => void;
  isSelected: (id: string) => void;
  setSortBy: (field: string) => void;
}

export default React.createContext<TableContext>({
  sortBy: '',
  order: 'ASC',
  fetching: false,
  isSelectable: false,
  selected: [],
  searchPlaceholder: '',
  classes: {},
  subscribe: () => {},
  unsubscribe: () => {},
  select: () => {},
  refresh: () => {},
  search: () => {},
  isSelected: () => {},
  setSortBy: () => {},
});

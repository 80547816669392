import React from 'react';
import capitalize from 'lodash/capitalize';
import { Layer, ProgressBar, Tag } from '@carbon/react';
import { ContractStatus } from '@types';
import formatNumber from '@utils/formatNumber';
import { formatDate } from '@utils/formatDate';
import percentage from '@utils/percentage';
import styles from './contract.module.scss';

interface ContractProps {
  start: string;
  end: string;
  status: ContractStatus;
  count: number;
  usage: number;
}

const COLOR_TYPES = {
  active: 'cyan',
  pending: 'warm-gray',
  expired: 'red',
} as const;

const Contract = ({ start, end, status, count, usage }: ContractProps) => (
  <div>
    <h4 className={styles.heading}>
      <span className={styles.label}>{`${formatDate(start)} - ${formatDate(end)}`}</span>
      <Tag type={COLOR_TYPES[status]} className={styles.tag} size="sm">
        {capitalize(status)}
      </Tag>
    </h4>
    <Layer>
      <ProgressBar
        label={`Contract ${start} - ${end}`}
        hideLabel
        max={count}
        value={usage}
        helperText={`${formatNumber(usage)}/${formatNumber(count)} (${percentage(usage, count)}%)`}
      />
    </Layer>
  </div>
);

export default Contract;

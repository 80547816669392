import client from '@services/client';
import { gql } from '@services/gql';
import { SelectOption } from '@types';

const FETCH_ORGANIZATIONS = gql(/* GraphQL */ `
  query fetchOrganizationsOptionsForHistory {
    organizationsList {
      items {
        id
        name
      }
    }
  }
`);

const fetchOrganizations = async (): Promise<SelectOption[]> => {
  const response = await client.query({ query: FETCH_ORGANIZATIONS });

  return response.data.organizationsList.items.map((organization) => ({
    value: organization.id,
    label: organization.name,
  }));
};

export default fetchOrganizations;

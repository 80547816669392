import { USER_ROLE_MAP } from '@mantis/data';
import client from '@services/client';
import { gql } from '@services/gql';
import { EditUserData } from '../types';

const UPDATE_USER = gql(/* GraphQL */ `
  mutation updateUser($id: ID!, $data: UserUpdateInput!) {
    userUpdate(id: $id, data: $data) {
      id
    }
  }
`);

const updateUser = (id: string, data: EditUserData) =>
  client.mutate({
    mutation: UPDATE_USER,
    variables: {
      id,
      data: {
        name: data.name || '',
        role: USER_ROLE_MAP[data.role],
      },
    },
  });

export default updateUser;

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useIsFirstRender } from 'usehooks-ts';
import { Add } from '@carbon/icons-react';
import {
  Button,
  DataTableSkeleton,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  Table as CarbonTable,
  TableSelectAll,
  TableBatchActions,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  Pagination,
} from '@carbon/react';
import { SortOption, OffsetPaginationData } from '@types';
import SearchBar from './SearchBar';
import RefreshButton from './RefreshButton';
import TableContext from './TableContext';
import TableBodySkeleton from './TableBodySkeleton';
import useTableState from './useTableState';
import styles from './dataTable.module.scss';

export interface Header {
  id: string;
  value: string | React.ReactElement;
  isSortable?: boolean;
}

export interface TableClasses {
  toolbar?: string;
  refreshButton?: string;
}

interface TableProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  searchPlaceholder?: string;
  buttonText?: string;
  headers: Header[];
  loading?: boolean;
  fetching?: boolean;
  initialSort?: SortOption;
  toolbar?: React.ReactElement;
  isSelectable?: boolean;
  isSearchable?: boolean;
  batchActions?: React.ReactNode;
  totalItems?: number;
  children: React.ReactNode;
  page?: number;
  initialPageSize?: 10 | 25 | 50;
  pagination?: 'offset' | 'cursor';
  classes?: TableClasses;
  onSearch?: (text: string) => void;
  onButtonClick?: () => void;
  onSort?: (sort: SortOption) => void;
  onNavigation?: (data: OffsetPaginationData) => void;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onPageSizeChange?: (size: number) => void;
  onRefresh?: () => void;
}

const DataTable = ({
  title,
  description,
  searchPlaceholder = 'Full text search',
  buttonText,
  headers,
  initialSort,
  loading = false,
  fetching = false,
  toolbar,
  isSearchable = false,
  isSelectable = false,
  children,
  batchActions,
  totalItems = 0,
  page,
  initialPageSize = 10,
  pagination = 'offset',
  onButtonClick,
  onSearch,
  onSort,
  onPageSizeChange,
  onNavigation,
  onNextPage,
  onPreviousPage,
  onRefresh,
  classes,
}: TableProps) => {
  const [internalPage, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const isFirstRender = useIsFirstRender();
  const showToolbar = !!toolbar || isSearchable || isSelectable || !!buttonText || !!onRefresh;
  const {
    sortBy,
    order,
    selected,
    setSortBy,
    subscribe,
    unsubscribe,
    select,
    selectAll,
    resetAllSelection,
    isSelected,
    isAllSelected,
  } = useTableState({
    initialSortBy: initialSort?.field,
    initialOrder: initialSort?.order,
  });

  // page can be controlled by prop or internally. Prefer prop value if provided
  const pageValue = page || internalPage;

  // trigger sorting callback
  useEffect(() => {
    if (onSort && sortBy && !isFirstRender) {
      onSort({ field: sortBy, order });
    }
  }, [sortBy, order]);

  const handleSort = (field: string) => () => {
    setSortBy(field);
  };

  const handleNavigation = (data: OffsetPaginationData) => {
    if (!page) {
      setPage(data.page);
    }

    if (data.pageSize !== pageSize && onPageSizeChange) {
      // page size was changed
      setPageSize(data.pageSize);
      onPageSizeChange(data.pageSize);

      return;
    }

    if (pagination === 'offset' && onNavigation) {
      onNavigation({ page: data.page, pageSize: data.pageSize });
    }
    if (pagination === 'cursor' && onNextPage && onPreviousPage) {
      if (data.page > pageValue) {
        onNextPage();
      } else {
        onPreviousPage();
      }
    }
  };

  const handleRefresh = useCallback(() => {
    if (onRefresh) {
      if (!page) {
        setPage(1);
      }
      onRefresh();
    }
  }, [onRefresh, setPage, page]);

  // memoize the full context value
  const contextValue = useMemo(
    () => ({
      sortBy,
      order,
      fetching,
      isSelectable,
      selected,
      searchPlaceholder,
      classes: classes || {},
      setSortBy,
      subscribe,
      unsubscribe,
      select,
      refresh: handleRefresh,
      search: (value: string) => (onSearch ? onSearch(value) : null),
      isSelected,
    }),
    [
      sortBy,
      order,
      fetching,
      isSelectable,
      selected,
      searchPlaceholder,
      classes,
      setSortBy,
      subscribe,
      unsubscribe,
      select,
      isSelected,
      onSearch,
      handleRefresh,
    ]
  );

  if (loading) {
    return (
      <div className={styles.skeleton}>
        <DataTableSkeleton showToolbar={showToolbar} columnCount={headers.length} rowCount={10} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <TableContext.Provider value={contextValue}>
        <TableContainer title={title} description={description} className={styles.container}>
          {showToolbar && (
            <TableToolbar>
              {isSelectable && batchActions && (
                <TableBatchActions
                  onCancel={resetAllSelection}
                  totalSelected={selected.length}
                  shouldShowBatchActions={selected.length > 0}
                >
                  {batchActions}
                </TableBatchActions>
              )}
              {toolbar}
              {!toolbar && (isSearchable || buttonText || onRefresh) && (
                <TableToolbarContent className={classes?.toolbar}>
                  {isSearchable && onSearch && <SearchBar />}
                  {onRefresh && (
                    <RefreshButton className={classes?.refreshButton} primary={!buttonText} />
                  )}
                  {buttonText && (
                    <Button onClick={onButtonClick} renderIcon={Add}>
                      {buttonText}
                    </Button>
                  )}
                </TableToolbarContent>
              )}
            </TableToolbar>
          )}
          <CarbonTable size="lg">
            <TableHead>
              <TableRow>
                {isSelectable && (
                  <TableSelectAll
                    id="all"
                    name="select-all"
                    ariaLabel="Select all rows"
                    checked={isAllSelected()}
                    indeterminate={!isAllSelected() && selected.length > 0}
                    onSelect={selectAll}
                  />
                )}
                {headers.map((header) => (
                  <TableHeader
                    id={header.id}
                    key={header.id}
                    isSortable={header.isSortable || false}
                    isSortHeader={header.id === sortBy}
                    sortDirection={order}
                    onClick={handleSort(header.id)}
                    className={styles.cell}
                  >
                    {header.value}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={styles.tableBody}>
              {React.Children.count(children) < 3 && fetching ? (
                <TableBodySkeleton
                  columnCount={headers.length}
                  isSelectable={isSelectable}
                  rowCount={10}
                />
              ) : (
                children
              )}
            </TableBody>
          </CarbonTable>
        </TableContainer>
      </TableContext.Provider>
      {!loading && totalItems !== 0 && (
        <Pagination
          totalItems={totalItems}
          pageSize={pageSize}
          pageSizes={[10, 25, 50]}
          page={pageValue}
          onChange={handleNavigation}
          pageInputDisabled={pagination === 'cursor'}
        />
      )}
    </div>
  );
};

export default React.memo(DataTable);

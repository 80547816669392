import React, { useEffect, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Stack, TextInput, Dropdown } from '@carbon/react';
import { UserRole } from '@types';
import Modal from '@components/Modal';
import ErrorMessage from '@components/ErrorMessage';
import { EditUserData } from './types';

export interface UserData {
  name: string;
  email: string;
  role: UserRole;
}

interface EditUserProps {
  isOpen: boolean;
  loading: boolean;
  user: UserData;
  loggedInUserRole: UserRole;
  errorMessage?: string;
  onClose: () => void;
  onSubmit: (data: EditUserData) => void;
}

interface Fields {
  name: string;
  role: UserRole;
}

interface RoleOption {
  value: UserRole;
  label: string;
}

interface SelectedItem {
  selectedItem: RoleOption;
}

const EditUserSchema = Yup.object().shape({
  name: Yup.string().trim(),
  role: Yup.string()
    .trim()
    .oneOf(['admin', 'user', 'organizationAdmin'])
    .required('User role is required'),
});

const EditUser = ({
  isOpen,
  user,
  errorMessage,
  loading,
  loggedInUserRole,
  onClose,
  onSubmit,
}: EditUserProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: { name: user.name, role: user.role },
    validationSchema: EditUserSchema,
    onSubmit: async (data: Fields) => {
      if (!loading) {
        onSubmit({
          name: data.name.trim(),
          role: data.role,
        });
      }
    },
  });

  // update field inputs if user prop is changed
  useEffect(() => {
    if (values.name !== user.name || values.role !== user.role) {
      setValues({ name: user.name, role: user.role });
    }
  }, [user.name, user.role]);

  // reset form when it's re-opened
  useEffect(() => {
    if (isOpen && (values.name !== user.name || values.role !== user.role)) {
      resetForm({
        values: {
          name: user.name,
          role: user.role,
        },
      });
    }
  }, [isOpen]);

  const handleRoleSelection = useCallback(({ selectedItem }: SelectedItem) => {
    setFieldValue('role', selectedItem.value);
  }, []);

  const roleOptions = useMemo(() => {
    const options: RoleOption[] = [{ value: 'user', label: 'User' }];

    if (loggedInUserRole === 'admin') {
      options.push({
        value: 'admin',
        label: 'Admin',
      });
    }

    options.push({
      value: 'organizationAdmin',
      label: loggedInUserRole === 'admin' ? 'Organization admin' : 'Admin',
    });

    return options;
  }, [loggedInUserRole]);

  const selectedRole: RoleOption = useMemo(
    () => roleOptions.find((option) => option.value === values.role) || roleOptions[0],
    [values.role, roleOptions]
  );

  return (
    <Modal
      isOpen={isOpen}
      loading={loading}
      loadingText="Submitting..."
      heading="Edit user"
      primaryButtonText="Submit"
      primaryButtonDisabled={values.name === user.name && values.role === user.role}
      secondaryButtonText="Cancel"
      selectorPrimaryFocus="input[id='name']"
      onClose={onClose}
      onSubmit={handleSubmit}
      size="md"
      visibleOverflow
    >
      <Form onSubmit={handleSubmit}>
        <Stack gap={6}>
          {errorMessage && !loading && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <TextInput
            id="name"
            labelText="Name (optional)"
            title="Name"
            value={values.name}
            onChange={handleChange}
            invalid={errors.name && touched.name}
            invalidText={errors.name}
          />
          <TextInput
            id="email"
            labelText="Email (read-only)"
            title="Email"
            value={user.email}
            readOnly
          />
          <Dropdown
            id="role"
            titleText="Role"
            label="Role"
            items={roleOptions}
            selectedItem={selectedRole}
            onChange={handleRoleSelection}
          />
        </Stack>
      </Form>
    </Modal>
  );
};

export default React.memo(EditUser);

import manPlaceholder from '@assets/man-placeholder.png';
import womanPlaceholder from '@assets/woman-placeholder.png';

const config = {
  logger: {
    endpoint: process.env.LOGGER_ENDPOINT,
    token: process.env.LOGGER_TOKEN,
  },
  env: process.env.NODE_ENV,
  isDev: process.env.NODE_ENV === 'development',
  personPlaceholder: {
    man: manPlaceholder,
    woman: womanPlaceholder,
  },
  mapBoxToken: process.env.MAP_BOX_TOKEN,
  breakPoints: {
    md: 672,
    lg: 1056,
    xlg: 1312,
    max: 1584,
  },
} as const;

export default config;

import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  FlexGrid,
  Row,
  Column,
  SkeletonText,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabsSkeleton,
} from '@carbon/react';
import LoadingWrapper from '@components/LoadingWrapper';
import Heading from '@components/Heading';
import Card from '@components/Card';
import CenteredContent from '@components/CenteredContent';
import { TabChangeEvent, User } from '@types';
import OrganizationStatistics from './OrganizationStatistics';
import UserStatistics from './UserStatistics';
import Contracts from './Contracts';
import styles from './statisticsPage.module.scss';
import { UserData, UserOrganization } from './types';

interface StatisticsPageProps {
  loading: boolean;
  loggedInUser: Nullable<User>;
}

const TABS = {
  ORGANIZATION: 'organization',
  USERS: 'users',
  CONTRACTS: 'contracts',
} as const;

const StatisticsPage = ({ loading, loggedInUser }: StatisticsPageProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const userOrganization: UserOrganization = useMemo(
    () => ({
      id: loggedInUser?.organization.id || '',
      creationYear: loggedInUser?.organization
        ? new Date(loggedInUser.organization.createdAt).getFullYear()
        : new Date().getFullYear(),
    }),
    [loggedInUser]
  );

  const userData: UserData = useMemo(
    () => ({
      id: loggedInUser?.id || '',
      organization: {
        id: loggedInUser?.organization.id || '',
        name: loggedInUser?.organization.name || '',
      },
      creationYear: loggedInUser
        ? new Date(loggedInUser.createdAt).getFullYear()
        : new Date().getFullYear(),
    }),
    [loggedInUser]
  );

  const activeTabIndex = useMemo(() => {
    if (searchParams.get('tab')) {
      const selectedTabIndex = Object.values(TABS).findIndex(
        (tab) => tab === searchParams.get('tab')
      );

      if (selectedTabIndex > -1) {
        return selectedTabIndex;
      }
    }

    return 0;
  }, [searchParams]);

  const handleTabChange = ({ selectedIndex }: TabChangeEvent) => {
    setSearchParams({ tab: Object.values(TABS)[selectedIndex] });
  };

  return (
    <CenteredContent>
      <FlexGrid condensed>
        <Row>
          <Column sm={4} md={8} lg={{ span: 12, offset: 2 }}>
            <Card className={styles.card}>
              {loading ? (
                <LoadingWrapper ariaLabel="Loading heading and tabs">
                  <SkeletonText className={styles.pageHeading} heading width="120px" />
                </LoadingWrapper>
              ) : (
                <Heading className={styles.pageHeading}>Statistics</Heading>
              )}
              <Tabs defaultSelectedIndex={activeTabIndex} onChange={handleTabChange}>
                {loading ? (
                  <TabsSkeleton className={styles.tabsSkeleton} />
                ) : (
                  <TabList aria-label="Statistics" activation="manual" className={styles.tabList}>
                    <Tab>Organization</Tab>
                    <Tab>Users</Tab>
                    <Tab>Contracts</Tab>
                  </TabList>
                )}
                <TabPanels>
                  <TabPanel className={styles.tabPanel}>
                    <OrganizationStatistics
                      loading={loading}
                      isOpen={activeTabIndex === 0}
                      isAdmin={loggedInUser?.role === 'admin'}
                      userOrganization={userOrganization}
                    />
                  </TabPanel>
                  <TabPanel className={styles.tabPanel}>
                    <UserStatistics
                      loading={loading}
                      isOpen={activeTabIndex === 1}
                      isAdmin={loggedInUser?.role === 'admin'}
                      userData={userData}
                    />
                  </TabPanel>
                  <TabPanel className={styles.tabPanel}>
                    <Contracts
                      loading={loading}
                      isAdmin={loggedInUser?.role === 'admin'}
                      organizationId={loggedInUser?.organization.id || ''}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Card>
          </Column>
        </Row>
      </FlexGrid>
    </CenteredContent>
  );
};

export default StatisticsPage;

import React from 'react';
import { SkeletonText, Stack } from '@carbon/react';

const ContractsSkeleton = () => (
  <Stack gap={3}>
    <SkeletonText width="120px" />
    <SkeletonText heading width="100%" />
    <SkeletonText heading width="100px" />
  </Stack>
);

export default ContractsSkeleton;

import React, { useState } from 'react';
import { SERVICE_PROVIDERS } from '@mantis/data';
import Modal from '@components/Modal';
import LinkButton from '@components/LinkButton';
import styles from './description.module.scss';

const Description = () => {
  const [listOpen, setListOpen] = useState(false);

  return (
    <React.Fragment>
      <p className={styles.container}>
        <b>Example: </b>
        {['John Doe', 'johnDoe123', 'john123@facebook', 'john.doe@example.com', '+123456789'].map(
          (value, index) => (
            <span key={value}>
              {index ? ', ' : ''}
              <i>&quot;{value}&quot;</i>
            </span>
          )
        )}
        . User ID must be used in combination with a service provider such as{' '}
        <i>&lsaquo;userId&rsaquo;@&lsaquo;service&rsaquo;</i>. Check{' '}
        <LinkButton onClick={() => setListOpen(true)}>list</LinkButton> of available service
        providers. For the use of location, please provide city and country.
      </p>
      <Modal
        isOpen={listOpen}
        heading="List of available service providers for user ID"
        allowCloseOnClickOutside
        passiveModal
        onClose={() => setListOpen(false)}
        size="sm"
      >
        {SERVICE_PROVIDERS.join(', ')}
      </Modal>
    </React.Fragment>
  );
};

export default Description;

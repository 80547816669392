import { MATCH_RESULT_TYPE_MAP, SEARCH_ACTIVITY_TYPE_MAP } from '@mantis/data';
import client from '@services/client';
import { gql } from '@services/gql';
import {
  CursorDataFetchResolverOptions,
  CursorDataFetchResolverResponse,
  SearchActivity,
} from '@types';
import { FetchSearchHistoryFilter } from '../types';

const FETCH_SEARCH_HISTORY = gql(/* GraphQL */ `
  query fetchSearchHistory(
    $user: ID
    $organization: ID
    $from: Date
    $to: Date
    $limit: Int!
    $after: ID
    $before: ID
  ) {
    searchActivities(
      filter: { user: $user, organization: $organization, from: $from, to: $to }
      limit: $limit
      after: $after
      before: $before
    ) {
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      items {
        id
        user
        organization
        date
        result
        type
      }
    }
  }
`);

const fetchSearchActivities = async (
  options: CursorDataFetchResolverOptions<FetchSearchHistoryFilter>
): Promise<CursorDataFetchResolverResponse<SearchActivity>> => {
  const response = await client.query({
    query: FETCH_SEARCH_HISTORY,
    variables: {
      user: options.filterBy?.user || null,
      organization: options.filterBy?.organization || null,
      from: options.filterBy?.from || null,
      to: options.filterBy?.to || null,
      limit: options.limit,
      after: options.after,
      before: options.before,
    },
  });

  const count = response.data?.searchActivities?.count || 0;
  const activities = response.data?.searchActivities?.items || [];
  const pageInfo = response.data?.searchActivities?.pageInfo;
  const data = activities.map((activity) => ({
    id: activity.id,
    user: activity.user,
    organization: activity.organization,
    date: activity.date,
    result: MATCH_RESULT_TYPE_MAP[activity.result],
    type: SEARCH_ACTIVITY_TYPE_MAP[activity.type],
  }));

  return {
    count,
    data,
    hasNextPage: pageInfo?.hasNextPage || false,
    hasPreviousPage: pageInfo?.hasPreviousPage || false,
    startCursor: pageInfo?.startCursor || null,
    endCursor: pageInfo?.endCursor || null,
  };
};

export default fetchSearchActivities;

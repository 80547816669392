import React, { useContext } from 'react';
import { TableToolbarSearch } from '@carbon/react';
import TableContext from './TableContext';

const SearchBar = () => {
  const { search, searchPlaceholder } = useContext(TableContext);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    search(event.target.value);
  };

  return <TableToolbarSearch persistent placeholder={searchPlaceholder} onChange={handleSearch} />;
};

export default SearchBar;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { SelectOption } from '@types';
import usePrevious from '@hooks/usePrevious';
import useToast from '@hooks/useToast';
import ComboBox from '@components/ComboBox';
import Select from '@components/Select';
import fetchOrganizations from '../helpers/fetchOrganizations';
import { OrganizationStatisticsFilter, OrganizationSelectOption, UserOrganization } from '../types';
import styles from './organizationStatistics.module.scss';

interface FiltersProps {
  isAdmin: boolean;
  loading: boolean;
  userOrganization: UserOrganization;
  onChange: (filter: OrganizationStatisticsFilter) => void;
}

const Filters = ({ isAdmin, loading, userOrganization, onChange }: FiltersProps) => {
  const currentYear = new Date().getFullYear();
  const previousOrganizationId = usePrevious(userOrganization.id);
  const toast = useToast();
  const [fetching, setFetching] = useState(true);
  const [organizations, setOrganizations] = useState<OrganizationSelectOption[]>([]);
  const [filter, setFilter] = useState<OrganizationStatisticsFilter>({
    organization: userOrganization.id,
    year: currentYear,
  });

  const yearOptions = useMemo(() => {
    const options: SelectOption[] = [];

    // logged user is NOT admin - from logged in user org creation year
    if (!isAdmin) {
      for (let year = userOrganization.creationYear; year <= currentYear; year += 1) {
        options.push({ label: year.toString(), value: year.toString() });
      }

      return options;
    }

    // logged user is admin - from selected org creation year
    const selectedOrganization = organizations.find(
      (organization) => organization.value === filter.organization
    );

    if (!selectedOrganization) {
      return [
        {
          label: currentYear.toString(),
          value: currentYear.toString(),
        },
      ];
    }

    for (let year = selectedOrganization.createdYear; year <= currentYear; year += 1) {
      options.push({ label: year.toString(), value: year.toString() });
    }

    return options;
  }, [filter.organization, filter.year, organizations, currentYear]);

  // update org filter if "organization" prop is changed
  useEffect(() => {
    if (userOrganization.id && userOrganization.id !== previousOrganizationId) {
      setFilter({ organization: userOrganization.id, year: filter.year });
    }
  }, [userOrganization.id, previousOrganizationId, filter.year]);

  // fetch filter options
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const fetchedOrganizations = await fetchOrganizations();
        setOrganizations(fetchedOrganizations);
      } catch (error) {
        toast.warning({ title: 'Filter options', subtitle: 'Failed to fetch organizations' });
      } finally {
        setFetching(false);
      }
    };

    if (isAdmin && !loading) {
      fetchItems();
    } else {
      setFetching(false);
    }
  }, [isAdmin, loading]);

  const handleOrganizationChange = useCallback(
    (selectedOrganizationId: string) => {
      const selectedOrganization = organizations.find(
        (organization) => organization.value === selectedOrganizationId
      );

      const newFilter: OrganizationStatisticsFilter = {
        organization: selectedOrganizationId,
        year: filter.year,
      };

      // if selected year is before org creation year -> use current year instead of previously selected year
      if (selectedOrganization && selectedOrganization.createdYear > filter.year) {
        newFilter.year = currentYear;
      }

      setFilter(newFilter);
      onChange(newFilter);
    },
    [onChange, filter.year]
  );

  const handleYearChange = useCallback(
    (value: string) => {
      const newFilter = { organization: filter.organization, year: parseInt(value, 10) };

      setFilter(newFilter);
      onChange(newFilter);
    },
    [onChange, filter.organization]
  );

  return (
    <div className={styles.filters}>
      {isAdmin && (
        <ComboBox
          id="organization"
          title="Organization"
          hideLabel
          placeholder="Filter by organization"
          selected={filter.organization}
          items={organizations}
          loading={loading || fetching}
          onChange={handleOrganizationChange}
        />
      )}
      <Select
        id="year"
        title="Year"
        hideLabel
        selected={filter.year.toString()}
        items={yearOptions}
        loading={loading || fetching}
        onChange={handleYearChange}
      />
    </div>
  );
};

export default Filters;

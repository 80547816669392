import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { useState, useLayoutEffect } from 'react';

interface Options {
  debounce?: boolean;
  debounceDelay?: number;
  throttle?: boolean;
  throttleDelay?: number;
}

interface Size {
  width: number;
  height: number;
}

const useComponentSize = <T extends HTMLElement>(
  options: Options = {}
): [(node: T | null) => void, Size] => {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (ref) {
      const mergedOptions = {
        debounce: false,
        debounceDelay: 200,
        throttle: false,
        throttleDelay: 200,
        ...options,
      };

      const handleResize = () => {
        setSize({
          width: ref.offsetWidth,
          height: ref.offsetHeight,
        });
      };

      let resizeObserver: ResizeObserver;
      if (mergedOptions.debounce) {
        resizeObserver = new ResizeObserver(debounce(handleResize, mergedOptions.debounceDelay));
      } else if (mergedOptions.throttle) {
        resizeObserver = new ResizeObserver(throttle(handleResize, mergedOptions.throttleDelay));
      } else {
        resizeObserver = new ResizeObserver(handleResize);
      }

      resizeObserver.observe(ref);

      return () => {
        resizeObserver.disconnect();
        setSize({
          width: 0,
          height: 0,
        });
      };
    }

    return undefined;
  }, [ref, options.debounce, options.debounceDelay, options.throttle, options.throttleDelay]);

  return [setRef, size];
};

export default useComponentSize;

import React from 'react';
import { CheckmarkFilled } from '@carbon/icons-react';
import Navigate from '@components/Navigate';
import styles from './passwordChanged.module.scss';

const PasswordChanged = () => (
  <div className={styles.container}>
    <div className={styles.headingWrapper}>
      <CheckmarkFilled size={20} className={styles.icon} />
      <h1 className={styles.heading}>Password successfully changed</h1>
    </div>
    <p>
      Your password was successfully changed. You can <Navigate to="/login">login</Navigate> with
      your new password.
    </p>
  </div>
);

export default PasswordChanged;

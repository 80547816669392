import { useReducer, useCallback } from 'react';

interface ModalState<T> {
  id: Nullable<T>;
  isOpen: boolean;
}

interface ActionOpen<T> {
  type: 'OPEN';
  payload: {
    id: T;
  };
}

interface ActionClose {
  type: 'CLOSE';
}

type ModalAction<T> = ActionOpen<T> | ActionClose;

const reducer = <T>(state: ModalState<T>, action: ModalAction<T>): ModalState<T> => {
  switch (action.type) {
    case 'OPEN':
      return {
        isOpen: true,
        id: action.payload.id,
      };
    case 'CLOSE':
      return {
        isOpen: false,
        id: null,
      };
    default:
      return state;
  }
};

const useModal = <T>() => {
  const [state, dispatch] = useReducer<React.Reducer<ModalState<T>, ModalAction<T>>>(reducer, {
    isOpen: false,
    id: null,
  });

  const openModal = useCallback((id: T) => {
    dispatch({ type: 'OPEN', payload: { id } });
  }, []);

  const closeModal = useCallback(() => {
    dispatch({ type: 'CLOSE' });
  }, []);

  const isModalOpen = useCallback(
    (id: T) => state.isOpen && state.id === id,
    [(state.id, state.isOpen)]
  );

  return { openModal, closeModal, isModalOpen };
};

export default useModal;

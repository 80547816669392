import React from 'react';
import { Link } from '@carbon/react';
import { PersonFieldMetadata } from '@mantis/types';
import baseStyles from '@styles/base.module.scss';
import spacing from '@styles/spacing.module.scss';
import Navigate from '@components/Navigate';
import useBreakpoint from '@hooks/useBreakpoint';
import MetadataInfo from '../MetadataInfo';
import RecordTags, { Tag } from '../RecordTags';

interface ClickableRecordProps {
  link: string;
  children: string;
  tags?: Tag[];
  searchLink?: boolean;
  metadata: PersonFieldMetadata;
}

const ClickableRecord = ({
  link,
  children,
  tags = [],
  searchLink = true,
  metadata,
}: ClickableRecordProps) => {
  const { breakpointDown } = useBreakpoint();

  if (breakpointDown('sm')) {
    // mobile
    return (
      <div className={baseStyles.dFlexColumn}>
        <div className={baseStyles.spaceBetween}>
          {searchLink ? (
            <Navigate to={link}>{children}</Navigate>
          ) : (
            <Link href={link} target="_blank">
              {children}
            </Link>
          )}
          {(metadata.validSince || metadata.lastSeen) && (
            <div className={spacing.ml3}>
              <MetadataInfo metadata={metadata} />
            </div>
          )}
        </div>
        <RecordTags metadata={metadata} tags={tags} />
      </div>
    );
  }

  return (
    <div className={baseStyles.verticalCenter}>
      {searchLink ? (
        <Navigate to={link}>{children}</Navigate>
      ) : (
        <Link href={link} target="_blank">
          {children}
        </Link>
      )}
      <RecordTags metadata={metadata} tags={tags} className={spacing.ml3} />
      {(metadata.validSince || metadata.lastSeen) && (
        <div className={spacing.ml3}>
          <MetadataInfo metadata={metadata} />
        </div>
      )}
    </div>
  );
};

export default ClickableRecord;

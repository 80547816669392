import axios from 'axios';
import errorType from '@mantis/errors';
import { LoginData, SignupData, SuccessResponse, ErrorResponse } from '@types';

const REFRESH_TOKEN_KEY = 'refreshToken';
const REMEMBER_LOGIN_KEY = 'rememberLogin';

/**
 * Check if user checked checkbox to remember login
 * @returns {boolean}
 */
export const isLoginRemembered = (): boolean => {
  const storedSelection = localStorage.getItem(REMEMBER_LOGIN_KEY);

  // if there is no stored selection, return true by default
  if (storedSelection === null) {
    return true;
  }

  return Boolean(storedSelection);
};

/**
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.password
 * @param {boolean} data.rememberMe
 * @returns {Promise<object>}
 */
export const login = async (data: LoginData): Promise<SuccessResponse> => {
  try {
    const response = await axios.post<{ refreshToken: string }>('/auth/login', {
      username: data.email,
      password: data.password,
    });

    // store remember me selection
    localStorage.setItem(REMEMBER_LOGIN_KEY, data.rememberMe.toString());

    if (data.rememberMe) {
      // store refresh token
      localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refreshToken);
    } else {
      localStorage.removeItem(REFRESH_TOKEN_KEY);
    }

    return {
      success: true,
    };
  } catch (error: unknown) {
    if (axios.isAxiosError<ErrorResponse>(error)) {
      if (error.response?.status === 500) {
        const receivedErrorType = error.response?.data.type;

        if (receivedErrorType === errorType.LOGIN_INVALID_CREDENTIALS) {
          return {
            success: false,
            errorMessage: 'Invalid email or password',
            errorType: errorType.LOGIN_INVALID_CREDENTIALS,
          };
        }

        if (receivedErrorType === errorType.SIGNUP_PENDING_VERIFICATION) {
          return {
            success: false,
            errorMessage: 'Pending email verification',
            errorType: errorType.SIGNUP_PENDING_VERIFICATION,
          };
        }
      }
    }

    return {
      success: false,
      errorMessage: 'Something went wrong',
    };
  }
};

/**
 * @returns {Promise<boolean>}
 */
export const logout = async () => {
  try {
    await axios.post('/auth/logout');
    localStorage.removeItem(REFRESH_TOKEN_KEY);

    return true;
  } catch (error) {
    return false;
  }
};

/**
 * @param {object} data
 * @param {string} [data.name]
 * @param {string} data.email
 * @param {string} data.password
 * @returns {Promise<object>}
 */
export const signup = async (data: SignupData): Promise<SuccessResponse> => {
  try {
    await axios.post('/auth/signup', data);

    return {
      success: true,
    };
  } catch (error: unknown) {
    if (axios.isAxiosError<ErrorResponse>(error)) {
      if (error.response?.status === 500) {
        const receivedErrorType = error.response?.data.type;

        if (receivedErrorType === errorType.SIGNUP_USER_NOT_REGISTERED) {
          return {
            success: false,
            errorMessage: 'Unauthorized to use the app',
            errorType: errorType.SIGNUP_USER_NOT_REGISTERED,
          };
        }

        if (receivedErrorType === errorType.SIGNUP_USER_ALREADY_ACTIVE) {
          return {
            success: false,
            errorMessage: 'Account is already active',
            errorType: errorType.SIGNUP_USER_ALREADY_ACTIVE,
          };
        }
      }
    }

    return {
      success: false,
      errorMessage: 'Something went wrong',
    };
  }
};

/**
 * @param {string} email
 * @returns {Promise<boolean>}
 */
export const resendActivationMail = async (email: string): Promise<boolean> => {
  try {
    await axios.post('/auth/signup/resend', { email });

    return true;
  } catch (error) {
    return false;
  }
};

interface VerifyEmailResult {
  success: boolean;
  email?: string;
  errorType:
    | typeof errorType.SIGNUP_INVALID_VERIFICATION_LINK
    | typeof errorType.SIGNUP_EXPIRED_VERIFICATION_LINK;
}

interface VerifyEmailResponse extends SuccessResponse {
  email?: string;
}

/**
 * Verify email after signup
 * @param {string} context
 * @returns {Promise<object>}
 */
export const verifyEmail = async (context: string): Promise<VerifyEmailResponse> => {
  try {
    const result = await axios.post<VerifyEmailResult>('/auth/signup/verify', {
      context,
    });

    return result.data;
  } catch (error: unknown) {
    return {
      success: false,
      errorMessage: 'Something went wrong',
    };
  }
};

/**
 * @param {string} email
 * @returns {Promise<boolean>}
 */
export const forgotPassword = async (email: string): Promise<boolean> => {
  try {
    await axios.post('/auth/forgot-password', { email });

    return true;
  } catch (error) {
    return false;
  }
};

/**
 * @param {string} email
 * @returns {Promise<boolean>}
 */
export const resendPasswordResetLink = async (email: string): Promise<boolean> => {
  try {
    await axios.post('/auth/forgot-password/resend', { email });

    return true;
  } catch (error) {
    return false;
  }
};

/**
 * @param {string} context String generated and appended to url by AppId
 * @param {string} password New password
 * @returns {Promise<object>}
 */
export const resetPassword = async (
  context: string,
  password: string
): Promise<SuccessResponse> => {
  try {
    await axios.post('/auth/reset-password', { context, password });

    return {
      success: true,
    };
  } catch (error: unknown) {
    if (axios.isAxiosError<ErrorResponse>(error)) {
      if (error.response?.status === 500) {
        const receivedErrorType = error.response?.data.type;

        if (receivedErrorType === errorType.RESET_PASSWORD_INVALID_CONTEXT) {
          return {
            success: false,
            errorMessage: 'Invalid reset password link',
            errorType: errorType.RESET_PASSWORD_INVALID_CONTEXT,
          };
        }

        if (receivedErrorType === errorType.RESET_PASSWORD_EXPIRED_LINK) {
          return {
            success: false,
            errorMessage: 'Reset password link expired',
            errorType: errorType.RESET_PASSWORD_EXPIRED_LINK,
          };
        }
      }
    }

    return {
      success: false,
      errorMessage: 'Something went wrong',
    };
  }
};

/**
 * Refresh tokens
 * @returns {Promise<bool>}
 */
export const refreshTokens = async (): Promise<boolean> => {
  try {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

    if (!refreshToken) {
      return false;
    }

    const response = await axios.post<{ refreshToken: string }>('/auth/refresh-token', {
      refreshToken,
    });

    // store new refresh token
    localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refreshToken);

    return true;
  } catch (error) {
    localStorage.removeItem(REFRESH_TOKEN_KEY);

    return false;
  }
};

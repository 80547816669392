import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  firstName: Yup.string().trim().min(2, 'Must be at least 2 characters'),
  lastName: Yup.string().trim().min(2, 'Must be at least 2 characters'),
  middleName: Yup.string().trim(),
  age: Yup.string()
    .trim()
    .matches(
      /^((\d{2})|(\d{2}-\d{2}))$/,
      'Incorrect format. Must be a number in format xx or xx-xx'
    ),
  email: Yup.string().trim().email('Invalid email'),
  phone: Yup.string().trim(),
  username: Yup.string().trim().min(3, 'Must be at least 3 characters'),
  userId: Yup.string().trim(),
  userIdService: Yup.string().when('userId', {
    is: (userId: string) => userId,
    then: () => Yup.string().required('Field is required'),
  }),
  url: Yup.string().trim().url('Invalid URL'),
  country: Yup.string().trim(),
  state: Yup.string().trim(),
  city: Yup.string().trim(),
  street: Yup.string().trim(),
  zipCode: Yup.string().trim(),
  organization: Yup.string().trim(),
  industry: Yup.string().trim(),
});

export default formSchema;

import React from 'react';
import { Match } from '@mantis/types';
import { View } from '@carbon/icons-react';
import spacing from '@styles/spacing.module.scss';
import Card from '@components/Card';
import Navigate from '@components/Navigate';
import ProfileInfo, { Title } from '@components/ProfileInfo';
import ImageLoader from './ImageLoader';
import MatchProbability from './MatchProbability';
import styles from './searchResultCard.module.scss';

interface SearchResultCardProps {
  match: Match;
}

const SearchResultCard = ({ match }: SearchResultCardProps) => (
  <Card className={styles.card}>
    <div className={styles.container}>
      <ImageLoader
        images={match.images}
        gender={match.gender}
        alt={match.name}
        matchPointer={match.pointer}
      />
      <div className={styles.info}>
        <ProfileInfo
          profile={match}
          condensed
          header={
            <div className={styles.header}>
              <Navigate to={`/detail/${match.pointer}`} disableFocus noStyle>
                <Title>{match.name || '-'}</Title>
              </Navigate>
              <div className={styles.headerRight}>
                <span className={spacing.mr3}>
                  <MatchProbability probability={match.probability} />
                </span>
                <Navigate to={`/detail/${match.pointer}`} icon={View}>
                  View
                </Navigate>
              </div>
            </div>
          }
        />
      </div>
    </div>
  </Card>
);

export default SearchResultCard;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { SkeletonText } from '@carbon/react';
import { UserRole, SelectOption } from '@types';
import { RefreshButton } from '@components/DataTable';
import ComboBox from '@components/ComboBox';
import DatePicker from '@components/DatePicker';
import useBreakpoint from '@hooks/useBreakpoint';
import useToast from '@hooks/useToast';
import spacing from '@styles/spacing.module.scss';
import { FetchSearchHistoryFilter } from '../types';
import fetchOrganizations from '../helpers/fetchOrganizations';
import fetchUsers from '../helpers/fetchUsers';
import styles from './toolbar.module.scss';

interface ToolbarProps {
  userRole: UserRole;
  filteredBy: FetchSearchHistoryFilter;
  onFilter: (filter: FetchSearchHistoryFilter) => void;
}

const Toolbar = ({ userRole, filteredBy, onFilter }: ToolbarProps) => {
  const toast = useToast();
  const { breakpointDown, breakpointUp } = useBreakpoint();
  const [items, setItems] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const fetchedItems = userRole === 'admin' ? await fetchOrganizations() : await fetchUsers();
        setItems(fetchedItems);
      } catch (error) {
        toast.warning({ title: 'Filter options', subtitle: 'Failed to fetch' });
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  const filterByOrganization = useCallback(
    (organization: string) => {
      onFilter({ organization });
    },
    [onFilter]
  );

  const filterByUser = useCallback(
    (user: string) => {
      onFilter({ user });
    },
    [onFilter]
  );

  const filterByStartDate = useCallback(
    (date: Nullable<string>) => {
      onFilter({ from: date });
    },
    [onFilter]
  );

  const filterByEndDate = useCallback(
    (date: Nullable<string>) => {
      onFilter({ to: date });
    },
    [onFilter]
  );

  const maxDateForStartDate = useMemo(() => {
    if (filteredBy.to) {
      return DateTime.fromISO(filteredBy.to).toJSDate();
    }

    return DateTime.now().toJSDate();
  }, [filteredBy.to]);

  const maxDateForEndDate = useMemo(() => DateTime.now().toJSDate(), []);
  const minDateForEndDate = useMemo(() => {
    if (filteredBy.from) {
      return DateTime.fromISO(filteredBy.from).toJSDate();
    }

    return null;
  }, [filteredBy.from]);

  if (loading) {
    return <SkeletonText heading width="220px" className={spacing.ml5} />;
  }

  return (
    <div className={styles.toolbar}>
      <div className={styles.filterWrapper}>
        <div className={styles.filterRefresh}>
          {userRole === 'admin' && (
            <ComboBox
              id="organization"
              title="Organization"
              placeholder="Filter by organization"
              onChange={filterByOrganization}
              items={items}
              selected={filteredBy.organization || ''}
            />
          )}
          {userRole === 'organizationAdmin' && (
            <ComboBox
              id="user"
              title="User email"
              placeholder="Filter by user"
              onChange={filterByUser}
              items={items}
              selected={filteredBy.user || ''}
            />
          )}
          {breakpointDown('sm') && <RefreshButton className={styles.refreshButton} primary />}
        </div>
        <DatePicker
          id="startDate"
          title="Start date"
          onChange={filterByStartDate}
          maxDate={maxDateForStartDate}
          defaultValue={filteredBy.from ? DateTime.fromISO(filteredBy.from).toJSDate() : null}
        />
        <DatePicker
          id="endDate"
          title="End date"
          onChange={filterByEndDate}
          minDate={minDateForEndDate}
          maxDate={maxDateForEndDate}
          defaultValue={filteredBy.to ? DateTime.fromISO(filteredBy.to).toJSDate() : null}
        />
      </div>
      {breakpointUp('md') && (
        <RefreshButton
          className={styles.refreshButton}
          primary
          label={breakpointUp('lg') ? 'Refresh' : ''}
        />
      )}
    </div>
  );
};

export default Toolbar;

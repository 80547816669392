import client from '@services/client';
import { gql } from '@services/gql';
import { CONTRACT_STATUS_MAP } from '@mantis/data';
import { Contract } from '@types';
import { ContractData } from '../../types';

const SAVE_CONTRACTS = gql(/* GraphQL */ `
  mutation saveContracts($organization: ID!, $contracts: [ContractInput!]!) {
    organizationUpdate(id: $organization, data: { contracts: $contracts }) {
      contracts {
        id
        start
        end
        count
        status
        usage
      }
    }
  }
`);

const saveContracts = async (
  organizationId: string,
  contracts: ContractData[]
): Promise<Contract[]> => {
  const response = await client.mutate({
    mutation: SAVE_CONTRACTS,
    variables: {
      organization: organizationId,
      contracts: contracts.map((contract) => ({
        start: contract.start,
        end: contract.end,
        count: contract.count,
      })),
    },
  });

  return (response.data?.organizationUpdate?.contracts || []).map(({ status, ...rest }) => ({
    ...rest,
    status: CONTRACT_STATUS_MAP[status],
  }));
};

export default saveContracts;

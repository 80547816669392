const percentage = (partialValue: number, totalValue: number, round: boolean = true): number => {
  if (!totalValue) {
    return 0;
  }

  if (round) {
    return Math.round((100 * partialValue) / totalValue);
  }

  return parseFloat(((partialValue / totalValue) * 100).toFixed(2));
};

export default percentage;

import React from 'react';
import OrganizationsList from './OrganizationsList';

interface OrganizationsPageProps {
  loading: boolean;
}

const OrganizationsPage = ({ loading }: OrganizationsPageProps) => (
  <OrganizationsList loading={loading} />
);

export default OrganizationsPage;

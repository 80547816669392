import throttle from 'lodash/throttle';
import { useState, useCallback } from 'react';
import { useEventListener, useIsomorphicLayoutEffect, useIsMounted } from 'usehooks-ts';
import config from '@config';

const SIZES = ['sm', 'md', 'lg', 'xlg', 'max'] as const;
export type Size = typeof SIZES[number];

interface Breakpoint {
  breakpoint: Size;
  breakpointUp: (size: Size) => boolean;
  breakpointDown: (size: Size) => boolean;
}

const getCurrentBreakpoint = () => {
  let breakpoint: Size = 'lg';
  const { width } = window.screen;

  if (width < config.breakPoints.md) {
    breakpoint = 'sm';
  }
  if (width >= config.breakPoints.md && width < config.breakPoints.lg) {
    breakpoint = 'md';
  }
  if (width >= config.breakPoints.lg && width < config.breakPoints.xlg) {
    breakpoint = 'lg';
  }
  if (width >= config.breakPoints.xlg && width < config.breakPoints.max) {
    breakpoint = 'xlg';
  }
  if (width >= config.breakPoints.max) {
    breakpoint = 'max';
  }

  return breakpoint;
};

const useBreakpoint = (): Breakpoint => {
  const isMounted = useIsMounted();
  const [currentBreakpoint, setBreakpoint] = useState<Size>(getCurrentBreakpoint());

  const handleSize = () => {
    const breakpoint = getCurrentBreakpoint();

    if (currentBreakpoint !== breakpoint && isMounted()) {
      setBreakpoint(breakpoint);
    }
  };

  // use throttle to reduce number of updates
  useEventListener('resize', throttle(handleSize, 200));

  // set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, []);

  const breakpointUp = useCallback(
    (size: Size): boolean => {
      const sizeIndex = SIZES.findIndex((item) => item === size);
      const breakpointIndex = SIZES.findIndex((item) => item === currentBreakpoint);

      return sizeIndex <= breakpointIndex;
    },
    [currentBreakpoint]
  );

  const breakpointDown = useCallback(
    (size: Size): boolean => {
      const sizeIndex = SIZES.findIndex((item) => item === size);
      const breakpointIndex = SIZES.findIndex((item) => item === currentBreakpoint);

      return sizeIndex >= breakpointIndex;
    },
    [currentBreakpoint]
  );

  return { breakpoint: currentBreakpoint, breakpointUp, breakpointDown };
};

export default useBreakpoint;

import React from 'react';
import useBreakpoint from '@hooks/useBreakpoint';
import { Person } from '@mantis/types';
import Accordion from './Accordion';
import DetailTabs from './DetailTabs';

interface DetailsProps {
  person: Nullable<Person>;
  loading: boolean;
}

const Details = ({ loading, person }: DetailsProps) => {
  const { breakpointDown } = useBreakpoint();

  if (breakpointDown('md')) {
    // render accordion on small screen
    return <Accordion loading={loading} person={person} />;
  }

  // render tabs on big screen
  return <DetailTabs loading={loading} person={person} />;
};

export default React.memo(Details);

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import AuthContext from '@context/AuthContext';
import { User, LoginData } from '@types';
import useToast from '@hooks/useToast';
import * as auth from '@services/auth';
import client from '@services/client';
import loggedInUserVar from '@store/loggedInUser';
import fetchMeFn from './helpers/fetchMe';

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const toast = useToast();
  const user = useReactiveVar(loggedInUserVar);
  const [loading, setLoading] = useState(true);

  const setUser = (value: User | null) => {
    loggedInUserVar(value);
  };

  const fetchMe = async () => {
    try {
      const fetchedUser = await fetchMeFn();
      setUser(fetchedUser);
    } catch (error) {
      toast.error({ title: 'User profile', subtitle: 'Failed to fetch' });
    } finally {
      setLoading(false);
    }
  };

  // on page load -> fetch logged in user
  useEffect(() => {
    fetchMe();
  }, []);

  const login = useCallback(async (data: LoginData) => {
    const response = await auth.login(data);

    if (response.success) {
      await fetchMe();
    }

    return response;
  }, []);

  const logout = useCallback(async () => {
    const success = await auth.logout();

    if (success) {
      await client.resetStore();
      setUser(null);
    }

    return success;
  }, []);

  const refetchMe = useCallback(async () => {
    await fetchMe();
  }, []);

  // memoize the full context value
  const contextValue = useMemo(
    () => ({
      user,
      loading,
      login,
      logout,
      refetchMe,
    }),
    [user, loading, logout, login, refetchMe]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

import React from 'react';
import { Wikis, Identification } from '@carbon/icons-react';
import { Person, PersonUrl } from '@mantis/types';
import spacing from '@styles/spacing.module.scss';
import ClickableRecord from '../components/ClickableRecord';
import { Tag } from '../components/RecordTags';
import Section from '../components/Section';
import Records from '../components/Records';
import styles from './digitalFootprint.module.scss';

interface UrlGroup {
  title: string;
  items: PersonUrl[];
}

const GROUP_NAME_MAP = {
  backgroundReports: 'Background reports',
  contactDetails: 'Contact details',
  emailAddress: 'Email addresses',
  media: 'Media',
  personalProfiles: 'Personal profiles',
  professionalAndBusiness: 'Professional and business',
  publicRecords: 'Public records',
  publications: 'Publications',
  schoolAndClassmates: 'School and classmates',
  webpages: 'Webpages',
} as const;

const DigitalFootprint = ({ urls, userIds }: Pick<Person, 'urls' | 'userIds'>) => {
  const groupUrls = () => {
    const groups: UrlGroup[] = [];

    urls.forEach((url) => {
      const group = groups.find((item) => item.title === GROUP_NAME_MAP[url.category]);

      if (group) {
        group.items.push(url);
      } else {
        groups.push({ title: GROUP_NAME_MAP[url.category], items: [url] });
      }
    });

    return groups;
  };

  const getUrlTags = (url: PersonUrl) => {
    const tags: Tag[] = [];

    if (url.metadata.sponsored) {
      tags.push({ value: 'sponsored', type: 'red' });
    }

    return tags;
  };

  if (userIds.length === 0 && urls.length === 0) {
    return <span>No digital footprint were found</span>;
  }

  return (
    <div className={styles.container}>
      {/* User IDs */}
      {userIds.length > 0 && (
        <div className={spacing.mt4}>
          <Section icon={<Identification size={20} />} title="User IDs">
            <Records>
              {userIds.map((userId) => (
                <ClickableRecord
                  key={userId.value}
                  link={`/search/result?userId=${userId.value}`}
                  metadata={userId.metadata}
                >
                  {userId.value}
                </ClickableRecord>
              ))}
            </Records>
          </Section>
        </div>
      )}

      {/* Urls */}
      {urls.length > 0 && (
        <div className={spacing.mt4}>
          <Section icon={<Wikis size={20} />} title="Websites">
            {groupUrls().map((group) => (
              <div className={styles.group} key={group.title}>
                <h4 className={styles.groupTitle}>{group.title}</h4>
                <Records>
                  {group.items.map((item) => (
                    <div key={item.url} className={styles.url}>
                      {item.title && item.category === 'webpages' ? (
                        <b>{item.title}</b>
                      ) : (
                        <div>{item.name || item.domain}</div>
                      )}
                      {item.phrase && (
                        <div>
                          <i>{`"...${item.phrase}..."`}</i>
                        </div>
                      )}
                      <ClickableRecord
                        link={item.url}
                        tags={getUrlTags(item)}
                        metadata={item.metadata}
                        searchLink={false}
                      >
                        {item.url}
                      </ClickableRecord>
                    </div>
                  ))}
                </Records>
              </div>
            ))}
          </Section>
        </div>
      )}
    </div>
  );
};

export default DigitalFootprint;

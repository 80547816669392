import client from '@services/client';
import { gql } from '@services/gql';

const DELETE_USER = gql(/* GraphQL */ `
  mutation deleteUser($id: ID!) {
    userDelete(id: $id) {
      success
    }
  }
`);

const deleteUser = async (id: string) => {
  const response = await client.mutate({ mutation: DELETE_USER, variables: { id } });

  if (!response.data?.userDelete.success) {
    throw new Error('Something went wrong');
  }
};

export default deleteUser;

import * as Yup from 'yup';

const isContractIncluded = (context: Yup.TestContext) => {
  if (context && context.from && context.from[1] && context.from[1].value) {
    return context.from[1].value.includeContract;
  }

  return false;
};

const formSchema = Yup.object().shape({
  name: Yup.string().required('Organization name is required'),
  admin: Yup.object()
    .shape({
      name: Yup.string().trim(),
      email: Yup.string()
        .trim()
        .required('Organization admin email is required')
        .email('Invalid email'),
    })
    .required(),
  contract: Yup.object().shape({
    start: Yup.date().test('requiredContract', (value, context) => {
      // if contract is not included skip
      if (!isContractIncluded(context)) {
        return true;
      }

      // if contract is included the value is required
      if (!value) {
        return context.createError({ message: 'Start date for contract is required' });
      }

      // validate max date
      if (value && context.parent.end && value >= context.parent.end) {
        return context.createError({ message: "Start date can't be after end date" });
      }

      return true;
    }),
    end: Yup.date().test('requiredContract', (value, context) => {
      // if contract is not included skip
      if (!isContractIncluded(context)) {
        return true;
      }

      // if contract is included the value is required
      if (!value) {
        return context.createError({ message: 'End date for contract is required' });
      }

      // validate max date
      if (value && context.parent.start && value <= context.parent.start) {
        return context.createError({ message: "End date can't be before start date" });
      }

      return true;
    }),
    count: Yup.number().test('requiredContract', (value, context) => {
      if (!isContractIncluded(context)) {
        return true;
      }

      if (!value) {
        return context.createError({ message: 'Number of searches for contract is required' });
      }
      if (!Number.isInteger(value) || value <= 0) {
        return context.createError({ message: 'Number of searches must be positive integer' });
      }

      return true;
    }),
  }),
  includeContract: Yup.bool().required(),
  sendNotification: Yup.bool().required(),
});

export default formSchema;

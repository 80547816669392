import { gql } from '@services/gql';
import client from '@services/client';
import logger from '@services/logger';
import {
  GENDER_MAP,
  NAME_TYPE_MAP,
  EMAIL_TYPE_MAP,
  PHONE_TYPE_MAP,
  ADDRESS_TYPE_MAP,
  URL_CATEGORY_MAP,
  RELATIONSHIP_TYPE_MAP,
} from '@mantis/data';
import { Person } from '@mantis/types';
import replaceNullsWithUndefined from '@utils/replaceNullsWithUndefined';

gql(/* GraphQL */ `
  fragment Metadata on Metadata {
    current
    inferred
    validSince
    lastSeen
  }
`);

gql(/* GraphQL */ `
  fragment Name on PersonName {
    value
    first
    last
    middle
    type
    metadata {
      ...Metadata
    }
  }
`);

const FETCH_PERSON = gql(/* GraphQL */ `
  query fetchPerson($id: ID!) {
    person(id: $id) {
      name
      age
      dateOfBirth
      dateOfBirthRange
      gender
      languages
      originCountries
      images
      names {
        ...Name
      }
      emails {
        value
        type
        metadata {
          ...Metadata
          disposable
        }
      }
      phones {
        value
        type
        metadata {
          ...Metadata
          doNotCall
          voip
        }
      }
      addresses {
        value
        type
        metadata {
          ...Metadata
        }
      }
      educations {
        value
        degree
        school
        start
        end
        metadata {
          ...Metadata
        }
      }
      jobs {
        value
        title
        industry
        organization
        start
        end
        metadata {
          ...Metadata
        }
      }
      urls {
        url
        domain
        name
        title
        phrase
        category
        metadata {
          ...Metadata
          sponsored
        }
      }
      userIds {
        value
        metadata {
          ...Metadata
        }
      }
      relationships {
        type
        subtype
        names {
          ...Name
        }
      }
      tags {
        source
        classification
        value
        metadata {
          ...Metadata
        }
      }
      vehicles {
        vin
        year
        manufacturer
        model
        type
        metadata {
          ...Metadata
        }
      }
    }
  }
`);

const fetchPerson = async (id: string): Promise<Person | null> => {
  try {
    const response = await client.query({
      query: FETCH_PERSON,
      variables: { id },
    });

    const { person: fetchedPerson } = response.data;
    if (!fetchedPerson) {
      return null;
    }

    const person = replaceNullsWithUndefined(fetchedPerson);

    return {
      ...person,
      gender: person.gender ? GENDER_MAP[person.gender] : undefined,
      names: person.names.map((item) => {
        const { type, ...name } = item;
        if (type) {
          return { ...name, type: NAME_TYPE_MAP[type] };
        }

        return name;
      }),
      addresses: person.addresses.map((item) => {
        const { type, ...address } = item;
        if (type) {
          return { ...address, type: ADDRESS_TYPE_MAP[type] };
        }

        return address;
      }),
      emails: person.emails.map((item) => {
        const { type, ...email } = item;
        if (type) {
          return { ...email, type: EMAIL_TYPE_MAP[type] };
        }

        return email;
      }),
      phones: person.phones.map((item) => {
        const { type, ...phone } = item;
        if (type) {
          return { ...phone, type: PHONE_TYPE_MAP[type] };
        }

        return phone;
      }),
      urls: person.urls.map((item) => {
        const { category, ...url } = item;

        return { ...url, category: URL_CATEGORY_MAP[category] };
      }),
      relationships: person.relationships.map((item) => {
        const { type, names, ...relationship } = item;

        return {
          ...relationship,
          type: RELATIONSHIP_TYPE_MAP[type],
          names: names.map((nameItem) => {
            const { type: nameType, ...name } = nameItem;
            if (nameType) {
              return { ...name, type: NAME_TYPE_MAP[nameType] };
            }

            return name;
          }),
        };
      }),
    };
  } catch (error) {
    logger.error(error, { context: 'Fetch person detail' });

    throw error;
  }
};

export default fetchPerson;

import React from 'react';
import capitalize from 'lodash/capitalize';
import ErrorMessage from '@components/ErrorMessage';
import { DataTable, TableRow, TableCell, type TableHeader } from '@components/DataTable';
import { SearchActivity, UserRole } from '@types';
import RESULT_MAP from '@data/searchResultMap';
import { formatDateTime } from '@utils/formatDate';
import spacing from '@styles/spacing.module.scss';
import Toolbar from './Toolbar';
import Warning from './Warning';
import { FetchSearchHistoryFilter } from './types';

interface SearchHistoryTableProps {
  loading: boolean;
  fetching: boolean;
  userRole: UserRole;
  filteredBy: FetchSearchHistoryFilter;
  invalidSearchParams: boolean;
  error: boolean;
  data: SearchActivity[];
  count: number;
  onReset: () => void;
  onPageSizeChange: (size: number) => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onRefetch: () => void;
  onFilter: (filter: FetchSearchHistoryFilter) => void;
}

const headers: TableHeader[] = [
  {
    id: 'organization',
    value: 'Organization',
    isSortable: false,
  },
  {
    id: 'user',
    value: 'User',
    isSortable: false,
  },
  {
    id: 'date',
    value: 'Date',
    isSortable: false,
  },
  {
    id: 'result',
    value: 'Result',
    isSortable: false,
  },
  {
    id: 'type',
    value: 'Type',
    isSortable: false,
  },
];

const SearchHistoryTable = ({
  loading,
  fetching,
  userRole,
  filteredBy,
  invalidSearchParams,
  count,
  data,
  error,
  onReset,
  onPageSizeChange,
  onNextPage,
  onPreviousPage,
  onRefetch,
  onFilter,
}: SearchHistoryTableProps) => (
  <React.Fragment>
    {invalidSearchParams && (
      <div className={spacing.mb4}>
        <Warning userRole={userRole} onReset={onReset} />
      </div>
    )}
    {error && (
      <div className={spacing.mb4}>
        <ErrorMessage>Something went wrong during data fetching</ErrorMessage>
      </div>
    )}
    <DataTable
      title="Search history"
      loading={loading}
      fetching={fetching}
      headers={userRole === 'organizationAdmin' ? headers.slice(1) : headers}
      totalItems={count}
      initialPageSize={50}
      pagination="cursor"
      toolbar={<Toolbar userRole={userRole} filteredBy={filteredBy} onFilter={onFilter} />}
      onPageSizeChange={onPageSizeChange}
      onRefresh={onRefetch}
      onNextPage={onNextPage}
      onPreviousPage={onPreviousPage}
    >
      {data.map((activity) => (
        <TableRow id={activity.id} key={activity.id}>
          {userRole === 'admin' && (
            <TableCell>{activity.organization || <i>Deleted org</i>}</TableCell>
          )}
          <TableCell>{activity.user || <i>Deleted user</i>}</TableCell>
          <TableCell>{formatDateTime(activity.date)}</TableCell>
          <TableCell>{RESULT_MAP[activity.result]}</TableCell>
          <TableCell>{capitalize(activity.type)}</TableCell>
        </TableRow>
      ))}
    </DataTable>
  </React.Fragment>
);

export default SearchHistoryTable;
